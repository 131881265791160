export const Case6SVG = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">

<g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill="#fae7e7" stroke="none">
<path d="M4927 9139 c-74 -15 -110 -40 -121 -83 -8 -28 -13 -35 -25 -29 -24
12 -115 9 -142 -4 -13 -6 -44 -20 -69 -31 -59 -26 -89 -60 -101 -113 -8 -33
-26 -60 -77 -114 -82 -85 -132 -153 -171 -231 -32 -65 -71 -195 -71 -237 0
-14 -4 -29 -10 -32 -5 -3 -10 -33 -10 -66 0 -46 -3 -59 -15 -59 -35 0 -55 -38
-55 -102 0 -47 -5 -69 -20 -88 -16 -20 -20 -41 -20 -103 l0 -78 53 -48 c28
-27 76 -71 106 -98 l54 -49 2 -90 c2 -141 53 -308 115 -379 25 -29 46 -89 59
-171 19 -110 8 -136 -95 -230 -87 -80 -177 -149 -270 -209 -52 -34 -225 -106
-469 -194 -77 -28 -171 -65 -210 -81 -38 -17 -75 -30 -81 -30 -6 0 -17 -7 -24
-15 -11 -13 10 -15 181 -15 197 0 299 -7 299 -20 0 -12 -142 -20 -372 -21
-122 0 -220 -5 -223 -10 -4 -5 -16 -9 -27 -9 -12 0 -19 -2 -16 -5 3 -3 117 -8
253 -10 434 -7 477 -29 89 -46 -137 -7 -251 -13 -253 -15 -12 -10 25 -13 294
-19 160 -4 296 -11 303 -15 14 -7 -185 -20 -310 -20 -38 0 -68 -4 -68 -9 0 -4
84 -10 186 -14 115 -3 190 -9 197 -16 8 -8 6 -11 -8 -12 -231 -9 -337 -17
-346 -26 -7 -7 43 -12 175 -15 167 -3 209 -8 195 -23 -3 -2 -45 -7 -95 -10
-129 -8 -174 -15 -174 -26 0 -5 45 -9 103 -9 104 0 147 -6 147 -20 0 -5 -25
-12 -55 -15 -87 -11 -84 -31 5 -42 27 -3 50 -9 50 -13 0 -4 -23 -13 -52 -21
-28 -8 -53 -20 -56 -28 -4 -11 7 -13 57 -8 58 5 66 2 62 -25 0 -3 16 -24 37
-45 37 -39 94 -110 238 -298 146 -190 161 -205 205 -205 48 0 166 18 239 37
166 42 229 54 279 51 49 -3 52 -4 26 -11 -16 -5 -39 -12 -50 -17 -41 -18 -236
-65 -312 -75 -155 -22 -186 -23 -275 -15 -122 12 -121 11 -133 35 -5 11 -29
29 -52 40 -45 21 -52 32 -59 83 -2 19 -20 68 -40 111 l-35 77 -117 61 c-64 34
-164 82 -222 106 -58 24 -139 59 -180 78 -133 60 -921 456 -1045 525 -192 107
-192 107 -229 100 -62 -12 -150 -109 -339 -372 -42 -59 -137 -241 -186 -359
-19 -44 -40 -93 -47 -110 -34 -80 -45 -113 -68 -207 -54 -229 -53 -232 109
-314 63 -32 115 -62 115 -66 0 -4 5 -8 11 -8 10 0 76 -36 171 -93 62 -36 186
-116 307 -196 62 -42 135 -89 162 -106 26 -16 83 -55 126 -85 43 -30 108 -75
144 -100 163 -112 409 -288 462 -332 116 -96 130 -95 237 8 l68 65 53 -17 c30
-9 59 -21 65 -26 6 -4 31 -9 56 -10 52 -3 99 26 120 72 7 17 17 30 23 30 5 0
31 -14 58 -30 51 -33 53 -40 36 -118 -8 -41 -7 -57 6 -88 25 -62 59 -98 118
-128 65 -32 73 -40 83 -81 9 -38 44 -93 80 -127 25 -23 83 -53 107 -56 45 -4
68 -18 93 -53 61 -89 131 -129 224 -129 57 0 59 -1 109 -53 88 -92 108 -98
328 -96 149 1 197 -2 226 -14 61 -26 118 -30 188 -13 35 8 91 14 124 13 70 -3
113 12 172 59 39 32 46 34 117 34 41 0 87 5 103 11 36 13 88 64 103 99 10 24
20 30 71 39 89 15 147 63 177 145 16 44 21 49 72 66 122 41 194 208 146 341
-15 42 -11 47 80 100 l63 37 22 -24 c43 -45 112 -57 184 -30 11 5 37 14 58 22
43 16 77 18 77 5 0 -15 151 -131 170 -131 10 0 40 15 66 34 27 19 146 101 264
182 118 81 260 180 315 219 307 220 797 537 1005 649 79 43 105 85 91 148 -5
24 -17 79 -26 123 -28 129 -45 183 -98 305 -147 339 -244 496 -428 687 -51 53
-79 74 -103 79 -39 7 -56 -1 -345 -165 -218 -124 -237 -134 -451 -241 -297
-148 -533 -260 -548 -260 -4 0 -25 -10 -47 -21 -22 -12 -55 -27 -75 -34 -64
-23 -315 -148 -328 -164 -33 -42 -92 -187 -92 -227 0 -8 -4 -14 -9 -14 -5 0
-26 -16 -46 -35 l-37 -35 -67 10 c-69 11 -106 17 -106 20 0 1 -9 3 -20 5 -17
4 -229 65 -435 126 -36 10 -87 19 -115 20 l-50 1 30 14 c55 26 103 16 465
-102 106 -34 170 -43 170 -23 0 21 279 367 354 438 20 19 36 41 36 49 0 10 10
12 45 6 38 -5 45 -4 45 10 0 10 -9 16 -25 16 -14 0 -25 5 -25 10 0 6 -7 10
-15 10 -8 0 -15 5 -15 11 0 7 26 8 80 3 46 -4 80 -3 80 2 0 10 -88 44 -114 44
-10 0 -14 5 -11 10 3 6 31 10 61 10 60 0 76 20 17 21 -21 0 -58 4 -83 9 -32 6
-2 8 103 7 104 -2 147 1 147 9 0 16 -16 22 -145 54 l-110 28 70 0 c39 0 123
-3 188 -6 109 -6 137 -3 137 19 0 18 -176 61 -302 74 -10 1 -21 5 -25 9 -7 7
28 6 341 -15 116 -8 171 -8 178 -1 8 8 4 12 -13 16 -13 2 -80 15 -149 28
l-125 23 90 5 c50 3 138 5 198 5 59 -1 107 2 107 7 0 21 -100 66 -318 143 -35
13 -66 25 -69 29 -4 3 -13 6 -21 6 -8 0 -28 6 -45 14 -18 7 -61 24 -97 36 -36
13 -83 31 -105 40 -22 9 -78 32 -125 50 -161 65 -360 188 -490 305 -140 127
-132 116 -135 183 -2 34 0 64 5 67 4 3 11 28 15 55 4 27 11 52 16 56 5 3 9 13
9 22 0 9 11 27 24 41 31 33 76 116 76 142 0 10 4 19 9 19 5 0 12 28 16 63 4
34 12 68 17 75 6 7 9 41 6 81 -5 66 -4 70 26 102 17 19 62 57 98 84 86 64 112
113 106 199 -4 49 -9 63 -31 80 -23 19 -26 29 -29 96 l-3 75 -33 10 c-21 6
-31 14 -27 23 7 19 -14 157 -37 237 -44 153 -113 269 -222 374 -67 64 -88 94
-100 142 -9 39 -63 82 -131 103 -33 10 -70 23 -82 29 -21 9 -58 6 -96 -8 -13
-6 -18 -1 -23 23 -9 42 -40 67 -102 80 -62 14 -265 14 -335 1z m366 -80 c23
-11 25 -16 25 -98 0 -79 -1 -82 -8 -36 -5 28 -14 66 -20 85 l-12 35 -121 -7
c-67 -3 -132 -7 -144 -7 -13 -1 -23 -5 -23 -11 0 -12 22 -17 125 -27 l80 -8
-99 -5 c-67 -3 -101 -9 -104 -17 -3 -10 17 -13 80 -13 110 0 188 -10 188 -24
0 -8 -35 -10 -114 -8 -89 3 -119 1 -135 -10 -19 -14 -13 -16 73 -21 96 -6 186
-24 186 -36 0 -4 -30 -4 -67 0 -121 14 -200 11 -207 -7 -5 -14 2 -15 54 -11
33 4 62 2 65 -3 4 -6 12 -10 18 -10 7 0 6 4 -3 10 -13 8 -12 10 3 10 9 0 17
-4 17 -10 0 -5 21 -10 48 -10 26 0 56 -7 66 -15 18 -13 4 -15 -127 -15 -91 0
-147 -4 -147 -10 0 -6 38 -10 93 -10 116 0 189 -20 205 -55 6 -14 11 -25 9
-25 -1 0 -20 7 -42 15 -48 17 -288 21 -245 4 14 -6 68 -14 121 -18 66 -5 107
-14 129 -27 52 -30 49 -42 -7 -32 -65 13 -274 20 -248 9 11 -4 62 -11 114 -14
90 -7 141 -21 148 -42 2 -7 -51 -9 -149 -8 -95 1 -144 -1 -128 -6 14 -5 50 -9
80 -10 89 -1 241 -24 237 -36 -3 -6 -58 -9 -160 -6 -195 5 -216 -13 -27 -24
71 -4 133 -11 136 -17 3 -5 17 -13 30 -18 15 -6 24 -18 24 -31 0 -17 -4 -19
-17 -13 -26 12 -242 17 -288 6 -33 -7 -15 -10 105 -16 159 -9 210 -22 210 -56
0 -16 -4 -20 -18 -16 -30 10 -243 10 -292 0 l-45 -8 60 -7 c33 -4 92 -8 130
-8 87 -2 167 -22 156 -39 -5 -8 -59 -12 -176 -14 -228 -4 -225 -21 5 -29 96
-3 176 -7 176 -8 1 0 4 -10 7 -20 6 -25 -29 -31 -218 -32 -82 0 -159 -4 -170
-9 -12 -5 53 -9 166 -9 200 -1 249 -6 249 -26 0 -9 -29 -14 -97 -18 -54 -3
-143 -11 -198 -19 l-100 -13 198 -3 c143 -2 197 -6 197 -14 0 -9 3 -9 12 0 16
16 56 15 73 -2 12 -11 -4 -15 -105 -21 -66 -4 -123 -5 -127 -2 -7 4 -408 -18
-458 -26 -19 -3 554 -19 624 -18 18 1 51 -4 73 -10 31 -8 38 -13 29 -22 -8 -8
-113 -12 -375 -12 -517 0 -498 -8 79 -35 275 -12 331 -22 185 -31 -58 -4 -202
-8 -320 -9 -118 -1 -255 -5 -305 -9 l-90 -6 145 -8 c80 -4 215 -7 300 -8 173
-1 262 -9 267 -24 4 -12 -806 -14 -824 -2 -9 6 -13 38 -13 102 0 91 -1 94 -28
114 -45 32 -118 47 -149 30 -28 -15 -62 -79 -63 -116 0 -13 -4 -23 -10 -23
-20 0 -38 48 -49 129 -13 101 -14 205 -2 229 5 9 16 49 25 89 19 80 51 153
101 228 41 61 100 119 113 111 21 -13 31 -110 36 -349 2 -137 7 -251 10 -255
18 -17 21 45 18 395 l-2 386 22 16 c53 37 158 65 158 41 0 -5 -13 -10 -30 -10
-16 0 -30 -4 -30 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -10 0 -5 -13 -10 -30
-10 -39 0 -39 -16 0 -25 33 -7 32 -25 -1 -25 -30 0 -17 -24 14 -28 31 -4 27
-22 -4 -22 -12 0 -19 -5 -16 -12 2 -7 16 -12 31 -11 17 1 26 -3 26 -13 0 -9
-11 -14 -31 -14 -17 0 -28 -4 -24 -10 3 -5 17 -10 31 -10 13 0 24 -4 24 -10 0
-5 -11 -10 -25 -10 -14 0 -25 -4 -25 -10 0 -5 13 -10 29 -10 41 0 49 -18 9
-22 -46 -4 -49 -28 -4 -28 25 0 36 -4 36 -15 0 -10 -10 -15 -30 -15 -43 0 -37
-18 7 -22 27 -2 39 -8 41 -20 3 -15 -4 -18 -35 -19 -21 -1 -30 -3 -20 -6 9 -2
17 -9 17 -14 0 -5 11 -9 25 -9 16 0 25 -6 25 -15 0 -10 -10 -15 -30 -15 -36 0
-42 -27 -8 -32 34 -5 37 -38 4 -38 -14 0 -26 -4 -26 -10 0 -5 16 -10 35 -10
19 0 35 -4 35 -9 0 -5 3 -16 6 -25 5 -13 -2 -16 -37 -16 -51 0 -49 -7 5 -12
29 -3 41 -9 47 -26 7 -19 5 -22 -15 -22 -13 0 -39 -3 -57 -7 -30 -7 -27 -8 31
-14 36 -3 67 -4 70 -2 2 3 7 172 10 376 3 205 9 379 13 387 13 27 50 43 117
51 86 10 292 2 323 -12z m247 -119 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0
6 9 10 20 10 11 0 20 -4 20 -10z m10 -51 c0 -12 -47 -12 -55 0 -6 11 23 20 43
13 6 -2 12 -8 12 -13z m0 -49 c0 -5 -13 -10 -30 -10 -16 0 -30 5 -30 10 0 6
14 10 30 10 17 0 30 -4 30 -10z m131 -259 c-6 -106 -13 -196 -16 -199 -3 -3
-5 100 -4 229 1 217 2 232 16 199 13 -29 13 -61 4 -229z m-131 204 c0 -10 -11
-15 -35 -15 -24 0 -35 5 -35 15 0 10 11 15 35 15 24 0 35 -5 35 -15z m0 -71
c0 -16 -45 -20 -55 -5 -7 12 25 30 42 24 7 -3 13 -11 13 -19z m204 -5 c65 -51
172 -235 202 -347 44 -168 57 -342 32 -437 -9 -35 -9 -48 0 -57 9 -9 12 -8 12
5 0 10 8 17 20 17 19 0 20 5 15 53 -3 28 -1 66 4 82 8 26 9 20 10 -36 1 -56 4
-69 26 -89 18 -17 25 -34 25 -62 0 -41 -28 -76 -109 -136 -20 -15 -63 -49 -96
-76 -92 -76 -159 -115 -224 -132 -51 -13 -137 -14 -578 -11 -285 2 -534 6
-553 9 -48 8 -101 31 -157 69 -56 38 -240 193 -265 223 -22 26 -23 92 -3 112
11 11 15 37 15 90 0 43 4 74 10 74 6 0 10 -15 10 -34 0 -18 3 -53 6 -78 6 -42
10 -47 60 -74 30 -16 54 -35 54 -41 0 -7 5 -13 12 -13 6 0 9 -3 5 -6 -7 -7
-106 24 -124 39 -9 6 -13 6 -13 -2 0 -20 48 -61 96 -82 53 -24 190 -40 240
-29 19 5 61 5 92 0 31 -4 150 -9 263 -9 114 -1 301 -6 415 -11 115 -5 236 -10
269 -11 l60 -2 -70 -7 c-38 -4 -141 -8 -227 -9 -131 -1 -158 -4 -166 -17 -6
-12 -34 -17 -120 -21 -62 -3 -112 -10 -112 -14 0 -5 92 -9 205 -9 113 0 205
-4 205 -9 0 -13 -64 -21 -185 -22 -124 -1 -307 -15 -235 -18 25 0 63 -4 85 -8
l40 -7 -30 -7 c-48 -11 -347 -10 -406 1 -59 11 -130 41 -168 71 -26 21 -41 24
-41 9 0 -5 6 -10 14 -10 8 0 16 -4 18 -8 12 -29 115 -73 213 -90 22 -4 256 -7
520 -7 533 0 537 0 645 65 96 57 272 209 281 243 4 15 6 27 4 27 -1 0 -19 -7
-40 -16 -51 -21 -68 -13 -54 23 13 35 -2 282 -22 368 -6 22 -16 68 -24 101 -7
34 -18 70 -23 80 -6 11 -22 45 -37 76 -28 62 -117 202 -134 212 -13 8 -15 26
-2 26 4 0 20 -10 35 -21z m-199 -50 c10 -15 4 -17 -32 -12 -18 3 -30 9 -27 14
7 13 51 11 59 -2z m5 -74 c0 -12 -7 -14 -27 -9 -45 11 -51 24 -10 24 26 0 37
-4 37 -15z m217 -1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-211 -82 c-3 -2 -22 -1 -43 3 -35 6 -36 8 -19 20 16 12 22 12 42 -2 14 -9 22
-18 20 -21z m254 -2 c0 -5 -14 -10 -31 -10 -17 0 -28 4 -24 10 3 6 17 10 31
10 13 0 24 -4 24 -10z m-250 -60 c0 -5 -21 -10 -46 -10 -27 0 -43 4 -39 10 3
6 24 10 46 10 21 0 39 -4 39 -10z m265 0 c3 -5 -3 -10 -15 -10 -12 0 -18 5
-15 10 3 6 10 10 15 10 5 0 12 -4 15 -10z m25 -60 c-8 -5 -26 -10 -40 -10 -20
0 -22 2 -10 10 8 5 26 10 40 10 20 0 22 -2 10 -10z m-290 -15 c0 -11 -11 -15
-40 -15 -48 0 -54 17 -7 23 17 2 35 5 40 5 4 1 7 -5 7 -13z m304 -44 c-15 -15
-84 -25 -84 -12 0 6 12 11 28 11 15 0 34 4 42 10 17 11 28 4 14 -9z m-304 -21
c8 -5 12 -11 10 -14 -8 -7 -109 -10 -116 -3 -4 4 4 7 19 7 14 0 29 5 32 10 8
12 35 13 55 0z m-137 -37 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18
-5z m471 -3 c-6 -6 -26 -12 -45 -14 -19 -2 -24 -1 -11 1 12 2 22 9 22 14 0 5
10 9 22 9 15 0 19 -3 12 -10z m-314 -49 c0 -10 -71 -18 -108 -13 -12 2 -20 8
-17 13 8 12 125 12 125 0z m-150 -11 c0 -5 -15 -10 -32 -9 -31 1 -32 1 -8 9
35 11 40 11 40 0z m435 -10 c-3 -5 -15 -10 -26 -10 -11 0 -17 5 -14 10 3 6 15
10 26 10 11 0 17 -4 14 -10z m55 -40 c0 -5 -3 -10 -7 -10 -5 0 -18 -3 -30 -6
-13 -4 -23 -2 -23 4 0 10 19 18 48 21 6 0 12 -3 12 -9z m-332 -18 c3 -9 -16
-12 -64 -12 -76 0 -78 16 -4 23 57 4 63 3 68 -11z m-158 -8 c0 -2 -17 -4 -37
-3 -29 0 -33 2 -18 8 18 7 55 4 55 -5z m490 -33 c0 -6 -9 -9 -19 -8 -11 0 -17
5 -15 9 7 11 34 10 34 -1z m-312 -33 c-5 -15 -118 -24 -118 -9 0 14 19 19 73
20 35 1 47 -3 45 -11z m291 -52 c14 -17 25 -172 13 -180 -5 -3 -12 29 -16 71
-7 85 -14 99 -60 112 l-31 9 41 1 c24 1 46 -5 53 -13z m-1514 -18 c24 -11 25
-14 25 -109 l0 -97 -55 13 c-30 7 -57 10 -60 7 -3 -3 -5 0 -5 6 0 7 4 12 9 12
5 0 11 24 13 53 5 48 30 127 42 127 3 0 17 -5 31 -12z m1225 -7 c0 -13 -27
-21 -73 -21 -39 1 -41 2 -23 15 21 16 96 21 96 6z m272 -88 c0 -29 1 -59 2
-65 1 -7 -11 -14 -26 -16 l-28 -3 0 80 c0 54 4 81 13 84 21 8 37 -25 39 -80z
m-262 13 c0 -14 -105 -30 -124 -18 -9 5 36 17 117 31 4 0 7 -5 7 -13z m-47
-83 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-193 -203 c0 -5
-16 -10 -35 -10 -19 0 -35 5 -35 10 0 6 16 10 35 10 19 0 35 -4 35 -10z m-998
-151 c63 -32 152 -58 240 -69 45 -5 83 -8 85 -6 3 2 19 0 36 -6 40 -12 332
-28 620 -34 119 -3 217 -9 217 -14 0 -14 -45 -19 -262 -26 -115 -3 -208 -10
-208 -15 0 -5 70 -9 156 -9 91 0 164 -4 175 -11 11 -6 26 -7 32 -2 18 12 99
10 104 -3 2 -7 -73 -15 -237 -24 -132 -8 -240 -17 -240 -21 0 -4 102 -8 228
-8 230 -1 336 -12 242 -24 -25 -3 -126 -8 -225 -11 -247 -6 -281 -22 -65 -29
91 -3 183 -5 205 -5 70 2 85 -2 85 -17 0 -12 -19 -15 -112 -15 -127 0 -349
-18 -360 -28 -4 -4 57 -6 135 -4 166 3 333 -9 330 -23 -2 -5 -11 -11 -20 -12
-94 -9 -171 -14 -303 -19 -85 -4 -162 -10 -170 -15 -15 -8 232 -16 332 -10 27
1 74 -3 105 -10 54 -11 56 -13 29 -20 -15 -4 -67 -8 -115 -8 -47 0 -126 -7
-176 -16 -49 -8 -118 -15 -152 -15 -39 0 -63 -4 -63 -11 0 -7 60 -9 204 -3
205 7 302 1 290 -17 -3 -6 -42 -9 -87 -8 -102 3 -376 -17 -408 -30 -26 -10
109 -15 308 -11 148 3 273 -9 273 -26 0 -15 -55 -20 -64 -6 -12 19 -554 -12
-575 -34 -10 -10 13 -10 149 -2 142 9 363 3 384 -10 6 -3 23 -5 38 -3 16 1 28
-2 28 -8 0 -6 -50 -10 -140 -10 -76 0 -144 -2 -150 -6 -12 -8 -187 -33 -287
-42 -35 -3 -61 -9 -58 -13 3 -5 17 -7 33 -4 15 2 124 8 242 14 223 11 334 7
327 -14 -2 -7 -52 -12 -157 -13 -85 -1 -201 -7 -259 -12 -57 -6 -113 -11 -123
-11 -10 0 -18 -4 -18 -10 0 -6 87 -10 240 -10 134 0 240 -4 240 -9 0 -5 -108
-12 -241 -16 -140 -5 -235 -11 -227 -16 7 -5 47 -9 88 -9 81 0 120 -7 120 -21
0 -17 -175 -10 -253 10 -120 30 -209 83 -312 186 -105 104 -130 147 -210 360
-31 83 -61 158 -66 167 -14 26 -35 198 -25 198 4 0 25 -9 48 -21z m1353 -88
c-3 -5 -22 -15 -43 -22 -20 -6 -42 -16 -49 -21 -7 -6 -18 -8 -25 -5 -9 3 -6 7
7 13 11 4 25 13 30 20 9 11 32 18 73 23 7 0 11 -3 7 -8z m-11 -91 c-13 -11
-93 -19 -99 -10 -3 5 11 12 32 16 21 4 45 10 53 15 17 9 28 -8 14 -21z m-6
-72 c-5 -15 -88 -24 -88 -9 0 13 18 19 58 20 23 1 32 -3 30 -11z m-8 -83 c0
-11 -12 -15 -45 -15 -42 0 -54 7 -38 23 3 4 24 7 45 7 27 0 38 -4 38 -15z
m-18 -78 c-12 -14 -92 -11 -92 4 0 5 23 9 51 9 41 0 49 -3 41 -13z m-18 -72
c-7 -17 -104 -22 -104 -6 0 14 19 19 67 20 34 1 41 -2 37 -14z m-31 -80 c-5
-22 -103 -16 -103 6 0 6 21 9 53 7 28 -2 51 -7 50 -13z m-940 -298 c84 -56
148 -87 192 -93 22 -3 47 -8 55 -11 16 -6 35 -8 108 -11 60 -3 43 -22 -19 -22
-27 0 -49 -4 -49 -10 0 -8 194 -17 233 -11 4 0 7 -3 7 -8 0 -12 -62 -21 -142
-21 -38 0 -68 -4 -68 -9 0 -11 97 -21 219 -22 79 -1 89 -3 56 -10 -22 -5 -89
-9 -150 -10 -104 -2 -107 -2 -50 -10 33 -5 88 -9 123 -9 34 0 62 -4 62 -9 0
-11 -41 -19 -140 -27 -45 -4 -83 -11 -86 -15 -3 -5 39 -9 93 -9 107 -1 109
-11 4 -30 -56 -10 -71 -9 -133 10 -133 40 -316 172 -405 293 -54 73 -67 105
-53 122 7 8 5 18 -5 31 -29 39 -13 31 38 -20 30 -28 79 -69 110 -89z m-233 48
c33 -77 94 -157 184 -243 91 -85 265 -201 304 -202 31 0 27 -16 -11 -42 -19
-13 -47 -39 -64 -58 -34 -39 -125 -185 -167 -268 l-27 -54 -52 44 c-47 40
-102 128 -138 223 -9 22 -44 170 -54 225 -18 96 -17 420 1 420 3 0 13 -20 24
-45z m1254 -30 c-6 -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m-34
-66 c-19 -17 -52 -46 -72 -65 -21 -19 -41 -34 -45 -34 -5 0 23 30 62 65 38 36
74 65 80 65 5 0 -6 -14 -25 -31z m-8 -98 c-75 -67 -137 -117 -187 -152 -27
-18 -52 -37 -55 -41 -3 -4 -17 -12 -30 -18 -14 -6 -45 -22 -70 -35 -25 -13
-53 -24 -63 -24 -10 -1 -15 -5 -12 -11 6 -10 114 27 162 56 144 88 209 133
246 170 20 21 39 35 42 32 9 -9 -20 -58 -37 -64 -10 -3 -18 -9 -18 -13 0 -15
-139 -120 -225 -169 -49 -29 -85 -52 -79 -52 20 0 160 69 174 85 7 8 18 15 24
15 6 0 26 14 44 30 18 17 36 30 39 30 3 0 18 9 33 20 16 11 31 16 34 11 6 -9
-85 -96 -139 -134 -16 -12 -32 -24 -35 -28 -10 -13 -117 -69 -133 -69 -9 0
-17 -5 -17 -11 0 -15 54 -2 99 23 39 22 86 55 169 118 46 36 52 38 52 20 0
-11 -5 -20 -10 -20 -6 0 -13 -7 -17 -16 -7 -20 -62 -74 -74 -74 -5 0 -9 -7 -9
-15 0 -8 -6 -15 -13 -15 -8 0 -24 -11 -37 -25 -13 -14 -28 -25 -33 -25 -20 0
-117 -51 -117 -61 0 -28 118 32 212 108 44 36 78 58 78 50 0 -8 -10 -21 -22
-30 -17 -11 -20 -18 -12 -27 15 -15 -3 -78 -27 -101 -18 -15 -27 -22 -106 -80
-24 -17 -43 -37 -43 -45 0 -20 8 -18 46 14 19 15 50 40 69 56 20 16 38 26 41
23 10 -10 -38 -86 -58 -93 -10 -3 -18 -11 -18 -17 0 -7 -12 -20 -27 -31 l-25
-19 -28 30 c-15 16 -54 72 -85 124 -94 154 -128 201 -159 220 l-30 18 89 42
c49 23 116 59 148 80 32 21 61 39 63 39 13 0 195 142 206 160 13 21 13 21 16
-1 3 -15 -8 -33 -36 -58z m-1512 35 c0 -2 -22 -16 -50 -30 -27 -14 -50 -22
-50 -16 0 5 17 18 38 29 35 19 62 26 62 17z m129 -50 c-2 -2 -44 -11 -93 -19
-128 -23 -136 -40 -13 -31 60 4 97 3 97 -3 0 -5 -45 -17 -100 -28 -74 -13
-100 -22 -100 -33 0 -11 7 -13 33 -8 64 13 146 19 164 11 17 -7 -15 -17 -152
-44 -68 -13 -15 -24 66 -13 69 8 129 0 129 -19 0 -4 -43 -10 -96 -14 -53 -4
-111 -13 -128 -21 -37 -16 -32 -16 100 -14 80 1 103 -2 113 -14 12 -14 10 -16
-15 -16 -56 0 -234 -24 -234 -32 0 -4 62 -8 137 -8 78 0 133 -4 128 -9 -10 -8
-204 -40 -260 -42 -27 -1 -26 -2 10 -10 22 -5 86 -6 143 -3 62 3 102 2 102 -4
0 -6 -17 -13 -37 -16 -21 -3 -47 -8 -58 -10 -11 -2 -30 -5 -42 -5 -13 -1 -23
-5 -23 -11 0 -6 40 -10 98 -11 l97 -1 -68 -16 c-38 -9 -65 -20 -61 -24 4 -5
41 -8 83 -8 53 0 71 -3 61 -10 -8 -5 -27 -10 -42 -10 -21 0 -28 -5 -28 -20 0
-17 7 -20 43 -21 41 -1 42 -1 12 -11 -16 -5 -43 -9 -60 -9 -16 0 -34 -4 -40
-8 -5 -5 -21 -11 -35 -14 -55 -11 -235 -86 -227 -93 3 -3 59 12 124 35 94 32
134 40 198 42 112 2 113 0 5 -22 -172 -34 -205 -42 -215 -52 -6 -6 14 -7 55
-3 167 16 273 19 266 7 -4 -6 -26 -11 -49 -11 -23 -1 -71 -7 -107 -15 -36 -8
-80 -14 -97 -15 -18 0 -33 -4 -33 -9 0 -5 -29 -12 -65 -16 -94 -10 -83 -27 13
-19 42 3 93 10 112 15 48 12 230 12 230 -1 0 -5 -21 -10 -47 -10 -27 0 -91 -7
-143 -15 -52 -9 -121 -18 -152 -22 -32 -3 -55 -9 -52 -14 2 -4 83 -5 179 -2
121 4 175 3 175 -5 0 -6 -28 -12 -62 -15 -35 -3 -88 -10 -118 -17 -53 -12 -78
-15 -129 -18 -19 -2 -22 -5 -13 -14 8 -8 67 -11 194 -10 118 1 177 -1 167 -7
-8 -6 -37 -11 -65 -12 -67 -2 -255 -29 -272 -38 -30 -17 10 -22 146 -21 159 2
194 1 201 -5 2 -3 -9 -5 -25 -6 -27 0 -121 -11 -238 -27 -27 -4 -51 -12 -53
-18 -6 -17 60 -24 181 -18 71 3 106 2 96 -4 -8 -5 -73 -12 -144 -15 -123 -5
-130 -4 -147 17 -10 11 -18 25 -19 29 0 5 -7 15 -15 23 -8 9 -15 24 -15 35 0
10 -4 19 -8 19 -11 0 -47 79 -100 217 l-20 53 76 36 c43 19 85 40 94 45 10 5
24 9 32 9 7 0 19 7 26 15 7 8 20 15 30 15 22 0 41 22 32 37 -4 6 -60 32 -126
59 l-119 47 7 66 c10 106 18 155 27 167 5 6 6 14 2 17 -3 4 -1 12 5 19 6 7 10
16 10 21 0 4 3 23 7 43 9 49 45 63 164 64 52 0 92 -2 90 -4z m1801 -41 c0 -8
-4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-395 -45
c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m409
-16 c9 -22 8 -24 -9 -24 -8 0 -15 9 -15 20 0 24 15 27 24 4z m-2271 -31 c-7
-2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m22 -44 c5 -5 -24 -9 -72
-9 -44 0 -96 -3 -114 -6 -48 -9 -25 13 26 25 44 10 144 4 160 -10z m2275 -10
c14 -24 9 -32 -14 -23 -17 7 -23 44 -6 44 5 0 14 -9 20 -21z m-2394 -40 c3 -5
-6 -9 -20 -9 -13 0 -35 -4 -48 -9 -13 -5 -80 -12 -149 -15 -119 -5 -122 -5
-69 8 30 8 73 18 95 24 50 12 183 13 191 1z m4436 -64 c70 -75 111 -133 103
-145 -9 -14 -18 -13 -41 8 -10 10 -40 37 -67 61 -26 24 -50 41 -54 38 -7 -7
61 -98 106 -141 30 -29 39 -46 23 -46 -4 0 -29 20 -54 45 -26 25 -51 45 -55
45 -5 0 -27 18 -48 40 -21 22 -43 40 -48 40 -14 0 42 -90 90 -145 65 -75 30
-68 -73 14 -80 65 -114 84 -114 64 0 -5 34 -42 75 -83 74 -74 85 -90 64 -90
-12 0 -52 20 -59 30 -3 4 -36 31 -74 59 -51 39 -72 49 -80 41 -8 -9 9 -30 67
-84 42 -40 77 -78 77 -84 0 -6 6 -12 13 -15 6 -2 9 -8 5 -12 -8 -8 -101 51
-169 107 -39 33 -69 47 -69 34 0 -6 172 -176 179 -176 9 -1 51 -37 51 -44 0
-14 -36 -4 -47 13 -7 11 -45 41 -85 67 -40 25 -88 60 -106 75 -35 31 -52 36
-52 17 0 -20 66 -89 81 -85 8 2 10 2 6 -1 -5 -2 6 -19 24 -38 40 -41 24 -44
-33 -6 -99 66 -143 90 -147 79 -2 -6 7 -19 20 -30 50 -40 178 -161 174 -165
-4 -4 -173 108 -196 130 -33 31 -47 39 -53 29 -8 -12 24 -46 88 -94 27 -20 43
-37 35 -37 -8 -1 -2 -11 15 -24 43 -36 14 -42 -39 -9 -53 33 -57 47 -12 38 30
-6 31 -5 12 10 -13 10 -29 13 -47 10 -20 -5 -37 0 -58 15 -61 44 -47 7 23 -61
74 -71 77 -97 4 -34 -22 19 -48 35 -58 35 -9 0 -19 9 -22 20 -6 22 -32 27 -43
8 -4 -7 20 -37 62 -76 38 -36 61 -62 51 -59 -10 4 -22 7 -26 7 -5 0 -39 27
-76 60 -46 40 -72 57 -78 51 -9 -9 62 -98 111 -138 14 -12 9 -33 -8 -33 -5 0
-40 27 -78 60 -63 54 -92 71 -92 54 0 -3 27 -36 60 -72 55 -61 58 -68 41 -76
-15 -6 -28 0 -60 29 -23 21 -54 46 -70 56 -16 9 -32 22 -35 28 -9 13 -26 15
-26 2 0 -5 27 -38 60 -73 33 -34 60 -68 60 -75 0 -13 -38 -28 -48 -19 -4 3
-39 39 -79 78 -40 40 -75 69 -79 65 -8 -8 152 -223 172 -231 19 -7 18 -22 -3
-30 -17 -7 -76 41 -68 54 3 4 -5 10 -16 13 -11 3 -23 15 -26 26 -3 12 -10 21
-15 21 -6 0 -30 21 -54 46 -31 34 -48 45 -61 40 -10 -4 -13 -10 -7 -14 5 -4
21 -23 34 -42 13 -19 37 -50 53 -67 33 -35 33 -37 1 -47 -16 -5 -34 8 -83 57
-34 35 -66 70 -71 77 -7 11 -12 12 -21 3 -12 -12 14 -51 84 -127 15 -17 27
-35 27 -40 0 -5 14 -22 30 -38 29 -27 32 -48 8 -48 -6 0 -61 52 -123 115 -99
102 -135 131 -135 108 0 -4 27 -42 60 -85 47 -60 58 -80 46 -84 -10 -4 -42 23
-97 80 -45 47 -88 86 -95 86 -22 0 -17 -9 29 -60 57 -63 70 -80 85 -113 15
-35 15 -37 -2 -37 -8 0 -36 26 -62 58 -90 106 -144 152 -144 124 0 -5 8 -17
18 -28 31 -33 112 -148 124 -175 6 -15 24 -34 40 -43 15 -10 28 -26 28 -37 0
-27 -12 -24 -45 11 -16 17 -25 30 -21 30 10 0 -109 118 -174 172 -62 52 -85
49 -34 -4 22 -24 64 -73 93 -110 64 -83 51 -101 -22 -31 -139 133 -220 179
-100 57 26 -27 56 -65 67 -84 16 -30 9 -26 -50 32 -38 36 -95 91 -126 121 -32
30 -58 59 -58 66 0 11 86 71 102 71 4 0 31 11 60 24 29 13 88 38 130 55 42 17
94 39 115 51 21 11 42 20 46 20 11 0 567 277 583 291 6 5 31 20 55 32 67 35
194 105 288 159 46 26 86 48 88 48 3 0 17 8 31 19 44 31 116 70 130 71 7 0 36
-25 64 -55z m-6011 4 c41 -22 135 -73 209 -111 115 -61 204 -112 399 -232 60
-36 378 -202 580 -303 l195 -96 -40 -33 c-21 -19 -45 -34 -52 -34 -7 0 -15 -7
-18 -15 -4 -8 -13 -15 -21 -15 -8 0 -28 -13 -46 -30 -18 -16 -35 -30 -39 -30
-15 0 -8 20 12 30 17 9 140 129 140 137 0 10 -40 1 -50 -12 -7 -8 -23 -15 -36
-15 -17 0 -22 -4 -18 -15 4 -10 0 -15 -15 -15 -11 0 -31 -12 -44 -26 -19 -20
-26 -23 -33 -13 -4 7 -3 14 4 17 7 2 20 18 29 35 8 17 26 40 39 50 25 19 31
37 14 37 -6 0 -35 -18 -65 -40 -30 -22 -59 -40 -65 -40 -16 0 -12 18 10 40 11
11 20 27 20 35 0 8 7 15 15 15 8 0 15 7 15 15 0 25 -6 22 -82 -48 -11 -10 -26
-16 -34 -13 -8 3 -14 1 -14 -4 0 -6 -7 -10 -15 -10 -24 0 -18 11 31 61 49 50
47 73 -3 36 -17 -12 -43 -31 -58 -42 -15 -11 -45 -39 -68 -62 -39 -41 -57 -52
-57 -35 0 5 21 34 48 65 83 97 114 138 110 143 -7 6 -168 -125 -168 -136 0
-18 -50 -44 -62 -32 -7 7 5 26 41 64 28 29 51 60 51 69 0 8 11 26 25 39 14 13
23 25 20 28 -2 2 -52 -38 -110 -89 -59 -52 -111 -95 -116 -97 -22 -8 -6 27 34
73 47 56 77 98 97 134 l12 24 -30 -21 c-16 -12 -63 -53 -105 -92 -67 -63 -97
-82 -97 -64 0 4 16 25 36 48 20 22 44 50 53 61 10 11 35 40 57 64 28 32 33 41
16 32 -36 -19 -84 -54 -121 -86 -18 -17 -47 -42 -65 -58 -17 -15 -38 -33 -46
-40 -8 -8 -24 -26 -36 -42 -13 -19 -25 -26 -33 -21 -9 6 -4 18 21 45 19 20 72
83 118 141 46 58 89 108 94 112 7 5 6 8 -3 8 -17 0 -81 -49 -158 -124 -123
-118 -126 -95 -7 45 47 56 83 103 80 106 -7 7 -99 -66 -174 -139 -34 -34 -65
-58 -68 -55 -7 7 18 67 28 67 10 0 152 170 147 175 -6 6 -104 -69 -184 -140
-74 -66 -85 -73 -85 -55 0 7 31 45 69 84 88 91 135 146 130 151 -4 3 -57 -34
-105 -74 -6 -5 -28 -20 -50 -34 -21 -13 -52 -39 -69 -57 -16 -17 -38 -36 -47
-41 -33 -18 -19 11 30 64 26 29 61 70 77 92 16 22 36 47 43 55 11 10 12 15 2
18 -6 2 -26 -10 -44 -27 -18 -17 -35 -31 -39 -31 -4 0 -42 -33 -84 -74 -77
-75 -126 -96 -61 -27 73 77 148 173 142 182 -3 5 -31 -13 -62 -40 -57 -49
-102 -77 -102 -65 0 4 26 30 57 60 32 29 54 56 50 60 -7 8 -22 -1 -106 -69
-33 -26 -62 -43 -65 -39 -3 5 26 40 64 79 106 106 85 126 -23 24 -27 -25 -50
-44 -52 -41 -2 3 -15 -6 -29 -19 -14 -14 -26 -20 -26 -14 0 5 10 18 21 29 24
21 109 129 109 138 0 22 -64 -21 -150 -101 -31 -29 -70 -44 -70 -27 0 4 20 30
44 58 42 49 110 155 104 161 -2 2 -52 -44 -112 -101 -60 -57 -113 -104 -118
-104 -21 0 -3 34 67 125 72 95 89 124 63 109 -24 -13 -54 -41 -190 -177 -76
-76 -138 -136 -138 -133 0 8 190 259 242 318 33 39 57 58 73 58 12 0 55 -18
96 -41z m3659 22 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m330
5 c0 -2 3 -11 6 -20 5 -12 0 -16 -20 -16 -19 0 -26 5 -26 20 0 13 7 20 20 20
11 0 20 -2 20 -4z m-1120 -66 c0 -5 -7 -10 -15 -10 -24 0 -17 -20 8 -21 12 -1
3 -5 -20 -11 -63 -14 -52 -25 21 -23 94 1 110 -9 40 -25 -93 -21 -96 -29 -11
-30 44 0 77 -4 77 -10 0 -5 -13 -10 -30 -10 -16 0 -30 -4 -30 -10 0 -5 11 -10
25 -10 40 0 29 -17 -20 -31 -25 -7 -45 -17 -45 -21 0 -4 24 -8 53 -10 38 -3
47 -6 32 -12 -102 -41 -125 -63 -55 -52 24 4 40 2 40 -4 0 -15 -57 -41 -75
-33 -14 5 -97 163 -119 226 -7 22 41 72 89 93 37 16 35 16 35 4z m848 -8 c2
-7 -7 -12 -23 -12 -24 0 -26 2 -15 15 14 17 31 16 38 -3z m-61 -8 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-37 -14 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m396 -11 c4 -7 -2 -17 -12 -23
-31 -18 -138 -56 -149 -52 -13 4 49 36 70 36 9 0 18 7 22 16 3 9 15 20 27 24
29 12 34 12 42 -1z m-346 -39 c0 -5 -6 -11 -12 -11 -7 -1 -24 -3 -38 -4 -43
-5 -47 10 -5 16 22 3 43 7 48 8 4 0 7 -3 7 -9z m625 -20 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1929 -130 c-3 -2 -25 16
-50 42 -24 25 -49 48 -56 50 -7 3 -8 9 -3 18 6 10 21 -1 61 -46 29 -32 50 -61
48 -64z m-249 100 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8
-4 11 -10z m-2290 -21 c13 -5 -3 -8 -47 -8 -67 -1 -102 14 -51 22 30 4 66 -1
98 -14z m69 -66 c61 -12 153 -47 196 -74 8 -5 35 -20 60 -33 36 -20 40 -25 22
-26 -13 0 -35 9 -50 20 -60 44 -182 76 -387 100 -33 4 -49 8 -35 9 14 1 31 5
38 9 20 13 81 11 156 -5z m-1038 -31 c12 -9 33 -35 48 -57 33 -51 34 -93 2
-261 -14 -71 -32 -171 -42 -221 -9 -51 -22 -93 -28 -94 -6 0 -41 -2 -78 -5
l-66 -4 -27 82 c-15 46 -28 90 -30 98 -2 8 -19 78 -38 155 -19 77 -38 152 -41
168 l-6 27 49 -35 c49 -35 51 -36 81 -20 18 9 53 50 87 100 31 47 59 85 62 85
3 0 16 -8 27 -18z m522 -62 c64 -84 88 -94 140 -56 32 23 37 24 41 10 1 -10
-4 -45 -13 -78 -13 -53 -41 -176 -66 -299 -4 -21 -10 -40 -12 -43 -3 -2 -34
-7 -69 -10 -35 -3 -68 -10 -73 -15 -13 -12 -36 9 -36 32 0 10 -14 87 -31 170
-27 134 -34 202 -30 261 1 21 69 98 85 97 6 0 35 -31 64 -69z m-3478 31 c0
-13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m100 -9 c0 -14 -46 -53
-53 -45 -4 3 3 17 15 30 22 23 38 30 38 15z m3910 -18 c41 -8 76 -17 79 -20
12 -12 -14 -12 -124 1 -66 8 -137 15 -157 15 -21 0 -38 4 -38 9 0 23 117 20
240 -5z m330 10 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10
-7 10 -16z m1998 0 c-10 -10 -23 -14 -34 -10 -16 6 -16 8 -4 16 8 6 24 10 35
10 18 0 18 -1 3 -16z m-6167 -37 c-29 -30 -39 -12 -10 19 14 15 25 20 27 14 3
-7 -5 -21 -17 -33z m6079 10 c0 -8 -3 -17 -7 -20 -9 -9 -34 13 -27 24 8 14 34
11 34 -4z m-5954 -44 c-14 -15 -29 -24 -32 -20 -4 4 6 21 22 38 16 16 30 25
32 20 2 -6 -8 -23 -22 -38z m-52 23 c-3 -8 -14 -16 -24 -19 -16 -4 -17 -2 -7
14 14 22 39 26 31 5z m3910 5 c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13 8 0 17
-4 20 -9z m2014 -13 c16 -16 15 -28 -2 -28 -17 0 -38 24 -29 33 9 10 17 9 31
-5z m-2220 -14 c50 -4 92 -10 92 -15 0 -5 9 -9 20 -9 11 0 20 -5 20 -11 0 -7
-7 -8 -17 -3 -19 8 -55 11 -220 15 -57 1 -100 5 -97 8 10 11 79 29 94 25 8 -1
57 -6 108 -10z m-3851 -52 c-15 -14 -27 -22 -27 -16 0 14 41 57 48 49 3 -3 -6
-18 -21 -33z m139 -10 c-8 -9 -19 -13 -22 -9 -10 9 16 38 28 31 6 -4 4 -13 -6
-22z m4023 19 c7 -5 11 -14 7 -20 -7 -11 -42 -6 -111 15 l-40 13 65 0 c36 0
71 -3 79 -8z m1736 -11 c10 -11 16 -23 12 -26 -6 -7 -56 27 -57 39 0 14 27 7
45 -13z m-2846 -2 c0 -7 -4 -22 -8 -33 -5 -11 -5 -25 0 -31 11 -13 0 -74 -12
-74 -5 0 -9 -7 -9 -15 0 -12 4 -13 19 -5 38 20 44 9 13 -23 -37 -38 -45 -75
-12 -57 17 9 19 8 13 -7 -4 -10 -15 -24 -25 -31 -26 -19 -24 -52 2 -45 20 5
20 5 3 -26 -10 -17 -24 -31 -30 -31 -19 0 -16 -27 3 -38 27 -15 6 -32 -38 -32
l-40 0 7 53 c3 28 13 84 21 124 8 40 14 90 14 112 0 22 4 42 10 46 5 3 7 12 3
20 -3 9 0 21 6 28 6 8 9 23 6 35 -2 13 3 26 13 32 24 13 42 12 41 -2z m3271 2
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-2445 -24 c19 -10 18 -11 -10 -14 -16 -2 -69 1 -118 6 -50 4 -91 4 -96 -1 -5
-5 -11 -4 -15 2 -17 27 192 34 239 7z m2250 14 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m125 -9 c0 -5 -7 -11 -15 -15 -15 -5
-20 5 -8 17 9 10 23 9 23 -2z m-2316 -7 c24 -9 19 -24 -9 -24 -18 0 -23 4 -19
15 7 17 7 17 28 9z m76 -24 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m2035 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m-5851 -24 c-7 -17 -44 -23 -44 -6 0 10 41 31 48 25
1 -1 0 -10 -4 -19z m3949 7 c5 -3 4 -10 -2 -16 -12 -12 -101 10 -101 25 0 9
85 1 103 -9z m-3843 -6 c0 -2 -16 -18 -35 -37 -26 -26 -35 -30 -35 -17 0 10
15 26 33 37 33 20 37 22 37 17z m3517 -10 c31 -3 51 -13 42 -20 -10 -8 -239
17 -239 25 0 9 81 7 197 -5z m2148 2 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15
6 0 14 -5 17 -11z m-5575 -16 c0 -12 -43 -53 -57 -53 -19 0 -16 8 15 35 27 25
42 31 42 18z m3550 -12 c0 -5 -9 -7 -20 -4 -11 3 -20 7 -20 9 0 2 9 4 20 4 11
0 20 -4 20 -9z m1948 -7 c25 -17 29 -34 8 -34 -7 0 -21 11 -30 25 -19 29 -11
32 22 9z m-1784 -15 c18 -17 0 -18 -57 -5 -79 18 -84 28 -12 21 33 -4 64 -11
69 -16z m-3626 -5 c-4 -21 -55 -60 -65 -51 -7 8 45 67 60 67 4 0 7 -7 5 -16z
m5329 -6 c26 -24 29 -38 10 -38 -17 0 -59 44 -50 53 10 11 15 9 40 -15z
m-5797 -3 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m6110 5
c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z
m-2316 -21 c8 -9 -4 -10 -54 -4 -36 5 -84 7 -108 6 -31 -1 -41 2 -36 10 9 15
182 5 198 -12z m-3241 -24 c-17 -23 -50 -42 -59 -33 -8 9 41 57 59 57 17 1 17
0 0 -24z m5477 9 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z
m-5840 -9 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4 -7 4
-15z m120 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m3573 3 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m1846
-12 c17 -13 20 -20 12 -26 -12 -7 -51 21 -51 37 0 13 13 9 39 -11z m-1734 -1
c8 -13 -45 -13 -65 0 -11 7 -6 10 22 10 20 0 40 -4 43 -10z m71 0 c9 0 13 -4
10 -9 -7 -11 -56 -2 -56 11 0 6 7 7 16 4 9 -3 22 -6 30 -6z m1889 -9 c7 -12
-12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15 -9z m-296 -20 c13 -10 21 -23
17 -30 -11 -18 -23 -13 -46 19 -25 35 -9 41 29 11z m-5399 -1 c0 -5 -7 -10
-15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m3580 0 c0 -5
-11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z
m-3452 -22 c-18 -26 -38 -35 -38 -16 0 18 20 38 38 38 12 0 12 -3 0 -22z
m3362 12 c0 -5 -15 -10 -34 -10 -19 0 -38 5 -41 10 -4 6 10 10 34 10 23 0 41
-4 41 -10z m-3020 -7 c0 -12 -43 -53 -57 -53 -19 0 -16 10 9 37 20 22 48 31
48 16z m4852 -10 c23 -21 23 -43 0 -43 -23 0 -56 35 -47 49 9 16 26 14 47 -6z
m298 7 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20
-10z m-5400 -18 c0 -14 -39 -46 -47 -39 -3 4 2 16 12 27 19 21 35 26 35 12z
m3557 -8 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1768 -5 c10
-15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m-5020 -39 c-17 -19
-45 -27 -45 -13 0 4 10 17 22 30 28 30 51 14 23 -17z m4723 15 l23 -24 -26 -7
c-18 -4 -32 -1 -46 12 -19 17 -19 19 -3 31 23 17 25 17 52 -12z m-4948 -3 c0
-13 -36 -43 -43 -36 -9 9 16 44 30 44 7 0 13 -4 13 -8z m548 -9 c-2 -10 -13
-19 -26 -21 -24 -4 -29 7 -10 26 18 18 40 15 36 -5z m452 0 c0 -5 -51 -50 -82
-72 -11 -7 -22 -10 -26 -7 -10 10 79 95 94 89 8 -3 14 -7 14 -10z m2200 7 c0
-5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m1970
-5 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-4840 -13 c0
-12 -39 -52 -50 -52 -15 0 -12 22 7 42 16 18 43 25 43 10z m4528 -15 c23 -25
20 -37 -8 -37 -22 0 -54 35 -45 49 10 17 30 13 53 -12z m-4218 4 c0 -12 -29
-35 -36 -28 -11 10 5 37 21 37 8 0 15 -4 15 -9z m-570 -16 c-7 -8 -19 -15 -27
-15 -13 0 -13 3 -3 15 7 8 19 15 27 15 13 0 13 -3 3 -15z m990 3 c0 -7 -21
-30 -48 -51 -63 -52 -162 -147 -162 -157 0 -9 -50 -43 -55 -38 -2 2 27 37 65
78 39 41 70 76 70 79 0 3 21 27 47 53 45 46 83 62 83 36z m4030 -8 c0 -21 -15
-27 -25 -10 -7 12 2 30 16 30 5 0 9 -9 9 -20z m-4590 0 c0 -5 -7 -10 -16 -10
-8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m1390 1 c0 -5 -37 -11 -82
-13 -100 -6 -150 4 -63 13 98 9 145 10 145 0z m-1490 -14 c0 -20 -37 -50 -50
-42 -8 5 -4 15 11 31 25 27 39 31 39 11z m1190 -3 c0 -2 -12 -4 -26 -4 -14 0
-23 4 -20 9 6 8 46 4 46 -5z m-1435 -15 c0 -8 -10 -15 -22 -17 -25 -4 -30 7
-11 26 15 15 33 10 33 -9z m425 6 c0 -8 -6 -15 -14 -15 -17 0 -28 14 -19 24
12 12 33 6 33 -9z m4720 5 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m510 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10
10 16 10 5 0 9 -4 9 -10z m-785 -9 c6 -10 -11 -24 -32 -27 -16 -3 -26 13 -18
26 8 13 42 13 50 1z m-4562 -8 c9 -9 -22 -43 -40 -43 -17 0 -17 10 4 32 17 19
26 22 36 11z m227 -1 c0 -15 -24 -32 -44 -32 -18 0 -19 1 -1 20 18 20 45 27
45 12z m4540 -7 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0
15 -7 15 -15z m-555 -25 c25 -26 26 -30 11 -36 -19 -7 -79 43 -70 58 10 15 30
8 59 -22z m-4415 0 c0 -14 -20 -22 -37 -16 -12 5 -13 9 -3 21 11 14 40 10 40
-5z m620 11 c0 -10 -73 -71 -86 -71 -4 0 10 18 31 40 39 39 55 49 55 31z
m4050 -27 c0 -8 -9 -14 -20 -14 -22 0 -28 26 -7 33 16 6 27 -1 27 -19z m224
11 c3 -9 -1 -18 -10 -22 -19 -7 -39 11 -30 26 10 16 33 13 40 -4z m-6078 -22
c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m1458 18 c3 -4 -4
-17 -15 -27 -17 -15 -22 -16 -29 -4 -5 9 -5 19 2 27 12 15 34 17 42 4z m-684
-10 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z
m250 -26 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15
-15z m228 3 c-3 -7 -13 -13 -23 -13 -10 0 -20 6 -22 13 -3 7 5 12 22 12 17 0
25 -5 23 -12z m302 5 c0 -12 -33 -43 -47 -43 -17 0 -17 10 4 32 16 18 43 25
43 11z m3890 -65 c0 -18 -38 -6 -63 20 -21 21 -24 31 -15 40 9 9 19 4 45 -21
18 -18 33 -35 33 -39z m315 46 c0 -10 -8 -20 -18 -22 -22 -4 -35 27 -16 39 20
12 34 5 34 -17z m231 15 c3 -6 2 -15 -2 -19 -12 -12 -38 5 -30 19 9 14 23 14
32 0z m584 2 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m-6290
-15 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m216 -23 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m4359 5
c40 -32 101 -99 168 -186 60 -78 134 -193 129 -199 -1 -2 5 -11 14 -20 11 -11
13 -18 5 -21 -6 -2 -11 -8 -11 -13 0 -4 6 -6 13 -4 7 3 22 -16 36 -48 13 -28
29 -58 35 -66 6 -7 16 -29 23 -49 10 -31 10 -37 -5 -48 -9 -7 -20 -10 -24 -7
-5 2 -15 -3 -24 -11 -13 -14 -13 -16 -1 -16 8 0 27 7 43 14 15 8 31 13 36 10
19 -12 5 -32 -32 -49 -41 -18 -56 -41 -20 -30 23 7 28 -13 8 -33 -18 -18 -38
-15 -38 6 0 43 -121 298 -198 417 -62 97 -150 203 -224 269 -34 31 -59 58 -57
60 13 12 76 46 85 46 6 0 24 -10 39 -22z m-3535 -2 c0 -8 -4 -17 -8 -20 -13
-8 -35 11 -28 23 10 16 36 14 36 -3z m300 8 c0 -16 -23 -44 -35 -44 -19 0 -19
9 2 32 16 17 33 24 33 12z m499 -21 c12 -13 7 -21 -45 -68 -71 -63 -204 -232
-264 -335 -23 -41 -53 -91 -65 -110 -38 -61 -106 -221 -130 -305 -10 -33 -21
-64 -25 -70 -4 -5 -11 -26 -15 -45 -11 -52 -13 -53 -55 -23 -37 26 -52 53 -30
53 6 0 10 -4 10 -8 0 -10 50 -35 57 -28 9 9 -23 47 -44 52 -17 5 -23 13 -23
36 0 20 4 27 12 22 7 -5 9 -2 5 9 -3 9 2 39 12 66 19 55 31 73 32 50 1 -18 18
21 18 41 0 9 -4 8 -9 -5 -6 -13 -9 -15 -9 -5 0 8 6 29 14 45 11 22 13 24 9 6
-4 -15 -2 -21 4 -17 6 3 14 21 17 39 7 32 87 199 106 221 5 6 39 55 74 109 76
115 162 217 225 263 48 36 87 38 119 7z m-1024 7 c3 -6 -1 -13 -10 -16 -19 -8
-30 0 -20 15 8 14 22 14 30 1z m2788 -24 c140 -53 385 -123 510 -147 99 -19
115 -26 140 -53 15 -16 27 -36 27 -43 0 -7 7 -10 15 -7 11 4 13 2 9 -9 -3 -8
8 -41 25 -72 17 -32 31 -64 31 -72 0 -7 7 -25 16 -38 16 -25 12 -45 -9 -45 -9
0 -11 -25 -10 -87 1 -49 -1 -128 -5 -178 -6 -76 -10 -90 -26 -93 -17 -4 -18 5
-15 167 5 223 -13 210 -25 -19 -8 -149 -12 -170 -26 -170 -15 0 -16 6 -10 43
5 23 5 103 1 177 -7 113 -8 122 -9 53 -3 -137 -22 -286 -39 -303 -14 -13 -15
-11 -9 25 12 72 18 365 8 365 -12 0 -21 -58 -23 -145 -1 -38 -5 -97 -9 -130
-6 -48 -8 -29 -10 95 -1 85 -2 161 -1 168 1 6 -3 12 -9 12 -5 0 -10 -21 -10
-48 0 -27 -3 -91 -7 -143 -7 -93 -8 -94 -14 -39 -4 30 -7 94 -8 143 -1 51 -5
87 -11 87 -5 0 -10 -24 -10 -54 0 -30 -4 -58 -10 -61 -5 -3 -10 -18 -10 -31 0
-16 -5 -24 -12 -22 -9 3 -14 34 -16 96 -1 51 -7 92 -11 92 -5 0 -12 -32 -16
-71 -9 -88 -26 -79 -27 15 0 36 -4 66 -8 66 -4 0 -11 -36 -14 -80 -4 -44 -11
-80 -15 -80 -8 0 -21 96 -21 158 0 63 -19 21 -22 -48 -2 -49 -7 -65 -18 -65
-11 0 -14 17 -13 83 0 45 -4 82 -8 82 -5 0 -9 -29 -9 -64 0 -38 -5 -68 -11
-72 -9 -5 -10 15 -7 74 l5 81 -24 -46 c-13 -26 -23 -57 -23 -70 0 -13 -5 -23
-11 -23 -9 0 -10 24 -5 85 4 55 3 85 -4 85 -5 0 -10 -10 -10 -22 0 -13 -7 -32
-15 -42 -8 -11 -15 -30 -15 -44 0 -14 -4 -21 -11 -17 -6 4 -9 24 -8 46 2 21 0
41 -4 45 -4 4 -7 22 -7 41 0 18 -5 33 -11 33 -6 0 -9 -6 -6 -13 3 -8 -3 -42
-12 -77 -22 -81 -35 -65 -35 47 0 74 -2 83 -18 83 -18 0 -24 -11 -11 -23 12
-12 9 -57 -4 -57 -6 0 -13 -14 -15 -32 -2 -17 -8 -33 -14 -35 -16 -5 -26 85
-14 127 12 43 13 60 1 60 -5 0 -13 -19 -19 -42 -6 -24 -18 -52 -27 -63 -15
-19 -16 -18 -10 20 4 22 9 52 12 68 3 16 1 27 -5 27 -6 0 -14 -16 -18 -35 -9
-47 -24 -44 -24 4 0 22 -4 43 -10 46 -6 4 -10 -11 -10 -39 0 -46 -7 -76 -19
-76 -8 0 -5 109 4 138 5 14 3 22 -4 22 -6 0 -11 -9 -11 -19 0 -11 -4 -22 -9
-25 -4 -3 -11 -24 -15 -46 -4 -25 -12 -40 -21 -40 -11 0 -13 14 -9 70 2 39 1
70 -4 70 -4 0 -14 -20 -21 -45 -16 -54 -28 -57 -37 -10 -3 19 -10 35 -14 35
-8 0 -20 -50 -20 -86 0 -13 -4 -24 -10 -24 -5 0 -10 19 -10 43 0 52 -17 84
-26 50 -4 -13 -10 -23 -15 -23 -5 0 -9 -18 -9 -40 0 -22 -4 -40 -10 -40 -5 0
-10 22 -10 49 0 28 -4 53 -10 56 -5 3 -10 -1 -10 -9 0 -9 -7 -16 -15 -16 -9 0
-15 -10 -16 -27 -1 -28 -1 -28 -11 5 -16 49 -31 39 -26 -18 4 -35 2 -50 -6
-47 -6 2 -12 12 -14 23 -4 27 -24 64 -34 64 -4 0 -8 -11 -8 -25 0 -14 -4 -25
-10 -25 -5 0 -10 11 -10 24 0 21 -21 41 -32 30 -3 -2 -1 -27 3 -56 3 -29 5
-55 3 -57 -8 -9 -23 13 -35 54 -7 25 -17 45 -21 45 -4 0 -8 -27 -8 -59 0 -33
-5 -63 -10 -66 -11 -7 -33 40 -32 68 1 19 -17 24 -19 5 -1 -7 -1 -32 1 -55 1
-27 -3 -43 -9 -43 -12 0 -15 7 -25 53 -10 45 -28 32 -24 -18 2 -25 0 -45 -4
-45 -5 0 -8 9 -8 19 0 11 -4 23 -10 26 -5 3 -10 21 -11 38 0 18 -3 25 -6 17
-3 -8 -11 -18 -16 -22 -7 -4 -7 -21 0 -52 6 -25 7 -46 3 -46 -11 0 -30 57 -30
86 0 13 -4 24 -10 24 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 5 -10
10 0 6 -4 10 -9 10 -6 0 -13 -32 -17 -71 -8 -82 -22 -80 -31 5 -4 38 -10 56
-19 56 -8 0 -13 -3 -13 -7 3 -18 -3 -127 -6 -118 -2 6 -6 36 -10 68 -7 58 -18
74 -28 36 -3 -12 -10 -18 -17 -14 -6 4 -9 13 -6 21 3 8 -1 14 -9 14 -8 0 -15
-6 -16 -12 0 -10 -2 -10 -6 0 -2 6 -15 12 -28 12 -30 0 -77 18 -87 33 -10 16
-10 39 0 33 14 -8 193 21 249 41 29 10 77 40 109 68 97 84 125 96 246 106 98
8 148 19 313 69 101 30 190 26 298 -14z m2023 23 c11 -19 -13 -34 -31 -19 -13
11 -13 15 -3 21 18 12 26 11 34 -2z m-4232 -36 c-13 -14 -27 -23 -31 -20 -8 9
37 58 48 52 4 -3 -3 -17 -17 -32z m3676 -27 c-14 -14 -65 37 -55 54 5 8 16 3
36 -16 20 -19 26 -31 19 -38z m314 55 c3 -5 -1 -14 -9 -21 -12 -10 -18 -10
-30 0 -13 11 -13 15 -3 21 17 11 35 11 42 0z m-4969 -11 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m695 1 c0 -5 -6 -14 -14
-20 -16 -13 -32 2 -21 19 8 12 35 13 35 1z m5120 -6 c0 -8 -7 -15 -15 -15 -16
0 -20 12 -8 23 11 12 23 8 23 -8z m-5565 -4 c3 -5 1 -12 -5 -16 -5 -3 -10 1
-10 9 0 18 6 21 15 7z m228 -6 c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13 0 17
30 18 33 0z m4407 -21 c0 -17 -36 -19 -46 -3 -8 14 14 29 33 22 7 -3 13 -11
13 -19z m-4110 -4 c0 -11 -2 -20 -4 -20 -2 0 -11 -3 -20 -6 -19 -7 -22 16 -4
34 18 18 28 15 28 -8z m4335 5 c0 -5 -10 -11 -22 -13 -16 -2 -23 2 -23 13 0
11 7 15 23 13 12 -2 22 -7 22 -13z m-5173 -17 c-7 -7 -12 -8 -12 -2 0 14 12
26 19 19 2 -3 -1 -11 -7 -17z m626 4 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14
24 9 9 26 2 31 -12z m-1022 -29 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1
-15 -2 -18z m5263 14 c13 -16 5 -27 -20 -27 -24 0 -35 20 -18 31 19 12 25 11
38 -4z m-3969 -17 c-6 -11 -18 -20 -27 -20 -14 0 -14 2 2 20 10 11 22 20 27
20 5 0 4 -9 -2 -20z m704 5 c11 -8 30 -15 43 -15 39 0 37 -26 -4 -67 -62 -63
-92 -101 -154 -197 -56 -85 -178 -320 -183 -348 -1 -7 -4 -17 -8 -23 -20 -32
-70 -239 -75 -310 -3 -46 -9 -88 -13 -94 -6 -11 -58 2 -115 28 -11 5 -37 17
-57 26 -21 10 -38 21 -38 25 0 13 29 20 36 9 4 -6 13 -4 25 7 18 16 19 16 19
0 0 -28 23 -13 36 25 l12 34 1 -38 c1 -45 24 -47 29 -4 2 15 8 27 14 27 6 0 8
-12 5 -30 -4 -17 -2 -30 3 -30 6 0 10 13 10 29 0 17 4 32 9 35 7 5 18 65 27
151 1 11 11 54 23 95 11 41 18 76 16 79 -9 8 -35 -80 -69 -229 l-15 -65 4 80
c2 44 7 89 10 100 5 15 4 16 -3 5 -5 -8 -17 -49 -27 -90 -20 -80 -43 -124 -30
-55 5 22 9 58 11 80 1 22 12 67 23 99 12 33 21 69 21 81 0 12 3 25 7 29 4 4 8
19 10 34 4 42 -22 -30 -68 -188 -42 -141 -56 -175 -44 -100 5 25 9 52 10 60 0
9 4 20 7 25 3 6 6 21 6 35 1 71 101 310 175 420 63 93 192 240 210 240 5 0 20
9 34 20 30 24 41 24 67 5z m2538 -52 c66 -70 147 -175 210 -273 63 -100 168
-335 168 -377 0 -11 5 -24 11 -30 19 -19 4 -41 -25 -36 -32 6 -111 -29 -126
-56 -13 -25 -13 -81 0 -81 6 0 10 -8 10 -18 0 -17 20 -32 64 -46 26 -9 96 12
103 31 5 13 8 12 15 -7 12 -32 -10 -53 -85 -81 -34 -13 -64 -24 -67 -25 -3 0
-13 -4 -22 -8 -34 -15 -44 1 -62 100 -39 213 -114 460 -178 588 -41 82 -131
219 -184 283 -24 28 -44 53 -44 56 0 8 33 18 75 23 22 3 44 9 50 15 15 15 19
12 87 -58z m416 55 c14 -14 16 -48 2 -48 -14 0 -50 42 -44 52 7 12 28 10 42
-4z m532 -2 c0 -8 -6 -16 -13 -19 -19 -7 -41 8 -33 22 10 16 46 14 46 -3z
m-4394 -7 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z
m-941 -39 c-5 -8 -11 -8 -17 -2 -6 6 -7 16 -3 22 5 8 11 8 17 2 6 -6 7 -16 3
-22z m5035 15 c0 -21 -30 -31 -40 -14 -11 17 -3 29 21 29 10 0 19 -7 19 -15z
m-4810 1 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z m800 -6
c0 -13 -28 -25 -38 -16 -3 4 0 11 8 16 20 13 30 12 30 0z m190 1 c0 -5 -7 -14
-15 -21 -12 -10 -15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z m4038 -3 c-3
-7 -11 -13 -18 -13 -7 0 -15 6 -17 13 -3 7 4 12 17 12 13 0 20 -5 18 -12z
m-297 -24 c10 -13 10 -17 -2 -25 -9 -6 -18 -3 -26 8 -22 29 4 46 28 17z
m-3871 -19 c-7 -9 -15 -13 -18 -10 -3 2 1 11 8 20 7 9 15 13 18 10 3 -2 -1
-11 -8 -20z m4089 11 c10 -11 8 -16 -9 -21 -28 -9 -52 12 -30 25 20 13 26 12
39 -4z m991 4 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
4 -10z m-3599 -14 c-16 -19 -130 -56 -173 -56 -57 -1 -171 -37 -190 -60 -45
-56 -5 -163 82 -222 60 -41 210 -83 254 -72 13 3 27 1 31 -5 4 -8 12 -8 25 -1
11 6 23 9 27 8 4 -1 19 4 32 11 21 12 23 11 18 -3 -9 -22 -2 -20 61 15 56 31
75 34 46 5 -9 -9 -14 -19 -11 -22 6 -7 47 18 47 28 0 5 5 8 11 8 6 0 26 12 45
26 42 33 43 24 3 -27 -18 -23 -25 -39 -17 -38 7 0 29 20 48 44 19 24 40 44 46
44 7 1 24 8 40 15 45 24 49 7 7 -37 -21 -23 -44 -46 -52 -53 -9 -7 -10 -13 -4
-17 6 -4 27 12 47 34 44 47 108 89 136 89 31 0 24 -14 -32 -69 -29 -27 -49
-53 -45 -57 4 -4 26 10 50 32 23 22 54 48 69 57 28 18 88 23 88 8 0 -4 -25
-25 -55 -45 -30 -20 -55 -41 -55 -46 0 -14 2 -14 78 30 101 59 114 57 306 -46
246 -132 242 -130 452 -374 170 -199 218 -259 257 -322 51 -85 60 -121 41
-168 -16 -39 -100 -127 -112 -116 -3 4 11 20 31 37 21 17 45 45 53 62 15 28
11 26 -42 -21 -32 -28 -60 -49 -63 -46 -3 3 5 15 19 27 26 23 60 71 60 84 0 4
-5 1 -10 -8 -14 -24 -89 -73 -98 -64 -4 4 -2 10 5 12 13 5 83 96 83 108 0 4
-21 -14 -47 -39 -41 -41 -73 -58 -73 -40 0 4 18 26 40 50 34 37 49 64 37 64
-2 0 -23 -18 -47 -40 -24 -22 -47 -40 -52 -40 -15 0 -8 18 18 49 14 17 21 31
15 31 -6 0 -11 -4 -11 -10 0 -5 -5 -10 -11 -10 -6 0 -17 -7 -26 -15 -13 -14
-23 -6 -97 72 -133 142 -187 203 -260 291 -64 78 -101 110 -113 98 -3 -2 0
-11 6 -18 7 -7 27 -31 46 -53 42 -48 37 -60 -8 -17 -18 18 -39 32 -46 32 -15
0 45 -87 155 -221 46 -57 104 -129 129 -159 24 -30 60 -80 78 -112 19 -31 56
-84 81 -117 53 -69 52 -79 -11 -128 -53 -39 -79 -53 -103 -53 -31 0 -24 15 14
28 19 7 54 30 78 51 33 30 40 42 31 51 -9 10 -18 5 -42 -23 -30 -34 -71 -58
-121 -72 -18 -4 -21 -3 -11 3 8 6 11 17 8 26 -4 10 -1 16 7 16 18 0 76 51 76
67 0 18 -15 16 -31 -3 -32 -39 -135 -72 -123 -39 4 8 12 15 20 15 8 0 14 5 14
10 0 6 6 10 13 10 16 1 76 50 60 50 -7 0 -38 -13 -68 -30 -30 -16 -59 -30 -64
-30 -8 0 -87 68 -150 130 -41 40 -75 80 -68 80 8 0 110 -83 165 -134 50 -47
71 -60 80 -52 7 7 -42 54 -163 156 -44 37 -93 82 -110 100 -30 33 -117 80
-145 80 -19 -1 60 -70 80 -70 15 0 80 -58 80 -73 0 -4 -10 3 -22 16 -21 22
-88 46 -88 30 0 -18 127 -194 186 -258 119 -128 195 -220 186 -223 -6 -2 -25
12 -43 30 -132 139 -259 264 -259 254 0 -14 98 -122 205 -227 70 -68 86 -89
68 -89 -8 0 -149 115 -218 178 -70 64 -85 75 -85 66 0 -11 42 -56 96 -103 88
-78 141 -130 137 -135 -6 -6 -44 12 -53 24 -3 4 -25 21 -50 39 -25 17 -82 68
-128 113 -45 44 -85 78 -89 74 -4 -4 48 -60 115 -126 67 -66 122 -122 122
-125 0 -27 -90 53 -289 259 -35 36 -73 68 -84 72 -11 4 -59 37 -107 74 -47 37
-106 80 -130 96 -25 15 -58 38 -73 51 -26 21 -139 87 -248 144 -62 33 -63 33
-50 56 27 47 27 47 67 17 22 -15 41 -30 43 -31 3 -2 -1 -9 -7 -15 -7 -7 -12
-17 -12 -23 0 -5 8 0 18 13 l17 22 67 -39 c75 -44 173 -116 298 -220 46 -39
87 -68 90 -65 8 9 -137 143 -232 214 -48 36 -133 90 -189 120 -55 30 -103 60
-106 66 -3 7 -11 10 -18 7 -8 -2 -24 2 -37 11 -35 25 -47 18 -14 -8 l28 -24
-22 -30 c-20 -27 -25 -29 -47 -19 -19 8 -23 16 -18 34 7 31 64 123 76 123 5 0
9 7 9 15 0 27 -29 16 -46 -17 -9 -18 -25 -44 -36 -57 -10 -14 -27 -44 -38 -68
-18 -41 -50 -59 -50 -28 0 20 40 138 51 150 5 5 9 17 9 26 0 9 7 19 15 23 20
7 19 26 -1 26 -9 0 -22 -17 -31 -40 -9 -22 -20 -40 -25 -40 -4 0 -8 -6 -8 -13
0 -8 -7 -28 -16 -46 -13 -25 -19 -30 -30 -20 -23 19 -15 67 22 142 16 32 18
57 5 57 -4 0 -20 -27 -34 -59 -14 -32 -30 -62 -35 -65 -6 -3 -23 -1 -39 5 -26
10 -27 12 -10 23 9 7 17 20 17 28 0 8 7 23 15 34 16 21 20 44 7 44 -8 0 -19
-20 -47 -82 -15 -33 -21 -38 -52 -38 -35 0 -35 0 -19 25 9 13 16 30 16 37 0 7
7 26 15 42 9 16 13 32 10 35 -8 7 -25 -20 -25 -40 0 -9 -6 -19 -14 -22 -7 -3
-16 -19 -19 -37 -6 -28 -60 -90 -79 -90 -11 0 -10 46 1 68 22 42 52 124 47
130 -7 7 -6 9 -39 -63 -14 -33 -31 -79 -37 -102 -6 -24 -12 -43 -14 -43 -1 0
-12 13 -23 29 -19 25 -20 31 -8 54 7 14 18 49 24 78 6 29 17 59 23 66 6 7 8
16 4 20 -12 12 -59 -82 -56 -112 0 -3 -7 -19 -15 -36 -11 -20 -21 -29 -31 -25
-9 3 -20 6 -25 6 -11 0 -12 25 0 48 44 91 63 142 52 142 -5 0 -21 -26 -36 -57
-56 -121 -60 -126 -90 -134 -16 -5 -31 -7 -33 -4 -6 5 27 102 54 158 12 26 19
47 16 47 -18 0 -68 -106 -98 -204 -5 -16 -15 -31 -22 -34 -9 -3 -11 13 -8 67
2 39 0 71 -5 71 -5 0 -10 -21 -10 -47 -1 -27 -2 -65 -3 -85 -1 -20 -7 -43 -13
-49 -11 -10 -13 -3 -14 36 -2 94 2 120 27 171 24 50 32 104 16 104 -5 0 -14
-18 -21 -41 -7 -22 -19 -43 -27 -46 -8 -3 -13 -12 -10 -20 3 -7 1 -46 -4 -86
-5 -40 -12 -93 -16 -119 -3 -27 -10 -48 -15 -48 -11 0 -7 170 5 220 3 14 9 39
12 55 4 17 11 41 17 55 6 14 11 27 12 30 1 3 7 19 14 35 7 17 17 40 22 53 5
13 23 43 40 68 36 52 23 60 -15 9 -15 -19 -25 -35 -22 -35 3 0 -9 -24 -28 -52
-18 -29 -39 -70 -47 -90 -14 -41 -21 -45 -37 -25 -10 12 18 92 76 219 12 26
22 51 22 57 0 5 3 11 8 13 4 2 18 25 32 53 14 27 43 72 65 100 51 63 59 75 46
75 -18 0 -118 -131 -149 -193 -36 -74 -65 -140 -65 -147 -1 -3 -6 -11 -13 -17
-7 -7 -14 -18 -17 -25 -2 -7 -8 -10 -12 -6 -4 4 1 24 12 45 11 21 26 56 33 78
7 22 20 50 28 63 8 12 11 22 7 22 -3 0 4 15 16 33 47 67 133 182 143 190 6 4
65 5 131 2 66 -3 152 -1 191 4 40 6 96 14 125 17 30 4 74 10 99 14 25 4 49 8
54 9 5 0 3 -5 -3 -13z m2322 -20 c7 -17 -22 -31 -36 -17 -5 5 -6 14 -3 20 10
16 33 14 39 -3z m214 -12 c2 -6 -8 -16 -22 -22 -34 -16 -35 -15 -35 7 0 27 49
39 57 15z m193 -24 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5
0 9 -4 9 -10z m-282 -22 c3 -17 -35 -32 -44 -17 -8 13 16 41 30 36 6 -2 12
-11 14 -19z m-4628 -4 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z
m4553 -38 c6 -14 -12 -26 -39 -26 -17 0 -19 26 -1 33 22 10 35 7 40 -7z m-684
-51 c0 -5 -6 -1 -14 9 -8 11 -15 24 -15 30 0 5 7 1 15 -10 8 -10 14 -23 14
-29z m-3882 13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2571
-11 c2 -12 -1 -15 -12 -11 -19 7 -22 38 -3 32 6 -3 13 -12 15 -21z m2712 14
c0 -9 -41 -23 -48 -16 -2 2 -1 8 3 14 8 13 45 15 45 2z m-4900 -26 c0 -8 -4
-15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m3530 -42 c6
-21 18 -52 26 -70 8 -17 19 -60 24 -95 15 -92 15 -92 23 -105 10 -16 14 -120
5 -128 -4 -4 -14 -5 -23 -2 -12 5 -15 26 -17 94 -1 48 -1 105 0 126 1 22 -2
37 -9 37 -6 0 -8 7 -5 15 3 9 -4 36 -15 60 -11 24 -22 51 -23 59 -1 9 -5 19
-9 22 -10 10 -9 24 2 24 5 0 15 -17 21 -37z m1290 27 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-3767 -15 c-3 -9 -8 -14 -10
-11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m547 -30 c0 -14 -4 -25 -10 -25 -5 0 -10
11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m-30 5 c0 -5 -5 -10 -11 -10 -5
0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m80 -16 c0 -8 -4 -14 -10 -14 -5
0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m3110 11 c-7 -8 -17 -12
-22 -9 -6 4 -5 10 3 15 22 14 33 10 19 -6z m-3060 -25 c0 -16 -4 -30 -10 -30
-5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10 -30z m-660 10 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1470 -10 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1442 -46
c-12 -26 -18 -32 -20 -20 -2 10 4 26 14 37 25 27 26 24 6 -17z m3628 17 c0 -5
-9 -11 -20 -14 -13 -3 -20 0 -20 9 0 8 9 14 20 14 11 0 20 -4 20 -9z m-5070
-31 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m3970 -40 c0 -16 3 -30 8 -30 4 0 7 -57 7 -128 0 -117 -5 -141 -27 -142 -5 0
-8 74 -8 165 0 103 4 165 10 165 6 0 10 -13 10 -30z m1000 15 c-7 -8 -16 -12
-21 -9 -13 8 -1 24 18 24 13 0 13 -3 3 -15z m-4990 -49 c0 -3 -4 -8 -10 -11
-5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1057 -148 c-42 -98 -45
-94 -10 17 12 40 19 53 21 38 2 -12 -3 -37 -11 -55z m360 30 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2697 -67 c-13 -14 -26 42 -16 67 4 12 8
5 13 -23 4 -22 5 -42 3 -44z m506 34 c0 -16 -27 -32 -37 -22 -3 4 -3 13 0 22
8 20 37 20 37 0z m-3218 -27 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2
2 -3 -1 -11 -7 -17z m-902 2 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m4138 -28 c4 -14 -83 -56 -98 -47 -18 11 -11 25 18
37 15 6 30 12 34 12 3 1 10 4 14 9 10 10 27 4 32 -11z m-3254 -44 c-4 -12 -10
-47 -15 -78 -4 -30 -12 -73 -17 -95 -8 -33 -9 -24 -6 44 5 112 13 151 31 151
10 0 12 -6 7 -22z m-354 3 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16
10 16 6 0 10 -4 10 -9z m410 -18 c0 -16 -7 -53 -15 -83 -8 -30 -15 -71 -15
-90 0 -19 -4 -41 -9 -49 -6 -9 -8 15 -5 60 3 58 11 171 14 187 0 1 7 2 15 2
10 0 15 -10 15 -27z m3210 7 c0 -9 -80 -50 -96 -50 -2 0 -4 8 -4 19 0 12 11
22 33 29 43 13 67 14 67 2z m-3343 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m2785 0 c6 -36 -6 -206 -14 -183 -6 19 -12 136 -9 183 0 12 5
22 10 22 5 0 11 -10 13 -22z m55 -60 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2
-15 2 -42 0 -60z m513 47 c0 -15 -79 -57 -92 -49 -18 11 -6 33 22 39 16 4 32
11 35 16 10 14 35 10 35 -6z m-630 -80 c0 -69 -3 -85 -15 -85 -12 0 -15 15
-15 78 0 43 3 82 7 85 18 18 23 0 23 -78z m-70 -10 c0 -37 -4 -65 -10 -65 -11
0 -14 113 -3 123 12 13 13 7 13 -58z m-48 -45 c-5 -88 -18 -73 -15 18 3 92 3
93 11 64 4 -12 5 -49 4 -82z m766 82 c2 -7 -5 -12 -15 -12 -10 0 -29 -9 -43
-20 -28 -22 -40 -25 -40 -10 0 12 71 60 84 56 6 -1 12 -8 14 -14z m-236 -34
c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9 -9 9 -15 0 -24z m598 12 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-4176 -61
c4 -4 7 -12 4 -19 -2 -6 -22 1 -51 21 -54 36 -54 61 1 28 20 -12 41 -26 46
-30z m3826 42 c0 -8 -84 -61 -96 -61 -2 0 -4 6 -4 13 0 7 19 23 43 34 48 25
57 27 57 14z m345 -41 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3
0 8 -4 11 -10z m-3322 -45 c15 -14 27 -30 27 -36 0 -15 -48 -109 -83 -161 -26
-39 -38 -48 -62 -48 -16 0 -37 -5 -45 -11 -11 -7 3 -9 53 -7 50 3 67 1 67 -9
0 -12 -19 -16 -85 -17 -43 -1 -43 -19 0 -30 42 -10 41 -32 -1 -29 -70 5 -134
57 -134 108 0 55 174 264 220 265 8 0 28 -11 43 -25z m2957 17 c0 -5 -16 -16
-35 -25 -19 -9 -35 -13 -35 -9 0 5 15 16 33 25 39 19 37 19 37 9z m-3820 -27
c6 3 10 -1 10 -9 0 -21 -10 -20 -40 5 -23 19 -23 19 -2 9 12 -6 27 -8 32 -5z
m3190 -26 c0 -16 -4 -29 -10 -29 -5 0 -10 16 -10 36 0 21 4 33 10 29 6 -3 10
-19 10 -36z m-3222 -17 c56 -42 37 -77 -22 -40 -26 17 -35 30 -36 51 0 31 3
30 58 -11z m1216 5 c15 -19 26 -46 26 -61 0 -14 -20 -69 -44 -121 l-45 -95
-65 0 c-82 0 -86 -17 -6 -26 90 -10 76 -30 -30 -43 -67 -7 -68 -26 -2 -26 35
0 47 -4 47 -15 0 -10 -18 -18 -60 -26 -33 -7 -62 -13 -65 -14 -12 -4 38 -19
68 -19 17 -1 32 -5 32 -11 0 -11 -27 -18 -79 -22 -19 -2 -36 -9 -39 -16 -3
-10 4 -12 32 -7 26 5 36 4 36 -5 0 -10 3 -10 14 -1 10 9 16 9 21 1 10 -15 0
-23 -32 -26 -16 -2 -47 -6 -71 -9 -38 -6 -45 -3 -72 24 -53 53 -36 134 72 326
101 181 139 222 210 224 19 1 34 -8 52 -32z m1903 -24 c-3 -10 -5 -2 -5 17 0
19 2 27 5 18 2 -10 2 -26 0 -35z m773 27 c0 -12 -15 -29 -40 -45 -49 -31 -50
-31 -50 -1 0 16 10 31 33 44 42 26 57 27 57 2z m-2425 -70 c0 -7 -5 -15 -10
-17 -16 -5 -49 23 -41 35 9 14 51 -1 51 -18z m-1478 -25 c-2 -5 -16 -11 -30
-13 -24 -3 -27 0 -27 28 l0 31 30 -18 c17 -9 29 -22 27 -28z m3909 34 c11 -18
-31 -52 -55 -44 -20 6 -20 7 7 30 31 28 38 30 48 14z m-2361 -50 c32 -19 35
-24 21 -37 -13 -13 -17 -12 -40 14 -42 48 -36 55 19 23z m100 -56 c26 -14 50
-35 53 -45 3 -14 7 -16 12 -8 6 9 13 9 31 -1 22 -11 150 -95 209 -136 43 -30
180 -168 180 -181 0 -24 -26 -11 -100 49 -111 91 -202 152 -344 232 -47 26
-86 54 -86 62 0 7 -5 15 -12 17 -10 4 -5 38 5 38 3 0 26 -12 52 -27z m-195
-21 c32 -26 30 -99 -5 -174 -27 -60 -56 -89 -115 -116 -32 -14 -33 -16 -12
-19 13 -3 41 5 62 16 22 11 40 17 40 12 0 -19 -59 -68 -99 -81 -22 -7 -41 -17
-41 -21 0 -15 26 -16 59 -2 18 8 35 12 38 10 12 -13 -26 -47 -74 -67 -32 -13
-51 -26 -46 -31 6 -6 24 -3 46 6 44 18 47 18 47 1 0 -7 -28 -26 -62 -42 -71
-32 -57 -46 15 -15 38 17 47 18 47 6 0 -18 -52 -45 -96 -51 -28 -3 -40 2 -74
34 -27 27 -40 48 -41 68 -3 50 2 98 10 119 5 11 23 57 41 102 34 89 105 203
143 233 20 16 27 17 35 7 16 -19 5 -101 -24 -169 -14 -33 -24 -62 -22 -64 9
-8 29 16 61 72 48 86 52 116 21 154 l-26 30 25 0 c14 0 35 -8 47 -18z m194
-80 c10 -12 15 -26 11 -31 -11 -19 -26 -12 -36 19 -12 33 -29 40 -29 13 -1
-16 -2 -16 -11 1 -9 14 -8 20 2 26 15 10 37 0 63 -28z m85 -45 c25 -13 72 -41
105 -63 32 -21 72 -48 90 -58 52 -33 188 -145 231 -191 22 -24 82 -89 134
-144 51 -56 89 -101 84 -101 -4 0 -49 35 -99 78 -176 148 -291 240 -370 296
-43 30 -86 62 -96 70 -9 8 -25 17 -37 21 -12 3 -21 11 -21 16 0 5 -6 9 -12 9
-22 1 -80 60 -74 76 7 18 10 18 65 -9z m-106 -148 c21 -39 17 -112 -8 -171 -8
-20 -12 -38 -9 -40 2 -3 -17 -16 -43 -29 -63 -33 -69 -55 -7 -29 26 11 49 19
51 17 11 -12 -15 -39 -58 -59 -26 -13 -48 -26 -49 -30 0 -13 33 -9 62 6 20 11
30 12 35 3 3 -6 1 -13 -6 -15 -22 -7 -66 -50 -59 -57 4 -4 21 0 39 9 39 19 39
19 39 4 0 -7 -20 -21 -45 -31 -40 -17 -46 -17 -66 -3 -46 32 -59 66 -59 158 0
48 5 90 10 93 6 4 8 10 5 15 -6 10 51 124 70 140 23 19 29 -31 15 -117 -6 -41
-10 -76 -8 -78 2 -2 10 0 18 5 9 6 10 10 3 10 -8 0 -3 19 13 55 31 71 32 118
2 140 -23 16 -23 16 -3 31 26 19 37 14 58 -27z m213 -21 c55 -35 156 -106 224
-159 125 -97 299 -254 290 -262 -6 -6 -205 135 -367 258 -65 50 -129 98 -143
107 -30 20 -120 104 -120 112 0 13 21 3 116 -56z m324 -33 c7 -9 8 -15 2 -15
-5 0 -12 7 -16 15 -3 8 -4 15 -2 15 2 0 9 -7 16 -15z m-380 -10 c0 -8 -7 -15
-15 -15 -9 0 -12 6 -9 15 4 8 10 15 15 15 5 0 9 -7 9 -15z m137 -93 c199 -152
372 -297 364 -305 -8 -8 -123 70 -176 119 -58 53 -134 114 -144 114 -6 0 -11
3 -11 8 0 4 -20 20 -45 35 -25 16 -45 32 -45 36 0 4 -16 15 -35 24 -24 11 -33
21 -29 32 4 8 9 15 13 15 3 0 52 -35 108 -78z m278 60 c-3 -3 -11 0 -18 7 -9
10 -8 11 6 5 10 -3 15 -9 12 -12z m-1140 -12 c-3 -5 -24 -10 -45 -10 -21 0
-42 5 -45 10 -4 6 13 10 45 10 32 0 49 -4 45 -10z m722 -52 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m33 -39 c0 -11 -4 -17 -10 -14 -5 3 -10
13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m40 -29 c0 -11 -7 -20 -15 -20
-18 0 -19 12 -3 28 16 16 18 15 18 -8z m-50 -21 c-7 -11 -14 -18 -17 -15 -8 8
5 36 17 36 7 0 7 -6 0 -21z m97 -26 c51 -48 55 -65 27 -106 -14 -21 -27 -37
-29 -37 -12 0 -3 21 20 48 18 22 22 33 14 38 -7 4 -23 -6 -38 -25 -26 -31 -54
-40 -66 -21 -4 6 7 29 24 52 21 28 28 45 21 52 -10 10 -14 26 -6 26 2 0 17
-12 33 -27z m-56 -1 c-12 -23 -50 -57 -56 -50 -7 6 46 68 58 68 4 0 3 -8 -2
-18z m-521 -36 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m1218 -32 c12 -4 22 -10 22 -15 0 -15 -37 -10 -50 6 -13 16 -8 18
28 9z m72 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-640 -16 c0 -3 -11 -22 -25 -42 -28 -42 -34 -29 -7 17 15 27 32
41 32 25z m30 -30 c0 -12 -35 -64 -43 -64 -5 0 -7 3 -5 8 24 48 32 62 40 62 4
0 8 -3 8 -6z m30 -33 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16
6 0 10 -4 10 -9z m-390 -21 c0 -5 -21 -10 -46 -10 -27 0 -43 4 -39 10 3 6 24
10 46 10 21 0 39 -4 39 -10z m420 -11 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10
21 0 8 5 14 10 14 6 0 10 -9 10 -21z"/>
<path d="M4760 8180 l-45 -7 53 -1 c29 -1 52 2 52 8 0 6 -3 9 -7 8 -5 0 -28
-4 -53 -8z"/>
<path d="M4765 8090 l-40 -7 48 -2 c26 0 47 4 47 9 0 6 -3 9 -7 9 -5 -1 -26
-5 -48 -9z"/>
<path d="M4965 6460 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5592 6043 c-29 -28 -74 -58 -114 -78 -37 -17 -50 -35 -27 -35 19 0
114 58 143 86 37 38 36 63 -2 27z"/>
<path d="M5565 5900 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5510 5850 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4018 6083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5008 5963 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6870 4689 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6885 4650 c-11 -17 5 -31 19 -17 14 12 12 27 -4 27 -5 0 -12 -4 -15
-10z"/>
<path d="M6897 4603 c-11 -10 -8 -20 7 -26 10 -4 12 0 9 14 -6 21 -7 22 -16
12z"/>
<path d="M6920 4600 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3450 4844 c-86 -129 -173 -302 -184 -365 -1 -8 -7 -23 -13 -35 -7
-11 -9 -31 -6 -44 4 -15 2 -21 -5 -16 -16 10 -22 -7 -6 -22 11 -10 15 -8 24 7
5 11 10 26 10 34 0 8 7 28 16 46 13 25 13 35 4 46 -7 8 -8 15 -3 15 6 0 23 30
38 68 16 37 42 89 57 115 15 26 28 50 28 53 0 2 23 41 51 86 59 96 71 118 65
118 -3 0 -37 -48 -76 -106z"/>
<path d="M3226 4455 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M3205 4400 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3210 4348 c0 -21 14 -33 39 -36 l25 -2 -24 25 c-26 27 -40 32 -40
13z"/>
<path d="M6200 4962 c0 -11 120 -242 126 -242 9 0 -10 56 -34 100 -77 142 -92
165 -92 142z"/>
<path d="M3779 5018 c-24 -28 -50 -57 -56 -64 -7 -7 -13 -15 -13 -18 0 -3 -16
-29 -37 -57 -37 -52 -126 -219 -119 -226 4 -5 55 72 66 102 13 33 93 156 150
230 26 33 50 66 54 73 15 26 -3 10 -45 -40z"/>
<path d="M3585 4540 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4011 4547 c-11 -19 -21 -43 -21 -53 0 -11 11 2 26 30 29 57 26 73
-5 23z"/>
<path d="M3936 4493 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3975 4460 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4772 4198 c-18 -18 -15 -24 16 -22 36 2 36 2 22 19 -15 18 -22 19
-38 3z"/>
<path d="M4850 4150 c0 -5 26 -25 59 -44 32 -20 64 -43 71 -51 7 -8 17 -15 22
-15 13 0 -76 74 -119 100 -18 11 -33 15 -33 10z"/>
<path d="M6190 3885 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M5840 3873 c0 -6 34 -47 75 -90 41 -43 74 -73 75 -68 0 6 -34 46 -75
90 -40 44 -74 75 -75 68z"/>
<path d="M5810 3786 c0 -12 127 -135 133 -129 4 4 -21 35 -55 70 -55 55 -78
73 -78 59z"/>
<path d="M5791 3733 c6 -17 99 -113 109 -113 19 0 9 16 -32 56 -56 52 -82 72
-77 57z"/>
<path d="M4329 4063 c-1 -63 -6 -91 -25 -131 -17 -37 -13 -52 10 -33 21 18 66
114 66 142 0 26 -25 69 -41 69 -5 0 -9 -21 -10 -47z"/>
<path d="M5051 3306 c-22 -23 -29 -36 -21 -41 11 -7 29 10 51 48 18 32 4 28
-30 -7z"/>
<path d="M6397 8614 c-4 -4 -7 -22 -7 -41 0 -52 -43 -92 -108 -100 -29 -3 -55
-10 -58 -15 -11 -17 15 -32 55 -34 21 -1 48 -7 61 -14 25 -13 50 -66 50 -105
0 -41 33 -28 40 15 13 73 42 100 108 100 39 0 69 20 58 39 -3 5 -28 12 -55 16
-60 8 -89 36 -99 96 -7 41 -27 60 -45 43z"/>
<path d="M3716 8528 c-3 -7 -7 -30 -11 -51 -5 -34 -12 -41 -51 -58 -25 -10
-57 -19 -71 -19 -43 0 -19 -25 28 -28 24 -2 50 -8 57 -14 22 -19 46 -72 44
-99 -2 -31 18 -52 37 -39 9 5 12 18 8 36 -6 32 13 87 34 100 8 5 32 11 54 14
56 7 54 33 -3 41 -54 7 -74 27 -81 81 -4 30 -11 44 -24 46 -9 2 -19 -2 -21
-10z"/>
<path d="M7180 8409 c-185 -36 -419 -162 -544 -293 -99 -104 -196 -270 -226
-386 -6 -25 -14 -52 -17 -60 -18 -45 -36 -255 -30 -340 11 -146 94 -350 198
-490 121 -164 312 -301 503 -359 91 -28 118 -32 266 -38 166 -7 314 17 427 68
24 11 54 24 68 29 90 34 300 214 364 310 114 175 147 264 167 458 13 128 13
155 -1 247 -36 243 -132 427 -310 593 -154 144 -298 217 -513 261 -63 13 -287
13 -352 0z m270 -68 c30 -5 71 -12 90 -16 61 -10 162 -46 241 -86 259 -129
464 -410 486 -668 3 -36 8 -49 14 -40 5 9 9 -31 9 -103 0 -64 -4 -119 -9 -122
-4 -3 -10 55 -13 129 -2 73 -9 140 -15 147 -6 7 -18 47 -28 88 -10 41 -21 77
-24 80 -3 3 -14 23 -24 45 -56 120 -186 266 -314 353 -220 149 -483 195 -728
127 -81 -22 -229 -95 -295 -144 -235 -176 -364 -427 -365 -706 0 -102 19 -233
35 -245 4 -3 11 -24 17 -46 12 -51 91 -186 153 -262 118 -146 346 -279 513
-299 65 -8 60 -25 -5 -19 -164 15 -358 108 -486 234 -114 112 -241 337 -261
462 -22 131 -12 303 24 455 10 42 84 189 129 255 42 62 166 183 229 223 136
87 272 141 387 154 25 2 47 7 49 9 8 7 135 4 191 -5z m44 -110 c3 -4 24 -11
47 -15 65 -10 219 -76 284 -122 63 -44 109 -84 103 -89 -2 -2 -28 5 -57 15
-51 17 -87 17 -76 0 4 -7 47 -15 130 -25 19 -2 41 -16 63 -41 35 -41 41 -59
15 -45 -19 10 -162 51 -177 51 -5 0 -1 -9 9 -20 10 -11 28 -20 41 -20 13 0 25
-4 28 -9 3 -4 33 -11 66 -15 72 -8 87 -21 131 -108 54 -107 70 -171 76 -315
l6 -133 -24 0 c-13 1 -35 7 -49 15 -26 15 -140 21 -140 7 0 -14 67 -33 139
-39 l69 -6 0 -35 c0 -31 -5 -49 -33 -117 -5 -11 -9 -28 -10 -37 -2 -17 -60
-133 -76 -154 -7 -8 -32 0 -88 27 -44 21 -91 41 -105 45 -39 10 -32 -14 9 -32
19 -8 35 -19 35 -24 0 -6 11 -10 24 -10 14 0 28 -4 31 -10 3 -5 16 -10 28 -10
12 0 29 -5 37 -10 12 -8 6 -18 -35 -59 -27 -28 -56 -51 -64 -51 -7 0 -40 23
-73 50 -84 70 -94 62 -18 -15 35 -35 62 -66 59 -68 -2 -2 -24 -19 -49 -39
-122 -95 -310 -151 -499 -147 -96 2 -267 41 -347 80 -126 60 -279 202 -353
326 -22 37 -54 110 -71 161 -28 87 -30 105 -30 235 0 126 7 187 27 237 3 8 13
35 22 60 61 170 226 349 394 429 76 36 177 71 237 81 63 11 258 12 264 1z
m773 -998 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-42 -162
c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14 17 26 13 15 -4z m-70 -117 c-23 -36
-30 -23 -9 18 10 18 19 27 22 20 2 -7 -4 -24 -13 -38z m-153 -159 c0 -5 -5
-11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-582 -245
c0 -5 -16 -10 -35 -10 -19 0 -35 5 -35 10 0 6 16 10 35 10 19 0 35 -4 35 -10z"/>
<path d="M7340 8163 c-7 -140 -6 -148 20 -148 24 0 25 2 25 75 0 70 -1 75 -22
78 -13 2 -23 0 -23 -5z"/>
<path d="M6972 8062 l-22 -17 35 -57 c36 -57 48 -62 83 -35 8 7 9 13 2 22 -6
7 -21 32 -33 56 -27 50 -35 54 -65 31z"/>
<path d="M7691 8063 c-20 -14 -61 -82 -61 -101 0 -5 15 -12 32 -16 34 -7 41
-2 84 61 31 44 -12 87 -55 56z"/>
<path d="M7545 8050 c-5 -9 53 -25 61 -17 3 3 -9 9 -25 15 -17 6 -33 7 -36 2z"/>
<path d="M7760 7881 c0 -9 206 -81 233 -81 14 0 6 20 -12 29 -25 14 -221 60
-221 52z"/>
<path d="M7338 7863 c-9 -11 -14 -59 -16 -156 l-4 -139 -108 111 c-60 61 -114
111 -120 111 -18 0 -50 -20 -50 -31 0 -10 54 -94 75 -115 5 -6 20 -24 32 -40
12 -16 37 -50 57 -77 29 -37 36 -56 36 -91 0 -78 65 -129 135 -106 37 12 37
12 94 -42 62 -61 87 -74 107 -57 23 19 17 34 -41 104 -31 38 -55 78 -55 90 0
26 54 98 85 113 34 17 173 141 179 160 3 10 19 26 36 35 16 10 35 27 40 38 20
36 0 40 -45 9 -24 -16 -52 -30 -63 -30 -10 0 -74 -47 -141 -104 -103 -88 -126
-103 -144 -97 -21 8 -22 16 -25 135 -1 73 -7 126 -12 126 -6 0 -10 13 -10 29
0 34 -23 47 -42 24z m56 -379 c21 -20 20 -43 -1 -66 -23 -25 -50 -23 -69 7
-15 23 -15 27 0 50 19 29 47 32 70 9z"/>
<path d="M6712 7798 c-22 -22 -12 -43 32 -70 49 -32 71 -35 80 -12 10 26 7 31
-36 63 -46 34 -58 37 -76 19z"/>
<path d="M7960 7770 c0 -5 -6 -10 -13 -10 -7 0 -22 -6 -32 -14 -26 -19 -7 -42
43 -50 59 -11 71 -16 82 -36 9 -17 6 -18 -34 -17 -41 2 -66 -6 -54 -18 2 -3
46 -8 96 -11 67 -5 92 -4 92 5 0 6 -15 11 -35 11 -24 0 -37 6 -45 19 -14 27
-13 31 10 31 40 0 19 19 -27 25 -27 4 -61 11 -78 16 -26 8 -24 8 15 5 49 -3
74 12 64 38 -7 18 -84 23 -84 6z"/>
<path d="M7940 7572 c0 -6 16 -14 35 -18 19 -3 38 -10 41 -15 7 -11 114 -12
114 -1 0 5 -36 14 -81 21 -44 7 -87 15 -95 18 -8 3 -14 0 -14 -5z"/>
<path d="M7940 7455 c0 -8 7 -15 15 -15 8 0 19 -9 25 -20 20 -38 140 -38 140
0 0 28 -20 37 -102 44 -67 6 -78 5 -78 -9z"/>
<path d="M6627 7444 c-4 -4 -7 -15 -7 -25 0 -13 11 -19 46 -25 54 -8 97 8 92
34 -3 13 -16 18 -64 20 -33 2 -63 0 -67 -4z"/>
<path d="M7935 7296 c11 -8 28 -16 37 -16 9 0 19 -4 22 -9 3 -4 31 -11 63 -14
32 -4 68 -10 81 -13 13 -4 22 -2 22 5 0 6 -12 14 -27 17 -16 4 -32 10 -38 14
-5 4 -32 11 -60 14 -27 4 -66 10 -85 13 -33 5 -34 4 -15 -11z"/>
<path d="M7900 7235 c14 -8 33 -14 43 -15 9 0 17 -4 17 -9 0 -4 27 -14 60 -21
33 -7 63 -17 66 -21 8 -14 34 -10 34 5 0 15 -7 18 -135 52 -102 27 -120 29
-85 9z"/>
<path d="M7890 7131 c-8 -16 -4 -24 28 -48 72 -54 79 -56 95 -23 14 27 14 30
-9 45 -13 8 -29 15 -37 15 -7 0 -22 7 -33 15 -25 19 -31 19 -44 -4z"/>
<path d="M6748 7120 c-44 -24 -51 -34 -43 -60 7 -23 47 -27 67 -7 7 8 24 19
36 26 16 8 22 20 20 34 -4 29 -34 32 -80 7z"/>
<path d="M7800 6988 c1 -28 157 -115 174 -98 12 12 18 7 -86 63 -52 28 -88 42
-88 35z"/>
<path d="M6993 6895 c-7 -7 -15 -25 -19 -39 -3 -14 -10 -26 -15 -26 -14 0 -10
-26 6 -40 8 -6 22 -9 32 -6 22 7 69 99 59 115 -10 15 -48 13 -63 -4z"/>
<path d="M7654 6897 c-5 -13 40 -102 57 -113 14 -8 46 5 53 21 3 8 -8 35 -25
60 -22 33 -37 45 -55 45 -14 0 -27 -6 -30 -13z"/>
<path d="M7338 6813 c-2 -5 -3 -37 -3 -73 0 -63 1 -65 28 -68 l27 -3 0 75 c0
74 -1 76 -24 76 -14 0 -26 -3 -28 -7z"/>
<path d="M2680 8332 c-15 -13 -26 -45 -41 -128 l-21 -111 -67 -33 c-82 -39
-91 -37 -169 34 -66 62 -96 79 -120 72 -20 -7 -200 -188 -226 -228 l-18 -28
43 -52 c23 -29 56 -67 73 -85 l29 -32 -17 -58 c-10 -32 -23 -65 -29 -74 -7 -9
-57 -24 -122 -38 -60 -12 -116 -27 -122 -33 -18 -15 -18 -311 0 -326 6 -5 62
-17 124 -27 61 -10 117 -21 123 -25 6 -4 16 -25 22 -46 23 -92 24 -87 -46
-172 -36 -44 -66 -87 -66 -95 0 -21 231 -257 251 -257 8 0 51 34 95 75 57 54
86 74 106 75 15 0 29 -4 33 -9 3 -5 27 -17 54 -26 l49 -16 21 -111 c12 -63 29
-120 38 -130 14 -16 34 -18 153 -18 150 0 160 4 160 57 0 18 4 33 9 33 5 0 12
30 16 68 4 37 12 74 18 82 7 9 43 27 81 42 l69 27 76 -68 c42 -37 82 -71 88
-75 8 -4 33 7 60 25 69 48 223 204 223 226 0 11 -32 54 -71 97 l-72 77 24 59
c12 32 28 65 34 73 7 10 51 22 121 33 60 9 115 22 122 27 9 7 12 53 12 166 0
180 3 174 -98 186 -33 4 -83 12 -109 19 -46 12 -48 14 -75 80 l-28 68 75 87
c41 47 75 90 75 96 1 17 -254 255 -272 255 -10 0 -49 -29 -87 -65 -37 -36 -75
-65 -82 -65 -18 1 -139 43 -160 55 -11 7 -23 47 -38 124 -12 63 -24 117 -27
120 -3 3 -66 7 -140 8 -112 1 -137 -1 -154 -15z m114 -79 c3 -16 11 -66 17
-113 6 -47 16 -92 20 -100 6 -10 8 -3 5 20 -3 19 -8 67 -11 105 -4 39 -9 80
-12 93 -8 32 19 28 35 -5 6 -15 12 -37 12 -50 0 -12 5 -56 11 -98 16 -106 33
-83 19 26 -14 114 -13 149 4 149 9 0 15 -18 19 -57 11 -114 26 -163 55 -175
15 -5 35 -14 44 -19 10 -5 24 -9 31 -9 8 0 44 -14 81 -30 89 -41 95 -39 199
59 l37 35 90 -89 89 -89 -42 -50 c-24 -28 -53 -65 -65 -82 l-21 -31 21 -44
c12 -24 33 -77 47 -117 l26 -74 64 -4 c35 -3 69 -8 75 -11 11 -8 16 -9 49 -11
16 -2 17 -12 15 -99 -3 -96 -4 -98 -28 -102 -14 -1 -28 -8 -32 -14 -12 -20
-16 -4 -21 88 -3 56 -10 91 -17 93 -9 3 -10 -19 -5 -92 l7 -96 -31 0 c-17 0
-31 -4 -31 -10 0 -5 -11 -10 -25 -10 -15 0 -25 -6 -25 -15 0 -18 -68 -186 -81
-200 -17 -17 -9 -32 56 -101 63 -68 74 -84 56 -84 -5 0 -42 -31 -82 -70 -40
-38 -79 -70 -88 -70 -9 0 -27 10 -41 23 -46 41 -114 87 -130 87 -8 0 -24 -6
-35 -13 -27 -18 -135 -58 -164 -60 -34 -2 -48 -32 -56 -117 -13 -143 -45 -146
-45 -5 0 130 -19 133 -22 3 -3 -118 -21 -141 -26 -33 -2 39 -7 74 -13 80 -14
14 -11 121 4 134 8 6 16 8 20 5 13 -13 175 31 252 68 87 42 175 118 224 193
14 22 28 42 31 45 4 3 11 19 18 35 40 103 36 89 48 196 7 72 -10 232 -26 237
-6 2 -10 12 -10 21 0 10 -3 21 -7 25 -5 4 -8 11 -8 15 0 4 -18 35 -39 70 -48
76 -142 163 -217 201 -60 31 -169 60 -223 60 -17 0 -37 5 -44 12 -9 9 -12 9
-12 0 0 -17 -26 -15 -43 3 -13 13 -16 13 -21 0 -3 -8 -12 -15 -20 -15 -8 0
-16 -5 -18 -12 -3 -7 -12 -9 -22 -5 -16 6 -17 17 -10 114 8 123 20 212 24 173
1 -14 4 -34 6 -45 2 -11 8 -45 13 -75 6 -30 15 -68 20 -85 11 -31 9 34 -6 183
-5 52 -3 62 10 62 9 0 18 -12 21 -27z m-504 -181 c0 -5 -11 -19 -25 -32 -29
-27 -32 -21 -9 15 16 24 34 33 34 17z m-22 -73 c-23 -28 -55 -65 -70 -82 -14
-18 -29 -29 -32 -26 -3 3 21 37 54 75 33 38 60 72 60 77 0 4 7 7 16 7 11 0 2
-16 -28 -51z m412 14 c0 -50 -10 -71 -31 -66 -10 3 -19 13 -19 23 0 10 -2 24
-5 32 -3 7 5 18 17 24 35 17 38 16 38 -13z m-350 0 c0 -13 -129 -149 -130
-136 0 6 26 41 57 77 52 61 73 77 73 59z m-110 -18 c-7 -9 -15 -13 -17 -11 -7
7 7 26 19 26 6 0 6 -6 -2 -15z m156 -21 c5 -28 -146 -198 -161 -182 -2 2 32
41 75 88 43 47 77 87 74 89 -2 3 -35 -25 -72 -62 -37 -37 -71 -67 -76 -67 -4
0 24 36 64 80 40 45 77 79 83 77 6 -2 12 -12 13 -23z m242 19 c-33 -69 -43
-82 -59 -83 -14 0 -17 41 -3 47 8 3 12 9 9 13 -3 5 3 13 12 19 21 12 46 14 41
4z m-442 -48 c-9 -14 -21 -25 -27 -25 -5 0 0 13 12 30 27 35 39 32 15 -5z
m359 15 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6 13 10 21 10 8 0 12 -4 9
-10z m-115 -7 c0 -21 -156 -190 -166 -181 -2 3 25 37 61 76 35 39 69 81 75 92
10 19 30 28 30 13z m12 -59 c-58 -52 -165 -175 -190 -219 -9 -16 -11 -17 -12
-3 -1 44 209 278 250 278 8 0 -13 -25 -48 -56z m557 37 c75 -21 109 -36 173
-80 208 -141 298 -436 202 -658 -8 -17 -14 -33 -14 -37 0 -25 -127 -174 -186
-216 -77 -56 -199 -100 -301 -107 -78 -6 -195 9 -218 27 -23 18 -72 21 -85 5
-7 -8 -16 -15 -21 -15 -14 0 -11 28 4 33 9 4 9 7 0 18 -11 11 -19 7 -48 -21
-19 -19 -35 -39 -35 -45 0 -6 -13 -19 -30 -28 -16 -10 -30 -22 -30 -26 0 -5
-12 -17 -27 -26 l-26 -18 20 29 c12 16 48 58 82 94 42 44 57 67 48 72 -16 10
-27 10 -27 -1 0 -15 -192 -211 -206 -211 -22 0 -16 12 31 64 25 26 66 73 91
102 26 30 53 54 60 54 21 0 17 18 -7 31 -16 8 -23 8 -29 0 -13 -22 -183 -191
-192 -191 -14 0 -9 12 25 50 106 121 142 163 145 170 4 11 -38 80 -49 80 -5 0
-9 9 -9 19 0 11 -4 23 -10 26 -5 3 -10 17 -10 30 0 12 -7 26 -16 29 -11 4 -14
13 -11 28 4 12 2 27 -3 33 -13 16 -14 181 -1 235 20 87 26 106 61 170 73 135
237 258 368 276 29 3 52 10 52 15 0 17 158 10 229 -10z m-455 -24 c-3 -7 -32
-32 -66 -56 -71 -52 -148 -145 -183 -221 -14 -30 -30 -63 -35 -72 -11 -20 -14
-272 -4 -345 4 -35 3 -53 -4 -53 -6 0 -14 -9 -17 -20 -4 -11 -11 -18 -16 -15
-5 3 -1 17 8 31 12 19 13 28 4 37 -15 15 -15 316 0 354 33 80 45 103 81 158
49 73 85 112 161 176 54 45 84 56 71 26z m-362 -69 c-7 -7 -12 -8 -12 -2 0 14
12 26 19 19 2 -3 -1 -11 -7 -17z m42 -223 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4
15 0 8 4 15 10 15 5 0 7 -7 4 -15z m-24 -30 c0 -8 -4 -15 -10 -15 -5 0 -7 7
-4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-80 -200 c0 -104 -2 -125 -15 -125 -12
0 -15 20 -15 118 0 65 3 122 7 125 18 19 23 -4 23 -118z m60 -65 c15 0 12 -56
-3 -69 -8 -6 -17 -8 -20 -4 -10 9 -9 248 1 257 4 4 8 -35 10 -88 1 -53 7 -96
12 -96z m-122 70 c2 -61 -1 -107 -7 -113 -6 -6 -12 -9 -15 -6 -6 6 -14 202 -9
217 3 7 10 11 17 9 7 -2 12 -40 14 -107z m-108 -5 c0 -78 -3 -95 -15 -95 -17
0 -20 59 -9 144 11 78 24 51 24 -49z m50 -5 c0 -60 -4 -100 -10 -100 -6 0 -10
40 -10 100 0 60 4 100 10 100 6 0 10 -40 10 -100z m207 -212 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m69 -37 c-18 -19 -22 -51 -6 -51 5 0 7
-7 4 -15 -9 -22 -24 -18 -24 5 0 11 -5 20 -10 20 -6 0 2 19 17 41 15 23 29 36
31 30 2 -7 -3 -20 -12 -30z m34 -33 c0 -6 8 -16 17 -23 15 -11 11 -19 -45 -76
-70 -73 -85 -60 -17 16 45 49 54 65 35 65 -5 0 -10 9 -10 21 0 11 5 17 10 14
6 -3 10 -11 10 -17z m50 -90 c-1 -16 -167 -194 -175 -187 -6 7 24 48 99 132
59 66 76 79 76 55z m-159 -85 c-6 -10 -20 -29 -31 -43 l-21 -25 17 35 c14 30
31 49 42 50 1 0 -2 -8 -7 -17z m334 -103 c-6 -20 -9 -21 -23 -7 -13 13 -13 19
-2 32 17 21 34 4 25 -25z m106 17 c-1 -4 -1 -20 -1 -37 0 -20 -5 -30 -15 -30
-16 0 -20 35 -9 65 6 15 26 17 25 2z m-51 -21 c0 -24 -16 -43 -27 -32 -8 8 6
56 17 56 6 0 10 -11 10 -24z m100 -32 c0 -40 -2 -45 -15 -34 -18 15 -18 20 -5
54 14 38 20 32 20 -20z m63 -76 c4 -62 10 -130 13 -150 5 -30 3 -38 -8 -38
-11 0 -18 22 -26 83 -13 90 -16 200 -5 210 16 17 20 0 26 -105z m58 6 c4 -38
10 -99 14 -136 7 -58 5 -68 -7 -68 -10 0 -19 16 -26 43 -14 57 -26 213 -17
227 16 26 30 -1 36 -66z"/>
<path d="M3650 7386 c0 -51 4 -86 10 -86 6 0 10 33 10 79 0 44 -4 83 -10 86
-6 4 -10 -25 -10 -79z"/>
<path d="M3387 6880 c33 -44 53 -61 53 -44 0 9 -74 94 -82 94 -5 0 8 -22 29
-50z"/>
<path d="M3300 6895 c1 -17 88 -107 99 -104 6 2 -13 29 -44 59 -30 30 -55 50
-55 45z"/>
<path d="M2695 7871 c-142 -40 -263 -141 -330 -276 -38 -77 -40 -84 -43 -197
-4 -117 12 -209 42 -242 6 -6 20 -28 30 -49 27 -52 124 -140 189 -170 29 -14
92 -34 139 -43 114 -24 216 -11 326 41 83 40 186 130 226 199 81 140 87 339
13 481 -38 72 -143 180 -213 217 -102 55 -263 72 -379 39z m286 -82 c35 -12
73 -26 83 -31 43 -23 120 -106 159 -173 l42 -70 0 -120 c0 -116 -1 -122 -34
-190 -64 -132 -185 -224 -322 -246 -64 -11 -199 -4 -228 11 -9 5 -32 14 -51
21 -50 19 -162 134 -193 199 -67 138 -54 309 31 432 52 73 142 143 211 164 31
9 61 18 66 19 39 12 179 3 236 -16z"/>
<path d="M1736 6575 c-3 -8 -12 -15 -20 -15 -26 0 -24 -25 6 -56 l29 -31 35
35 35 36 -28 23 c-33 26 -49 29 -57 8z"/>
<path d="M8630 6572 c0 -26 -27 -60 -52 -64 -32 -5 -33 -26 -1 -34 40 -10 63
-33 63 -65 0 -56 39 -42 49 17 5 33 11 40 36 44 42 7 47 40 6 40 -23 0 -34 7
-44 28 -8 15 -12 33 -10 40 3 7 -5 12 -21 12 -18 0 -26 -5 -26 -18z"/>
<path d="M8305 4493 c-58 -69 -248 -309 -252 -321 -7 -16 -80 -65 -153 -102
-86 -43 -120 -76 -120 -113 0 -43 12 -59 44 -60 26 -2 26 -2 26 -85 0 -107 9
-116 152 -152 46 -12 130 -36 188 -54 130 -41 237 -66 255 -59 17 6 184 212
240 296 14 20 31 37 38 38 6 0 29 26 50 57 21 31 52 76 69 100 30 43 30 44 29
170 -2 117 -4 129 -24 149 -14 14 -81 40 -187 73 -91 28 -192 60 -226 71 -81
25 -101 24 -129 -8z m155 -74 c58 -17 121 -35 140 -40 66 -18 145 -42 149 -45
7 -6 -36 -42 -79 -66 -38 -21 -201 -88 -213 -88 -3 0 -1 20 4 45 17 91 -32
129 -125 99 -61 -20 -84 -38 -102 -81 -8 -18 -28 -40 -45 -48 -63 -33 -55 -20
109 188 29 37 54 67 55 67 1 0 49 -14 107 -31z m-50 -175 c0 -24 -50 -72 -100
-95 -37 -17 -40 -18 -160 -68 -182 -74 -276 -113 -287 -117 -7 -4 -10 1 -6 14
4 17 121 86 148 87 6 0 35 12 65 26 30 15 66 30 80 34 40 12 104 46 120 64 9
9 26 33 39 54 12 20 26 35 30 33 4 -3 13 -1 21 4 19 12 50 -10 50 -36z m372
-6 c-8 -32 -54 -70 -140 -118 -45 -25 -82 -50 -82 -57 0 -23 19 -24 61 -3 24
11 44 19 45 17 6 -6 -193 -261 -235 -302 l-31 -29 -98 31 c-53 17 -159 50
-234 74 -133 41 -165 61 -120 75 37 12 207 87 216 95 13 12 36 12 36 0 0 -5
16 -37 36 -71 35 -58 71 -88 104 -85 40 3 90 19 101 31 6 8 17 14 24 14 17 0
63 55 71 84 4 16 -5 38 -26 70 -18 26 -30 53 -27 60 3 8 31 22 62 31 61 17
152 63 200 101 31 24 47 17 37 -18z m38 -98 c-16 -31 -23 -18 -8 15 7 15 14
23 16 17 2 -6 -2 -21 -8 -32z m-395 -62 c9 -18 23 -44 30 -58 13 -23 13 -27
-8 -43 -12 -10 -31 -22 -42 -26 -53 -22 -63 -22 -85 1 -20 22 -50 80 -50 99 0
14 89 55 126 58 7 1 19 -14 29 -31z m339 7 c-4 -8 -11 -15 -16 -15 -6 0 -5 6
2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m-414 -415 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M1677 4426 c-52 -30 -144 -105 -241 -197 -45 -41 -100 -93 -124 -114
-51 -46 -84 -105 -79 -143 6 -53 79 -165 185 -285 l33 -38 -80 -85 c-45 -47
-81 -94 -81 -104 0 -15 80 -124 110 -150 3 -3 27 -31 54 -62 58 -69 80 -73
146 -22 25 18 63 46 85 61 60 40 283 227 405 339 25 23 81 72 125 110 141 121
235 223 235 254 0 19 -145 224 -192 270 -42 43 -72 35 -185 -45 -16 -11 -12
-15 -128 128 -43 53 -81 91 -99 97 -49 17 -127 11 -169 -14z m128 -79 c52 -29
180 -190 170 -214 -6 -16 -8 -16 -25 5 -24 29 -36 29 -16 0 9 -12 16 -28 16
-35 0 -23 -17 -13 -30 17 -7 17 -16 30 -21 30 -12 0 -12 -2 7 -39 15 -31 15
-33 -3 -51 -11 -10 -30 -30 -41 -45 l-22 -27 -20 23 c-10 13 -19 30 -20 37 0
6 -3 12 -7 12 -5 0 -22 13 -40 30 -21 20 -33 25 -36 17 -4 -7 -6 -6 -6 4 -1
18 -66 39 -118 39 -39 0 -108 -46 -165 -109 -50 -55 -59 -88 -33 -115 8 -8 15
-20 15 -26 0 -6 4 -9 8 -6 4 2 14 -10 21 -27 8 -18 27 -48 43 -68 25 -33 36
-59 24 -59 -2 0 -11 -3 -19 -6 -11 -4 -38 23 -87 87 -95 123 -105 143 -90 179
22 54 304 309 386 350 50 25 61 24 109 -3z m435 -227 c30 -38 55 -75 55 -81 0
-26 -21 -17 -43 19 -35 56 -70 97 -78 90 -3 -4 15 -32 40 -63 25 -31 46 -63
46 -70 0 -7 -35 -41 -77 -75 -43 -34 -96 -76 -117 -94 -123 -98 -304 -274
-441 -430 -108 -122 -110 -124 -129 -106 -19 19 -19 19 1 37 12 10 29 31 39
47 27 43 -4 29 -34 -15 -13 -19 -26 -35 -30 -36 -6 -1 -61 63 -97 114 -11 15
72 99 280 288 173 156 198 179 224 206 14 14 78 70 141 124 63 55 117 103 118
107 2 5 14 8 26 8 15 0 38 -21 76 -70z m-706 -75 c-27 -24 -58 -59 -68 -79
-18 -34 -18 -37 -3 -54 10 -10 17 -23 17 -29 0 -6 3 -13 8 -15 9 -4 62 -74 62
-82 0 -3 -7 -6 -15 -6 -14 0 -19 12 -16 33 0 4 -6 7 -14 7 -8 0 -15 4 -15 9 0
5 -18 32 -40 60 -46 57 -46 61 -22 82 9 8 36 36 59 61 51 56 87 82 92 67 2 -6
-19 -30 -45 -54z m178 -14 c14 -5 32 -27 43 -51 l19 -42 -54 -49 c-30 -27 -59
-48 -65 -49 -12 0 -105 104 -105 118 0 14 32 40 76 62 45 22 54 23 86 11z
m658 -79 c0 -16 -190 -195 -379 -357 -52 -44 -105 -90 -118 -102 -13 -13 -26
-23 -29 -23 -2 0 -18 -15 -35 -32 -17 -18 -28 -27 -24 -20 3 6 1 12 -4 12 -6
0 5 24 26 53 20 28 29 46 20 38 -9 -7 -17 -9 -17 -5 0 18 228 234 329 313 104
80 128 92 97 49 -44 -62 -44 -69 -1 -23 70 73 135 120 135 97z m-785 -202 c0
-22 -29 -18 -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m-56 -56 c-14 -14
-17 -14 -29 0 -10 13 -10 19 0 32 12 14 15 14 29 0 14 -15 14 -17 0 -32z m246
-227 c-8 -13 -18 -35 -20 -47 -5 -24 -45 -73 -53 -65 -2 2 5 16 17 31 36 46
11 41 -30 -6 -21 -24 -41 -41 -44 -38 -3 3 27 40 67 81 74 76 93 89 63 44z"/>
<path d="M1770 4315 c0 -7 52 -85 56 -85 2 0 -6 20 -18 45 -19 38 -38 58 -38
40z"/>
<path d="M1840 4268 c11 -18 31 -46 46 -63 l26 -30 -17 29 c-10 16 -26 44 -37
63 -11 18 -24 33 -29 33 -4 0 0 -15 11 -32z"/>
<path d="M1850 4141 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M1867 4104 c-6 -10 9 -42 16 -34 3 3 1 14 -3 24 -3 11 -9 15 -13 10z"/>
<path d="M2150 4110 c0 -13 62 -100 72 -100 13 0 10 8 -28 60 -36 51 -44 58
-44 40z"/>
<path d="M2637 3796 c-83 -28 -107 -50 -114 -105 l-6 -41 -58 0 c-55 0 -59 2
-59 23 0 13 -9 32 -20 42 -16 14 -26 16 -52 9 -45 -13 -65 -55 -49 -101 12
-32 11 -34 -20 -61 -91 -75 -128 -134 -136 -217 -15 -141 37 -156 195 -53 34
22 65 39 67 36 3 -3 -35 -47 -84 -99 -208 -218 -285 -449 -241 -729 10 -64 39
-160 51 -170 3 -3 9 -17 13 -32 12 -51 109 -182 204 -275 l93 -93 -27 -80
c-25 -77 -26 -82 -9 -100 25 -27 69 -34 97 -16 13 8 37 42 53 75 22 43 34 59
45 54 132 -52 351 -54 542 -5 41 11 45 9 71 -48 20 -45 61 -83 90 -86 33 -2
76 23 82 49 4 15 -5 48 -25 91 -31 67 -33 96 -7 96 20 0 176 158 214 217 90
141 140 338 129 513 -14 237 -92 406 -260 562 -60 57 -62 80 -2 35 49 -37 154
-87 170 -81 29 11 46 53 46 109 0 78 -34 149 -100 208 l-52 47 11 47 c9 46 8
49 -17 70 -41 33 -68 29 -97 -15 l-25 -38 -60 10 c-56 9 -60 12 -60 37 0 35
-40 83 -82 99 -18 6 -42 17 -53 23 -13 8 -87 13 -205 14 -170 2 -190 0 -253
-21z m397 -56 c39 -6 81 -19 97 -31 36 -25 38 -62 6 -84 -26 -17 -47 -55 -47
-84 0 -20 33 -51 131 -120 34 -25 58 -46 52 -48 -5 -2 -44 10 -85 27 -178 71
-384 80 -563 26 -27 -8 -60 -18 -72 -22 -42 -12 -24 14 42 61 36 26 65 52 66
58 0 7 2 21 4 32 3 13 -11 33 -41 61 -24 23 -44 45 -44 50 0 5 11 22 24 39 20
25 33 31 82 36 95 10 274 9 348 -1z m-465 -184 c-9 -9 -163 -86 -172 -86 -12
0 80 69 106 80 22 9 74 13 66 6z m864 -36 c64 -40 87 -81 87 -153 0 -40 3 -56
11 -51 7 4 9 23 5 52 -6 42 -5 44 9 27 20 -27 19 -81 -1 -89 -16 -6 -60 16
-120 61 -17 13 -34 23 -38 23 -11 0 -79 53 -95 74 -7 10 -30 25 -50 33 -21 9
-30 18 -23 21 8 2 24 0 35 -6 45 -21 60 -22 78 -6 25 23 24 33 -3 26 -14 -3
-19 -2 -14 6 13 22 71 13 119 -18z m-883 -10 c-8 -5 -46 -29 -85 -54 -38 -24
-71 -43 -73 -41 -6 6 51 54 65 55 6 0 13 4 15 9 4 12 81 52 87 45 3 -3 -1 -9
-9 -14z m535 -131 c39 -12 80 -23 93 -26 12 -3 22 -9 22 -14 0 -5 6 -9 14 -9
21 0 164 -109 211 -161 91 -99 148 -209 179 -341 15 -62 17 -105 13 -215 -5
-156 -8 -170 -43 -259 -81 -202 -254 -358 -476 -430 -99 -32 -259 -43 -361
-25 -68 12 -214 78 -238 108 -16 19 -23 21 86 -25 98 -42 150 -52 264 -52 197
0 355 69 524 229 96 92 128 152 188 358 15 53 4 301 -14 319 -4 4 -7 19 -7 33
-1 71 -114 260 -195 326 -57 47 -178 125 -192 125 -7 0 -13 4 -13 8 0 9 -123
42 -154 42 -9 0 -16 5 -16 10 0 6 -24 10 -54 10 -31 0 -58 5 -62 12 -11 18
148 2 231 -23z m-20 -69 c6 -5 27 -11 48 -15 20 -4 70 -30 110 -57 78 -54 227
-201 227 -224 0 -8 4 -14 9 -14 5 0 12 -7 15 -16 13 -34 -3 -23 -39 26 -49 66
-162 180 -179 180 -8 0 -16 6 -19 13 -11 28 -148 83 -244 99 -35 6 -63 15 -63
20 0 11 119 1 135 -12z m-259 -6 c1 -5 5 -30 9 -56 6 -45 8 -48 36 -48 24 0
33 6 43 32 8 18 12 38 9 45 -6 15 23 18 31 4 4 -5 38 -15 76 -21 88 -15 108
-36 24 -25 -32 4 -60 11 -62 16 -4 11 -52 12 -52 1 0 -9 66 -27 135 -38 74
-11 74 -11 62 -44 -6 -17 -15 -30 -19 -30 -14 0 -8 -38 8 -54 20 -20 44 -21
44 -1 0 8 4 15 9 15 5 0 17 7 26 16 13 13 24 15 58 8 55 -12 50 -28 -10 -32
-61 -4 -82 -22 -26 -22 22 0 61 -3 87 -6 60 -9 43 -21 -41 -30 -95 -10 -78
-27 20 -19 45 4 88 8 95 10 6 3 12 0 12 -5 0 -6 -6 -11 -12 -12 -14 -1 -13 -1
-80 -12 -59 -9 -63 -26 -7 -26 l40 0 -27 -23 c-20 -18 -25 -29 -20 -45 9 -26
17 -27 82 -7 56 17 74 19 74 7 0 -5 -25 -14 -55 -21 -30 -7 -55 -16 -55 -20 0
-5 32 -6 70 -3 44 3 70 1 70 -5 0 -9 -68 -24 -112 -24 -27 0 -32 -19 -6 -20
66 -2 131 -8 135 -13 13 -13 -12 -26 -50 -26 -50 0 -87 -9 -87 -21 0 -5 21 -6
47 -3 54 6 113 -2 113 -16 0 -6 -15 -10 -34 -10 -18 0 -54 -3 -79 -6 -67 -9
-47 -28 22 -21 31 3 65 2 76 -3 15 -7 9 -9 -26 -9 -33 -1 -51 -7 -64 -21 -10
-11 -24 -20 -32 -20 -7 0 -13 -4 -13 -10 0 -5 9 -10 20 -10 25 0 27 -19 3 -22
-10 -1 18 -4 61 -7 72 -5 78 -8 64 -21 -10 -11 -38 -17 -84 -18 -86 -3 -97
-11 -18 -14 32 0 64 -2 69 -3 6 -2 16 -3 23 -4 7 0 10 -6 7 -11 -4 -6 -10 -8
-15 -5 -4 3 -10 1 -12 -5 -1 -5 -39 -13 -83 -17 -93 -8 -69 -21 34 -19 33 0
61 -1 61 -4 0 -8 -101 -30 -137 -30 -18 0 -35 -4 -38 -10 -7 -12 5 -14 113
-18 73 -3 47 -22 -30 -22 -85 0 -102 -8 -104 -50 -1 -24 2 -30 20 -31 11 0 37
-7 56 -14 33 -13 30 -14 -64 -14 -118 -1 -122 -12 -6 -16 45 -2 84 -5 87 -9
13 -12 -53 -26 -135 -28 -98 -2 -112 -5 -112 -23 0 -7 -21 -18 -46 -25 l-46
-13 30 -13 c36 -15 55 -40 23 -29 -11 3 -21 2 -21 -3 0 -5 -19 -12 -42 -15
-24 -3 -60 -9 -80 -13 -31 -5 -38 -4 -38 10 0 9 15 22 33 29 l32 14 -49 0
c-72 2 -91 -9 -117 -66 -14 -33 -29 -51 -40 -51 -10 0 -19 4 -21 9 -4 11 -108
24 -108 13 0 -5 -18 -4 -40 1 -37 10 -53 31 -30 39 12 4 143 -8 188 -17 19 -5
32 -3 32 3 0 9 -124 32 -178 32 -24 0 -25 1 -10 17 15 14 26 15 100 5 46 -7
90 -12 98 -12 48 1 -66 32 -155 43 -12 1 -21 11 -23 25 -3 18 -10 22 -37 22
-18 0 -41 -7 -49 -16 -10 -9 -31 -15 -54 -14 -55 3 -64 1 -57 -10 3 -5 26 -10
51 -10 50 0 62 -23 18 -34 -28 -7 -45 1 -104 53 l-35 30 85 2 c47 1 99 5 115
9 19 5 -13 8 -90 8 l-120 -1 -29 39 c-38 52 -81 146 -101 225 -13 49 -16 88
-11 169 7 122 10 147 19 156 4 4 7 17 7 29 0 12 7 30 15 41 8 10 15 28 15 39
1 11 12 38 25 60 14 22 33 56 44 76 18 32 37 52 127 130 27 23 125 74 194 101
55 22 152 29 156 12z m504 -1103 c0 -25 -130 -30 -150 -6 -11 13 -2 15 69 15
44 0 81 -4 81 -9z m-35 -50 c-7 -12 -85 -12 -85 0 0 5 21 9 46 9 25 0 43 -4
39 -9z m-165 -60 c0 -14 -106 -41 -160 -41 -29 0 -51 3 -48 6 8 8 148 40 181
41 15 1 27 -2 27 -6z m0 -45 c0 -7 -104 -40 -117 -38 -6 1 -14 -1 -16 -6 -6
-9 -87 -3 -87 7 0 3 5 13 11 21 8 11 12 12 16 3 6 -17 56 -16 118 1 54 15 75
18 75 12z m-352 -35 c15 -9 14 -10 -6 -11 -35 0 -112 21 -107 30 6 9 89 -5
113 -19z"/>
<path d="M3035 3191 l-60 -7 58 -4 c40 -3 62 -1 69 8 6 6 7 11 2 10 -5 -1 -36
-4 -69 -7z"/>
<path d="M2530 3175 c-13 -16 -9 -26 34 -72 31 -35 76 -45 76 -18 0 22 -30 77
-49 92 -22 17 -46 16 -61 -2z"/>
<path d="M2830 3160 c-12 -8 -16 -49 -20 -234 -4 -161 -9 -229 -17 -238 -24
-23 -15 -77 16 -109 39 -39 75 -38 112 2 35 37 38 84 9 121 -18 24 -20 44 -22
244 l-3 219 -30 3 c-16 1 -37 -2 -45 -8z m70 -510 c0 -13 -7 -20 -20 -20 -19
0 -27 20 -13 33 13 14 33 6 33 -13z"/>
<path d="M3010 3160 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M2332 2958 c-21 -21 -13 -32 31 -50 100 -42 144 2 50 51 -28 14 -66
14 -81 -1z"/>
<path d="M2260 2720 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2247 2674 c-4 -4 -7 -13 -7 -20 0 -26 27 -39 81 -39 44 0 54 3 57
19 2 10 0 22 -5 26 -11 11 -117 22 -126 14z"/>
<path d="M2263 2563 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2258 2513 c-8 -10 6 -13 68 -11 44 1 62 3 42 5 -21 2 -38 8 -38 12
0 13 -61 7 -72 -6z"/>
<path d="M2270 2454 c0 -13 11 -15 73 -13 39 2 78 0 85 -4 6 -5 12 -4 12 1 0
14 -28 23 -54 16 -12 -3 -34 0 -49 5 -18 7 -27 7 -27 0 0 -6 -7 -6 -20 1 -16
9 -20 8 -20 -6z"/>
<path d="M2293 2403 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2355 2389 c-56 -14 -61 -23 -29 -58 29 -33 50 -37 99 -18 23 9 31
19 32 43 4 43 -22 51 -102 33z"/>
<path d="M2475 2310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2363 2268 c-2 -5 40 -8 93 -8 54 0 94 3 90 8 -4 4 -46 7 -93 7 -47
0 -87 -3 -90 -7z"/>
<path d="M2996 2151 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M6755 3593 c-28 -2 -61 -12 -75 -23 l-25 -19 3 -641 c2 -605 4 -642
21 -661 39 -42 41 -22 43 642 l3 634 40 5 c22 3 294 3 605 0 l565 -5 1 -600
c1 -330 -1 -621 -3 -647 -6 -53 -23 -56 -23 -4 0 23 -7 38 -22 50 -21 14 -22
16 -5 26 26 16 21 30 -12 30 -16 0 -34 7 -41 15 -11 13 -7 15 29 15 29 0 41 4
41 15 0 11 -16 15 -77 15 -43 1 -85 1 -93 2 -40 2 -303 -12 -308 -17 -3 -3 50
-5 117 -5 111 0 122 -2 119 -17 -3 -16 -22 -18 -198 -20 -304 -4 -540 -22
-569 -45 l-24 -18 29 0 c214 -1 525 -15 594 -27 47 -9 104 -18 128 -20 26 -3
41 -10 39 -17 -3 -10 -44 -10 -92 1 -44 10 -708 16 -702 6 4 -6 123 -13 299
-16 326 -8 388 -13 388 -33 0 -8 8 -15 18 -16 9 0 -3 -4 -28 -7 -25 -4 -49 -5
-53 -3 -17 8 -595 1 -623 -8 -22 -6 -10 -9 56 -10 376 -10 698 -26 709 -35 1
-2 0 -8 -4 -14 -9 -16 -232 -15 -237 1 -3 9 -11 9 -35 -1 -17 -7 -48 -11 -69
-10 -22 1 -122 1 -224 -1 -140 -2 -170 -4 -125 -10 54 -7 564 -23 643 -20 68
2 102 57 102 163 0 92 10 167 22 167 23 0 187 -151 188 -173 0 -9 -104 62
-138 93 -26 24 -32 17 -32 -39 -1 -80 -24 -166 -56 -204 -31 -38 -102 -71
-189 -88 -27 -5 -188 -13 -358 -18 -169 -4 -310 -11 -314 -15 -10 -10 -1 -14
41 -18 43 -4 59 -22 26 -31 -40 -10 -20 -27 35 -28 30 -1 60 -3 65 -4 6 -1 13
-3 18 -4 15 -3 -24 -21 -46 -21 -13 0 -20 -4 -17 -10 4 -6 212 -10 591 -10
383 0 584 3 584 10 0 6 15 10 34 10 18 0 39 6 45 14 9 11 11 167 9 610 -3 535
-5 597 -19 612 -13 13 -21 14 -38 5 -20 -11 -21 -19 -21 -190 l0 -179 -27 7
c-36 10 -42 31 -9 31 32 0 35 17 4 22 -13 2 -24 11 -26 21 -3 13 3 17 23 17
26 0 32 24 7 26 -7 1 -16 2 -22 3 -23 3 -9 31 15 31 34 0 32 18 -2 22 -17 2
-28 9 -28 18 0 9 11 16 28 18 15 2 27 7 27 12 0 5 -12 10 -27 12 -34 4 -38 32
-5 36 32 5 27 21 -8 29 -21 4 -30 11 -30 24 0 16 5 18 25 13 34 -8 32 11 -2
28 -37 18 -44 43 -10 34 37 -9 43 10 7 23 -36 12 -39 31 -5 31 35 0 31 13 -7
28 -34 12 -34 20 -2 25 26 4 32 21 8 25 -32 6 -41 22 -16 28 30 8 28 24 -3 24
-20 0 -25 5 -25 25 0 24 3 25 60 25 l60 0 0 -119 c0 -77 4 -122 11 -127 15 -9
29 14 29 47 0 14 5 30 11 36 7 7 10 43 8 98 -5 111 -18 125 -120 125 l-69 0 0
63 c0 110 51 101 -613 103 -315 1 -594 0 -622 -3z m1354 -1085 c0 -117 -3
-207 -6 -200 -2 6 -13 12 -24 12 -12 0 -19 7 -19 20 0 11 7 20 15 20 8 0 15 4
15 9 0 5 -7 11 -15 15 -22 8 -18 36 5 36 11 0 20 5 20 10 0 6 -9 10 -20 10
-13 0 -20 7 -20 20 0 13 7 20 20 20 11 0 20 4 20 9 0 5 -9 11 -20 14 -11 3
-20 10 -20 16 0 6 9 11 20 11 11 0 20 7 20 15 0 8 -9 15 -20 15 -12 0 -20 7
-20 16 0 9 9 18 20 21 11 3 20 9 20 14 0 5 -9 9 -20 9 -11 0 -20 7 -20 15 0 8
9 15 20 15 11 0 20 7 20 15 0 9 -9 15 -25 15 -34 0 -32 28 3 33 15 2 28 5 30
6 1 0 2 -95 1 -211z m-459 -173 c0 -10 -11 -15 -35 -15 -24 0 -35 5 -35 15 0
10 11 15 35 15 24 0 35 -5 35 -15z m460 -61 c0 -10 -7 -14 -22 -12 -32 5 -35
28 -4 28 17 0 26 -5 26 -16z m0 -59 c0 -20 -5 -25 -25 -25 -18 0 -25 5 -25 18
0 24 8 32 32 32 13 0 18 -7 18 -25z m-460 -25 c0 -5 -6 -10 -14 -10 -8 0 -18
5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m460 -35 c0 -16 -35 -21 -45 -6
-4 8 9 15 38 20 4 0 7 -6 7 -14z m4 -60 c6 -18 -16 -30 -39 -21 -8 3 -15 12
-15 21 0 20 46 20 54 0z m-107 -87 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m113 -23 c0 -37 -3 -45 -19 -45 -19 0 -31 25 -31 68 0 17 6 22 25
22 23 0 25 -4 25 -45z m-193 -61 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z"/>
<path d="M7708 3333 c34 -2 90 -2 125 0 34 2 6 3 -63 3 -69 0 -97 -1 -62 -3z"/>
<path d="M6872 3287 c-7 -8 -7 -18 -2 -27 7 -11 44 -16 152 -22 176 -8 492 -8
613 1 81 5 90 8 90 26 0 18 -9 20 -110 26 -60 4 -250 7 -421 8 -243 1 -314 -2
-322 -12z"/>
<path d="M7745 3290 c3 -5 16 -10 28 -10 18 0 19 2 7 10 -20 13 -43 13 -35 0z"/>
<path d="M7815 3280 c-15 -6 -8 -9 28 -9 26 -1 47 4 47 9 0 12 -47 12 -75 0z"/>
<path d="M7778 3213 c23 -2 59 -2 80 0 20 2 1 4 -43 4 -44 0 -61 -2 -37 -4z"/>
<path d="M6403 3162 c-7 -15 -26 -33 -44 -39 -33 -13 -35 -36 -3 -42 29 -5 42
-19 49 -52 8 -37 26 -33 37 9 5 18 15 32 22 32 7 0 19 6 25 14 9 11 6 18 -17
35 -17 11 -33 32 -36 46 -8 32 -18 32 -33 -3z"/>
<path d="M7765 3150 c-57 -6 -56 -7 33 -8 56 -1 92 2 92 8 0 11 -29 11 -125 0z"/>
<path d="M6867 3098 c-9 -6 -9 -12 -1 -25 10 -17 38 -18 395 -18 513 0 629 5
629 26 0 15 -28 17 -252 23 -293 8 -754 4 -771 -6z"/>
<path d="M7781 3026 c7 -8 108 -10 115 -3 4 4 -21 7 -56 7 -35 0 -61 -2 -59
-4z"/>
<path d="M8531 2989 l-24 -32 24 -18 c31 -24 41 -24 62 1 22 26 21 36 -3 60
-27 27 -32 25 -59 -11z"/>
<path d="M5820 2965 c0 -8 13 -41 29 -74 15 -32 31 -72 35 -88 4 -15 27 -69
52 -119 24 -50 44 -96 44 -102 0 -6 10 -27 23 -46 12 -18 41 -65 64 -102 65
-106 111 -178 121 -189 44 -54 54 -73 43 -93 -6 -11 -11 -30 -11 -41 0 -17 29
-77 52 -106 12 -15 94 -145 167 -262 34 -56 76 -117 92 -134 25 -27 34 -30 64
-25 34 7 35 6 82 -66 105 -163 144 -201 201 -193 56 6 92 42 92 91 0 18 -6 39
-13 46 -8 7 -19 30 -26 50 -21 64 -240 434 -417 703 -253 387 -404 566 -580
690 -15 11 -42 32 -60 48 -35 30 -54 35 -54 12z m161 -157 c52 -57 60 -78 30
-78 -11 0 -31 21 -55 59 -55 91 -47 97 25 19z m134 -118 c9 -15 -10 -35 -56
-58 l-39 -19 0 23 c0 40 77 83 95 54z m55 -71 c0 -6 -4 -7 -10 -4 -5 3 -10 11
-10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-7 -127 c32 -38 45 -62 33 -62 -6 0
-106 140 -105 148 0 4 8 -4 17 -18 9 -13 34 -44 55 -68z m144 -76 c26 -39 55
-80 64 -91 41 -48 284 -434 277 -440 -4 -4 -122 165 -175 253 -18 29 -37 58
-43 65 -6 7 -41 60 -78 118 -37 58 -82 123 -100 145 -18 21 -32 43 -32 48 0
18 40 -28 87 -98z m-7 -82 c0 -8 -7 -14 -15 -14 -8 0 -15 9 -15 20 0 13 5 18
15 14 8 -4 15 -12 15 -20z m80 -244 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m36 -172 c64 -89 144 -211 144 -221 0 -5 -10
3 -22 19 -12 16 -25 31 -28 34 -20 17 -150 201 -150 212 0 20 22 3 56 -44z
m283 -140 c12 -15 11 -18 -3 -18 -10 0 -16 8 -16 22 0 12 1 19 3 17 1 -2 9
-12 16 -21z m177 -243 c7 -17 -30 -54 -55 -56 -9 -1 -26 -3 -38 -4 -35 -4 -9
26 52 62 28 16 35 15 41 -2z m20 -86 c3 -6 2 -15 -4 -21 -14 -14 -82 -21 -82
-8 0 5 6 10 14 10 7 0 19 7 26 15 15 18 36 20 46 4z"/>
<path d="M7741 2966 c7 -7 149 -10 156 -3 3 4 -31 7 -77 7 -46 0 -81 -2 -79
-4z"/>
<path d="M4133 2886 c-29 -30 -29 -35 5 -68 l28 -27 27 34 27 34 -25 26 c-32
31 -34 31 -62 1z"/>
<path d="M6869 2894 c-9 -11 -10 -20 -2 -32 9 -15 40 -17 299 -17 174 0 345 6
429 14 77 8 176 17 219 20 49 4 81 11 83 18 4 10 -108 13 -505 13 -454 0 -511
-2 -523 -16z"/>
<path d="M4950 2854 c-131 -25 -187 -37 -195 -44 -6 -5 -32 -16 -58 -25 -101
-34 -245 -137 -346 -248 -72 -80 -156 -233 -180 -332 -7 -27 -17 -61 -21 -75
-16 -47 -29 -294 -17 -307 4 -3 7 -21 7 -38 0 -32 39 -166 62 -215 24 -50 102
-166 147 -219 97 -114 191 -181 384 -273 41 -20 168 -38 300 -44 171 -7 290
13 412 70 42 20 79 36 81 36 13 0 126 84 184 136 118 105 181 199 225 334 10
30 22 64 26 75 31 72 38 295 15 440 -43 264 -215 495 -471 631 -60 31 -96 44
-230 84 -41 12 -280 22 -325 14z m345 -149 c44 -15 98 -36 120 -47 74 -38 215
-144 215 -163 0 -3 10 -16 23 -28 81 -79 134 -186 184 -367 3 -8 7 -57 10
-107 11 -195 -37 -364 -142 -498 -26 -33 -59 -69 -73 -80 -14 -11 -34 -28 -44
-38 -10 -9 -49 -37 -86 -60 -57 -37 -95 -55 -227 -107 -54 -22 -177 -32 -282
-24 -139 11 -127 8 -188 34 -11 5 -31 12 -45 16 -55 16 -168 94 -245 171 -90
89 -142 166 -175 258 -11 33 -25 68 -31 78 -35 63 -35 311 0 429 38 129 96
224 206 333 100 100 122 115 300 196 52 24 165 38 280 35 99 -3 134 -8 200
-31z m492 -281 c54 -67 121 -217 140 -312 5 -20 7 -109 7 -197 -2 -137 -5
-169 -24 -225 -77 -222 -176 -345 -385 -478 -43 -27 -122 -61 -157 -67 -21 -4
-38 -11 -38 -17 0 -5 -3 -7 -8 -4 -4 2 -34 -2 -67 -9 -91 -22 -395 -10 -411
16 -3 5 -15 9 -26 9 -21 0 -192 80 -185 87 2 2 26 -6 53 -18 27 -11 58 -24 69
-29 11 -4 45 -16 75 -26 103 -33 410 -29 455 6 6 4 24 10 40 14 54 12 191 87
268 147 88 68 189 189 224 268 48 108 53 122 48 131 -3 5 -1 11 5 15 6 4 8 11
5 16 -4 5 -3 15 1 21 11 17 13 36 20 153 5 77 3 113 -10 154 -8 29 -13 57 -11
62 3 4 1 10 -5 14 -6 4 -8 10 -5 15 15 24 -82 221 -146 295 -14 16 -38 48 -54
70 -28 38 -27 37 30 -15 33 -30 75 -73 92 -96z m-1567 -499 c0 -8 -4 -15 -10
-15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m20 -105 c0 -5 -4
-10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m60 -193 c0
-5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m200
-273 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z"/>
<path d="M5036 2678 c-9 -28 -14 -196 -5 -209 11 -18 55 -21 69 -4 7 8 10 51
8 116 l-3 104 -33 3 c-20 2 -34 -2 -36 -10z"/>
<path d="M5389 2583 c-35 -41 -99 -147 -99 -163 0 -10 7 -21 16 -24 32 -12 82
31 119 101 10 21 26 46 34 56 17 20 8 33 -29 42 -18 4 -30 1 -41 -12z"/>
<path d="M4683 2574 c-7 -18 11 -53 65 -127 25 -35 40 -47 56 -45 36 5 31 42
-18 118 -37 57 -50 70 -71 70 -14 0 -28 -7 -32 -16z"/>
<path d="M5475 2540 c-4 -7 -1 -16 6 -21 11 -6 9 -12 -7 -31 -11 -13 -31 -34
-43 -48 -12 -13 -21 -35 -19 -47 4 -33 -324 -363 -361 -363 -17 0 -53 22 -106
66 -187 154 -240 181 -265 135 -24 -46 -2 -76 155 -206 39 -31 99 -83 134
-114 35 -31 71 -59 80 -62 30 -12 76 16 95 57 25 54 296 321 439 433 47 37 71
51 79 43 7 -7 -7 -23 -45 -52 -48 -35 -78 -70 -61 -70 2 0 27 16 55 35 28 19
59 35 68 35 10 0 5 -7 -14 -20 -16 -11 -34 -20 -38 -20 -5 0 -15 -7 -24 -15
-8 -9 -28 -18 -43 -22 -16 -3 -32 -15 -39 -29 -12 -27 -12 -28 25 -14 24 9 25
9 14 -5 -11 -13 -4 -14 57 -11 37 1 78 8 90 14 33 18 41 14 14 -8 -13 -11 -30
-20 -38 -20 -11 0 -64 -25 -102 -47 -2 -2 -2 -6 1 -9 4 -3 19 1 35 9 15 8 46
18 67 21 87 14 110 30 82 58 -7 7 -11 18 -9 25 8 22 -18 24 -51 4 -36 -23 -46
-25 -46 -13 0 5 18 17 40 27 22 10 40 23 40 29 0 25 -54 100 -116 162 -23 23
-43 47 -45 53 -3 8 -22 -3 -49 -29 -48 -44 -100 -76 -100 -61 0 6 78 78 119
108 8 6 -14 22 -40 28 -17 4 -30 2 -34 -5z m145 -109 c0 -16 -48 -67 -55 -59
-4 4 3 21 15 37 21 30 40 40 40 22z m-535 -491 c0 -7 -6 -15 -12 -17 -8 -3
-13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z"/>
<path d="M4424 2325 c-4 -8 -4 -21 -1 -29 8 -19 137 -106 158 -106 14 0 29 19
29 35 0 12 -52 56 -96 84 -56 34 -82 39 -90 16z"/>
<path d="M5706 2135 c-50 -18 -56 -23 -36 -28 13 -3 45 -1 72 4 37 8 48 15 48
30 0 23 -9 23 -84 -6z"/>
<path d="M5765 2101 c-11 -5 -42 -11 -70 -15 -60 -8 -105 -25 -105 -38 0 -6 7
-7 18 -2 9 4 33 7 52 6 60 -3 75 -3 90 0 8 2 16 -1 18 -7 3 -10 -25 -19 -120
-38 -62 -12 -86 -37 -73 -77 7 -22 9 -23 115 -16 70 4 111 3 115 -4 4 -6 -8
-10 -29 -10 -20 0 -36 -4 -36 -8 0 -4 -44 -6 -97 -4 -54 2 -95 2 -92 -2 3 -3
48 -10 100 -16 52 -5 103 -12 114 -15 33 -9 47 9 51 64 3 45 1 53 -21 67 -30
20 -33 38 -5 29 15 -5 20 -2 20 13 0 11 -6 23 -12 25 -10 4 -10 8 1 15 8 6 11
17 8 26 -7 18 -12 19 -42 7z m5 -123 c0 -14 -50 -20 -105 -13 -84 10 -67 26
25 23 44 -2 80 -6 80 -10z"/>
<path d="M4345 1981 c-25 -5 -30 -11 -30 -36 l0 -30 108 -3 c110 -3 147 6 147
35 0 31 -32 42 -115 41 -44 0 -93 -3 -110 -7z"/>
<path d="M5788 1844 c-3 -3 -59 -7 -123 -9 -65 -3 -120 -7 -123 -10 -13 -13
15 -17 102 -16 83 2 95 0 90 -14 -3 -8 -11 -15 -18 -16 -6 0 -20 -2 -31 -5
-11 -2 -46 -7 -78 -10 -77 -9 -71 -29 9 -28 81 1 175 17 173 29 -1 6 -10 9
-21 7 -31 -5 -20 15 12 22 24 4 30 11 30 31 0 23 -10 32 -22 19z"/>
<path d="M5490 1740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5750 1733 c-14 -3 -29 -9 -35 -13 -14 -11 -135 -16 -164 -6 -17 6
-26 4 -32 -7 -5 -9 -31 -18 -59 -22 -30 -4 -47 -11 -44 -17 4 -6 23 -8 44 -4
31 5 42 2 61 -17 13 -13 33 -27 44 -32 19 -8 19 -9 -4 -13 -13 -2 -37 -12 -55
-23 -20 -12 -51 -19 -91 -20 -33 -1 -73 -5 -90 -9 -26 -6 -22 -8 25 -10 30 -1
75 -5 100 -9 43 -7 44 -8 17 -16 -16 -4 -52 -5 -80 -2 -29 3 -72 1 -97 -4 -25
-5 -56 -12 -68 -14 -46 -7 -33 -13 28 -13 34 0 64 -3 67 -6 16 -16 -20 -26
-97 -28 -46 -2 -76 -5 -66 -6 16 -4 16 -5 -1 -18 -16 -13 -14 -13 22 -9 77 10
155 10 156 0 0 -15 -38 -23 -113 -24 -42 -1 -68 -5 -70 -13 -3 -8 -12 -5 -28
10 -23 22 -23 22 -4 37 17 14 15 15 -36 13 -52 -3 -55 -4 -55 -30 -1 -36 -10
-43 -52 -39 -23 2 -33 -1 -29 -8 4 -6 23 -11 42 -11 25 0 34 -4 34 -16 0 -14
-11 -16 -82 -11 -46 3 -96 9 -113 12 -24 5 -21 2 14 -14 25 -12 71 -23 109
-25 49 -3 66 -8 68 -19 2 -12 -9 -16 -54 -16 -31 0 -61 3 -67 7 -5 4 -23 8
-40 9 -16 1 -46 5 -65 9 -69 13 -16 -14 68 -36 93 -23 316 -37 327 -20 3 5 26
10 51 10 24 0 44 5 44 10 0 6 10 10 22 10 12 0 54 16 93 35 125 63 253 171
318 268 l37 57 -30 0 c-16 0 -30 5 -30 10 0 6 15 10 33 10 28 0 36 5 50 35 18
38 22 66 10 64 -5 -1 -19 -4 -33 -6z m18 -45 c-3 -7 -20 -14 -39 -16 -18 -2
-37 -8 -40 -14 -5 -7 -12 -7 -25 0 -18 10 -18 11 1 17 11 4 27 10 35 15 24 14
72 12 68 -2z m-103 -123 c0 -5 -19 -11 -42 -13 -40 -3 -58 10 -30 22 20 8 72
2 72 -9z m-97 -47 c-3 -7 -13 -15 -24 -17 -10 -1 -26 -9 -34 -16 -8 -7 -26
-15 -40 -18 -14 -3 -35 -9 -47 -12 -15 -5 -23 -3 -23 4 0 6 20 16 45 23 25 7
45 16 45 20 0 10 38 26 63 27 11 1 17 -4 15 -11z m62 -17 c0 -5 -6 -11 -12
-14 -19 -6 -49 4 -42 14 7 12 54 12 54 0z m-65 -51 c-11 -12 -31 -20 -50 -20
-17 0 -36 -5 -42 -11 -6 -6 -19 -9 -29 -7 -15 3 -11 7 16 19 46 21 92 37 109
38 11 1 10 -4 -4 -19z m-69 -75 c-22 -17 -36 -19 -36 -7 0 14 12 22 34 22 21
-1 21 -1 2 -15z m-116 -4 c0 -5 -17 -12 -37 -16 -21 -4 -43 -11 -49 -16 -5 -4
-41 -11 -78 -13 -37 -3 -71 -8 -76 -11 -13 -8 -40 6 -40 21 0 10 12 14 40 14
25 0 40 5 40 13 0 9 2 9 8 -1 6 -9 21 -11 50 -6 23 4 42 9 42 13 0 3 23 7 50
8 28 2 50 -1 50 -6z m0 -55 c-13 -12 -42 -21 -179 -56 -38 -9 -91 -3 -91 11 0
5 15 10 33 11 17 1 43 2 57 3 14 0 32 3 40 7 8 3 35 11 60 18 25 7 50 14 55
15 6 2 17 4 25 4 13 1 13 -1 0 -13z"/>
<path d="M4514 1687 c-77 -48 -89 -63 -76 -93 13 -27 34 -31 52 -9 7 8 16 15
21 15 19 0 99 65 99 80 0 19 -19 40 -34 39 -6 0 -34 -15 -62 -32z"/>
<path d="M5407 1616 c-26 -7 -50 -15 -53 -18 -8 -9 113 -1 128 8 7 5 21 7 31
6 9 -2 17 2 17 7 0 15 -64 13 -123 -3z"/>
<path d="M4775 1522 c-13 -9 -80 -110 -96 -145 -15 -34 -5 -57 24 -57 25 0 56
37 105 128 22 42 23 45 6 63 -19 21 -24 22 -39 11z"/>
<path d="M4930 1418 c0 -5 14 -8 30 -8 17 0 30 3 30 8 0 4 -13 7 -30 7 -16 0
-30 -3 -30 -7z"/>
<path d="M4850 1380 c0 -5 8 -10 18 -10 9 0 28 -3 42 -5 22 -5 21 -3 -5 9 -36
18 -55 20 -55 6z"/>
<path d="M7820 2830 l-65 -6 68 -4 c38 -3 71 -2 73 2 7 12 -9 13 -76 8z"/>
<path d="M7785 2771 c-87 -5 -94 -7 -55 -13 63 -10 153 -5 158 10 2 7 2 11 0
10 -1 -1 -48 -4 -103 -7z"/>
<path d="M6854 2708 c-12 -19 24 -36 90 -43 93 -10 937 12 950 25 7 7 -13 10
-67 10 -45 0 -77 4 -77 10 0 15 -887 14 -896 -2z"/>
<path d="M1678 2620 l-28 -28 36 -27 36 -28 24 26 c13 14 24 28 24 31 0 9 -50
56 -58 55 -4 -1 -19 -14 -34 -29z"/>
<path d="M7560 2620 c-27 -4 29 -8 142 -8 115 -1 189 2 193 8 7 11 -262 11
-335 0z"/>
<path d="M7728 2575 c-11 -11 -118 -16 -480 -23 -341 -7 -358 -8 -372 -27 -32
-42 -15 -44 521 -44 381 1 503 3 500 12 -2 8 -35 13 -90 15 -79 3 -87 5 -87
22 0 18 7 20 91 20 68 0 90 3 87 13 -5 12 -158 24 -170 12z"/>
<path d="M3813 2171 c-10 -17 -21 -31 -25 -31 -18 0 -6 -38 17 -58 14 -12 27
-22 28 -22 2 0 17 17 34 38 l30 37 -33 34 -34 33 -17 -31z"/>
<path d="M6915 2042 c-25 -5 41 -10 180 -15 303 -10 415 -9 415 3 0 6 -43 10
-107 10 -60 0 -184 2 -278 4 -93 3 -188 1 -210 -2z"/>
<path d="M6167 1610 l-35 -35 28 -27 c34 -33 46 -35 62 -8 7 11 18 20 25 20
22 0 14 27 -16 57 l-28 29 -36 -36z"/>
</g>
</svg>
  )
}

export const Case7SVG = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">

<g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill="#fae7e7" stroke="none">
<path d="M8553 9398 c-6 -13 -34 -54 -62 -92 -43 -58 -51 -76 -51 -112 0 -57
14 -92 56 -135 32 -33 34 -39 22 -58 -16 -26 -16 -26 -70 4 -58 32 -171 47
-247 33 -30 -5 -85 -23 -122 -40 -64 -28 -68 -29 -97 -14 -65 34 -150 8 -184
-55 -29 -55 -22 -94 27 -149 l22 -25 -23 -70 c-13 -38 -24 -77 -24 -86 0 -9
-9 -37 -20 -62 -11 -25 -25 -72 -31 -104 -6 -31 -21 -78 -34 -103 -13 -25 -31
-73 -40 -105 -9 -33 -23 -75 -31 -93 -9 -18 -18 -50 -20 -70 -3 -20 -14 -57
-24 -82 -10 -25 -24 -65 -31 -90 -8 -25 -31 -64 -51 -86 -21 -23 -38 -48 -38
-57 0 -23 76 -84 146 -117 34 -17 94 -37 135 -46 86 -19 264 -20 348 -3 76 15
167 60 221 108 25 22 48 41 52 41 11 0 19 36 12 54 -3 9 -14 16 -23 16 -12 0
-26 26 -50 98 -19 53 -42 111 -51 127 -9 17 -25 59 -35 95 -10 36 -22 70 -26
75 -4 6 -12 29 -18 50 -5 22 -14 56 -19 76 -5 20 -21 58 -35 85 -28 52 -68
159 -69 184 -1 18 -33 148 -43 172 -5 12 8 27 47 57 104 78 188 94 308 58 125
-37 114 38 122 -837 6 -713 11 -832 33 -830 31 4 35 25 37 194 l1 171 8 -189
c5 -127 11 -192 20 -200 8 -7 10 -7 6 0 -7 12 10 81 22 88 4 3 5 -8 3 -24 -2
-17 -1 -38 3 -47 21 -51 27 93 34 803 6 692 8 763 23 786 27 41 117 72 213 72
74 1 86 -2 168 -41 48 -24 87 -48 87 -56 0 -8 -12 -48 -26 -88 -14 -41 -26
-85 -26 -99 1 -39 -26 -109 -63 -170 -33 -55 -85 -180 -85 -206 0 -8 -14 -36
-30 -63 -36 -57 -60 -131 -60 -183 0 -20 -5 -40 -11 -44 -6 -3 -22 -30 -35
-59 -12 -29 -28 -56 -33 -60 -24 -14 -7 -44 53 -92 127 -101 201 -130 356
-140 107 -6 213 7 295 38 59 22 172 100 182 126 8 21 -9 47 -36 51 -14 2 -21
11 -21 27 0 25 -37 154 -50 170 -4 6 -12 36 -18 68 -7 32 -20 70 -30 85 -11
15 -24 50 -31 77 -7 28 -23 75 -36 105 -13 30 -29 75 -35 100 -6 25 -19 72
-30 105 -10 33 -29 101 -41 151 l-22 91 21 21 c47 47 37 153 -18 192 -43 30
-92 36 -144 16 -43 -16 -46 -16 -82 3 -21 11 -72 28 -113 37 -64 15 -87 15
-144 6 -37 -7 -86 -21 -107 -32 -51 -26 -79 -25 -89 1 -5 16 -1 28 16 45 34
34 53 85 53 142 0 40 -7 58 -37 103 -21 30 -46 71 -55 92 -21 44 -58 51 -75
15z m58 -169 c21 -29 28 -73 15 -86 -3 -3 -15 2 -26 12 -11 10 -23 16 -26 12
-8 -8 5 -47 17 -47 5 0 9 -9 9 -20 0 -26 -12 -25 -37 3 -24 26 -45 85 -36 101
11 19 46 56 54 56 4 0 18 -14 30 -31z m-357 -260 c-56 -5 -98 -16 -134 -34
-56 -28 -77 -29 -34 -1 48 32 98 45 174 44 l75 -1 -81 -8z m735 -8 c13 -3 -11
-7 -55 -7 -54 -1 -98 -8 -138 -23 -60 -21 -94 -22 -40 0 93 37 172 47 233 30z
m-379 -26 c14 -17 15 -17 -20 -32 -16 -8 -19 -12 -9 -12 9 -1 19 -11 23 -23 8
-32 8 -35 -10 -53 -15 -15 -18 -15 -35 1 -36 33 -16 134 26 134 7 0 18 -7 25
-15z m-659 -25 c22 -12 26 -59 7 -78 -20 -20 -76 -15 -88 7 -14 28 -13 38 10
61 22 22 42 25 71 10z m1337 -13 c40 -31 -15 -99 -66 -81 -22 8 -27 16 -27 44
0 51 49 71 93 37z m-1306 -174 c21 -36 33 -99 20 -107 -11 -6 -62 113 -55 127
8 13 19 7 35 -20z m-72 -5 c0 -26 -28 -92 -40 -96 -15 -5 -6 59 13 89 14 22
27 25 27 7z m1387 -51 c21 -51 22 -87 4 -87 -5 0 -16 27 -26 60 -10 33 -22 60
-26 60 -5 0 -6 7 -2 15 10 27 27 11 50 -48z m-77 46 c0 -20 -35 -113 -43 -113
-11 0 -1 60 15 96 10 22 28 33 28 17z m-628 -708 l-9 -330 -1 486 c-1 267 1
489 6 493 11 12 13 -283 4 -649z m-642 594 c8 -14 8 -24 1 -32 -6 -8 -6 -18
-1 -27 20 -32 6 -140 -19 -148 -11 -4 -13 11 -9 81 3 50 2 89 -4 95 -6 6 -6
17 1 31 14 26 18 26 31 0z m1303 -18 c3 -14 1 -18 -9 -14 -8 3 -14 14 -14 25
0 23 16 16 23 -11z m-1352 -416 c2 -281 6 -311 46 -334 10 -5 14 -13 10 -17
-8 -8 -307 -17 -307 -9 0 2 7 30 15 62 17 65 20 122 6 107 -5 -5 -25 -45 -45
-89 -19 -44 -36 -78 -36 -75 0 3 7 38 15 78 18 87 33 114 57 106 19 -6 39 31
51 93 3 18 22 54 40 80 19 26 41 68 49 93 35 104 36 108 25 119 -8 8 -8 11 1
11 19 0 40 52 49 121 l7 64 8 -70 c4 -38 8 -191 9 -340z m149 363 c26 -60 34
-98 21 -98 -14 0 -48 67 -54 108 -7 45 11 40 33 -10z m1104 -6 c-4 -18 -10
-32 -14 -32 -4 0 -11 -15 -15 -32 -5 -21 -9 -27 -12 -16 -4 16 36 122 43 115
2 -2 1 -18 -2 -35z m-1310 -27 c-7 -51 -53 -119 -53 -80 -1 41 20 110 36 122
23 18 26 12 17 -42z m1421 43 c15 -15 67 -167 70 -206 8 -95 63 -202 105 -202
13 0 16 -6 13 -24 -6 -32 34 -122 63 -141 28 -18 50 -57 59 -108 12 -61 -10
-43 -41 36 -26 66 -29 69 -32 40 -2 -17 4 -54 12 -82 l15 -51 -151 0 -152 0
30 31 c28 29 29 34 29 122 0 174 -15 559 -23 572 -4 8 -8 -16 -10 -52 -1 -36
-8 -75 -15 -87 -11 -18 -12 -13 -15 43 -2 35 0 71 4 80 4 9 6 21 5 28 -1 11
23 12 34 1z m99 -50 c11 -40 12 -55 3 -66 -9 -11 -15 -4 -29 37 -16 49 -15 81
2 81 5 0 15 -23 24 -52z m-175 -240 c-1 -139 -2 -297 -2 -350 l1 -98 -139 0
c-77 0 -139 3 -139 8 0 4 4 23 10 42 16 57 -5 56 -36 -1 -28 -51 -54 -74 -54
-48 0 20 61 109 74 109 7 0 20 8 28 18 20 23 58 151 58 196 0 32 3 36 24 36
17 0 34 12 50 33 26 34 67 135 69 172 2 25 50 145 56 140 2 -2 2 -118 0 -257z
m-1147 129 c-4 -14 19 -88 40 -133 11 -23 28 -44 40 -47 11 -3 17 -10 14 -15
-3 -5 6 -39 20 -76 21 -52 31 -67 51 -72 29 -8 58 -63 67 -129 l6 -40 -24 50
c-24 49 -55 89 -64 81 -2 -3 10 -44 27 -93 17 -49 31 -92 31 -96 0 -4 -57 -7
-126 -7 -117 0 -126 1 -118 18 13 25 10 128 -5 188 -10 42 -16 51 -31 47 -22
-6 -33 11 -32 50 1 49 22 58 22 9 0 -44 14 -57 23 -22 3 11 3 93 0 181 -2 89
-2 173 2 188 7 25 8 24 33 -24 14 -27 25 -54 24 -58z m62 1 c18 -29 40 -112
33 -130 -2 -7 -17 14 -31 47 -15 33 -31 67 -36 76 -18 34 13 39 34 7z m966
-82 c-30 -72 -60 -115 -60 -86 0 27 44 136 64 158 32 36 30 11 -4 -72z m354
-26 c27 -77 29 -107 5 -83 -14 14 -59 139 -59 165 0 39 25 1 54 -82z m-1664
-3 c-20 -53 -35 -75 -46 -65 -6 6 36 130 49 147 22 26 20 -22 -3 -82z m194 14
c4 -27 2 -48 -5 -57 -6 -8 -8 -22 -5 -31 5 -12 4 -15 -3 -8 -12 13 -14 26 -12
105 2 70 14 66 25 -9z m1306 -16 c0 -48 -12 -75 -33 -75 -9 0 -2 94 10 134 9
32 23 -3 23 -59z m-1076 -102 c3 -16 8 -39 11 -53 5 -20 2 -18 -14 10 -21 37
-27 70 -12 70 5 0 11 -12 15 -27z m779 -48 c-9 -19 -18 -33 -21 -31 -4 5 26
66 33 66 3 0 -3 -16 -12 -35z m291 -56 c-3 -5 -12 -9 -20 -9 -15 0 -19 37 -7
80 6 23 8 21 20 -19 7 -24 10 -48 7 -52z m-1560 44 c-4 -16 -13 -35 -20 -43
-12 -12 -14 -12 -14 2 0 23 21 68 32 68 5 0 5 -12 2 -27z m1821 -35 c4 -18 9
-41 12 -52 8 -30 -13 -10 -31 29 -16 34 -15 55 4 55 5 0 11 -15 15 -32z m-565
-53 c0 -2 -5 -15 -11 -29 -13 -27 -39 -43 -39 -23 1 32 14 57 31 57 11 0 19
-2 19 -5z m310 -90 c0 -42 -13 -65 -38 -65 -12 0 -14 11 -9 58 4 32 9 67 13
77 6 16 8 15 20 -6 7 -13 13 -42 14 -64z m-1290 35 c32 -62 22 -121 -15 -90
-24 20 -33 130 -11 130 3 0 15 -18 26 -40z m45 -239 c18 -7 17 -9 -12 -24 -18
-10 -45 -17 -61 -17 -44 0 -195 30 -206 41 -12 11 250 11 279 0z m1263 -10
c-36 -3 -99 -3 -140 0 -54 4 -38 6 67 6 97 0 120 -2 73 -6z m-666 -423 c-6 -6
-7 0 -4 19 5 21 7 23 10 9 2 -10 0 -22 -6 -28z"/>
<path d="M8552 8858 c5 -32 28 -35 28 -4 0 17 -5 26 -16 26 -10 0 -14 -7 -12
-22z"/>
<path d="M5034 9396 c-10 -26 1 -51 22 -54 27 -4 47 21 39 48 -7 24 -53 28
-61 6z"/>
<path d="M5231 9391 c-13 -24 -5 -48 19 -54 28 -7 42 7 38 39 -4 36 -41 46
-57 15z"/>
<path d="M4841 9387 c-24 -29 17 -70 46 -46 20 17 12 53 -13 57 -11 2 -26 -3
-33 -11z"/>
<path d="M5426 9384 c-9 -8 -16 -19 -16 -24 0 -13 30 -40 44 -40 26 0 40 28
28 55 -13 29 -34 32 -56 9z"/>
<path d="M5610 9355 c-14 -17 -5 -51 16 -59 22 -8 54 11 54 32 0 38 -46 56
-70 27z"/>
<path d="M6858 9326 c-60 -25 -132 -76 -148 -106 -8 -14 -4 -20 20 -30 27 -11
33 -10 67 20 45 40 107 68 165 76 38 5 110 -11 179 -40 21 -9 45 40 28 60 -18
22 -112 44 -186 44 -48 0 -85 -7 -125 -24z"/>
<path d="M5822 9318 c-19 -19 -14 -56 8 -63 24 -8 50 10 50 34 0 19 -19 41
-35 41 -6 0 -16 -5 -23 -12z"/>
<path d="M4010 9302 c-19 -10 -64 -34 -100 -54 -36 -19 -121 -66 -190 -103
-173 -94 -483 -264 -750 -413 -352 -195 -406 -224 -554 -303 -98 -52 -141 -80
-147 -97 -12 -31 -12 -394 1 -426 14 -37 36 -44 170 -55 68 -6 127 -14 131
-18 5 -5 9 -272 11 -596 l3 -587 -123 0 c-164 0 -162 2 -162 -160 0 -148 4
-154 108 -165 52 -5 72 -11 76 -23 3 -10 14 -42 26 -72 20 -54 21 -63 14 -725
l-6 -670 24 -35 c28 -41 62 -49 118 -26 23 9 42 12 45 6 6 -10 -45 -40 -70
-40 -9 0 -24 -13 -33 -30 -21 -37 -31 -37 -67 0 -16 16 -33 30 -38 30 -15 0 3
-183 20 -202 20 -23 133 -79 144 -72 5 3 9 41 9 85 0 47 4 79 10 79 6 0 41
-15 78 -34 37 -18 112 -53 167 -76 202 -86 482 -227 503 -255 15 -19 22 -42
22 -74 0 -59 12 -80 35 -61 20 16 500 23 526 7 51 -31 59 27 59 426 0 141 4
257 9 257 15 0 21 -86 22 -304 0 -116 4 -231 8 -256 5 -25 9 -37 10 -27 1 14
11 17 54 18 59 0 91 3 297 25 80 8 172 17 205 19 l60 4 -50 -9 c-27 -5 -77
-12 -110 -15 -33 -4 -82 -11 -110 -16 -27 -5 -104 -17 -170 -25 -152 -19 -170
-33 -44 -36 191 -3 449 17 674 52 64 10 101 13 94 6 -4 -4 -71 -15 -199 -31
-25 -4 -128 -20 -230 -36 -102 -17 -202 -33 -223 -36 -20 -2 -50 -9 -65 -14
-44 -15 300 -1 413 16 117 18 242 30 235 22 -5 -5 -195 -42 -272 -52 -61 -8
-72 -25 -16 -25 74 1 362 32 588 65 14 2 50 6 80 10 55 7 318 50 480 80 47 8
125 20 173 26 117 14 158 30 57 23 -130 -9 -654 -69 -730 -84 -78 -15 -137
-20 -115 -10 11 5 162 32 335 60 173 28 329 55 345 60 30 9 95 19 128 20 10 0
16 4 12 9 -3 5 -20 8 -38 6 -17 -2 -88 -8 -157 -15 -69 -6 -147 -13 -175 -15
-27 -3 -84 -9 -125 -14 -41 -5 -100 -12 -130 -15 -51 -5 -49 -4 15 8 39 8 91
17 117 20 26 4 53 9 60 11 7 3 135 25 283 49 149 25 281 47 295 49 20 3 18 5
-8 6 -74 3 -578 -47 -840 -83 -51 -7 -89 -9 -85 -5 5 4 26 9 48 12 22 3 78 11
125 17 47 6 184 29 305 51 121 23 258 47 305 55 95 16 163 31 140 33 -14 1
-336 -23 -450 -34 -44 -4 -37 -1 35 15 50 11 128 26 175 35 47 9 96 18 110 21
14 2 58 9 99 14 67 9 107 20 70 20 -40 0 -351 -21 -393 -26 -47 -6 -49 -5 -43
18 4 12 7 109 7 214 0 183 1 192 20 197 19 5 20 0 22 -171 l2 -177 6 175 5
175 28 3 27 3 0 -165 c0 -154 6 -187 26 -154 4 6 2 14 -4 16 -9 3 -12 47 -12
152 l0 148 26 6 c15 4 29 5 31 2 3 -3 6 -75 7 -161 1 -115 4 -155 13 -155 33
0 153 25 153 32 0 4 -22 8 -50 8 -43 0 -50 3 -50 20 0 11 -6 20 -12 20 -10 0
-10 2 0 8 7 5 15 28 18 51 3 23 6 336 7 696 2 360 6 657 9 661 4 3 7 -283 8
-637 0 -633 4 -779 21 -779 11 0 9 -15 19 140 24 362 29 555 31 1170 1 366 5
735 9 820 4 102 7 -61 9 -475 2 -346 4 -666 6 -710 1 -44 3 -280 5 -525 l3
-445 13 155 c24 293 42 1081 44 1974 0 229 10 127 16 -165 3 -156 7 -318 9
-359 2 -41 4 -320 5 -620 2 -541 12 -655 24 -280 4 107 9 229 11 270 2 41 7
215 10 385 7 376 15 588 21 594 2 2 5 -54 5 -125 1 -71 3 -145 4 -164 2 -19 4
-267 6 -550 3 -597 10 -752 22 -480 12 294 18 393 24 440 3 25 7 503 10 1062
4 918 6 1017 21 1023 8 3 87 11 175 17 116 9 165 16 179 28 18 14 19 30 18
245 0 175 -4 234 -14 250 -10 16 -182 77 -727 258 -393 130 -804 264 -914 298
-110 33 -315 96 -455 139 -356 110 -564 170 -586 170 -11 0 -35 -8 -54 -18z
m235 -159 c55 -13 159 -42 230 -63 72 -21 247 -73 390 -115 542 -160 1021
-312 992 -314 -4 -1 -166 44 -360 100 -300 86 -672 189 -684 189 -1 0 -4 -19
-5 -42 l-1 -43 -6 44 c-5 39 -10 46 -41 57 -58 21 -70 18 -71 -18 l-2 -33 -6
35 c-6 34 -36 60 -72 60 -19 0 -28 -42 -30 -145 -2 -62 -3 -57 -10 39 -4 60
-11 112 -16 116 -4 5 -25 13 -45 18 l-38 10 -1 -54 c-1 -30 -3 -63 -4 -74 -1
-11 -4 -37 -5 -57 -2 -21 -7 14 -12 76 -9 121 -13 131 -60 131 l-28 0 -1 -152
c-1 -175 -13 -157 -24 36 -7 129 -14 146 -61 146 -12 0 -24 -6 -27 -12 -2 -7
-2 -78 1 -157 3 -85 1 -141 -5 -138 -5 3 -9 32 -11 64 -4 152 -15 252 -26 256
-7 3 -22 -2 -34 -12 -19 -14 -22 -32 -31 -169 -6 -85 -11 -203 -11 -264 0 -99
-2 -109 -17 -106 -17 3 -18 27 -21 316 l-2 312 27 -6 c16 -3 73 -17 128 -31z
m-252 -80 c7 -138 -1 -515 -11 -524 -4 -4 -117 -44 -252 -88 -368 -120 -728
-243 -885 -301 -197 -73 -474 -170 -486 -170 -5 0 -9 64 -9 154 l0 155 28 19
c15 10 107 60 204 110 166 86 463 248 1038 564 288 159 341 187 356 188 8 0
14 -37 17 -107z m364 -465 c-2 -24 -4 -7 -4 37 0 44 2 63 4 43 2 -21 2 -57 0
-80z m1568 37 c39 -17 21 -18 -25 0 -19 7 -28 14 -20 14 8 0 29 -6 45 -14z
m-790 -260 c177 -21 364 -51 645 -100 128 -23 509 -85 560 -92 71 -9 227 -41
205 -42 -46 -2 -512 63 -855 119 -102 17 -217 35 -257 41 -39 5 -82 12 -95 14
-13 3 -66 12 -118 21 -52 9 -104 18 -115 20 -11 2 -47 9 -80 14 -68 11 -128
28 -105 29 8 1 105 -10 215 -24z m-773 -24 c40 -5 134 -17 208 -26 74 -9 198
-26 275 -37 77 -11 151 -22 165 -24 294 -41 447 -64 763 -115 207 -34 375 -64
373 -66 -10 -10 -649 67 -846 101 -14 3 -63 10 -110 16 -47 6 -98 13 -115 16
-207 30 -740 91 -872 100 -103 8 -105 19 -6 33 82 12 83 12 165 2z m-382 -41
c17 -11 9 -15 -55 -25 -61 -10 -383 -89 -545 -135 -80 -22 -188 -52 -240 -66
-91 -25 -397 -112 -469 -134 -19 -5 -30 -6 -25 -1 10 10 241 89 439 150 216
67 565 171 655 196 88 24 213 32 240 15z m2585 -80 c3 -6 -1 -7 -9 -4 -18 7
-21 14 -7 14 6 0 13 -4 16 -10z m-2448 -52 c4 -7 10 -337 13 -733 l5 -720 30
-14 c20 -10 56 -14 107 -12 l77 2 3 -181 3 -180 -57 0 -58 0 0 154 c0 175 8
159 -92 171 l-58 6 -2 752 c-2 414 -2 755 0 760 5 11 20 8 29 -5z m-108 -773
l3 -735 -244 0 c-135 0 -441 -3 -681 -7 l-437 -6 0 180 c0 99 3 342 7 539 l6
360 38 12 c73 23 69 36 69 -210 0 -258 19 -784 30 -813 4 -10 10 109 14 265 5
201 11 292 21 309 12 23 13 22 18 -35 4 -32 9 -122 12 -199 10 -260 17 -355
27 -355 6 0 8 196 6 545 -3 504 -2 545 14 545 9 0 22 2 30 5 11 4 13 -50 13
-302 0 -528 6 -761 20 -773 9 -8 13 30 18 172 4 100 7 225 7 278 0 102 7 160
20 160 17 0 23 -67 36 -440 3 -96 8 -177 10 -179 16 -17 19 74 19 565 0 305 2
554 5 554 3 0 14 3 26 6 l21 6 7 -524 c7 -511 14 -659 29 -593 3 17 9 157 13
313 4 155 11 282 15 282 5 0 9 8 9 17 0 12 3 14 11 6 7 -7 14 -99 20 -239 13
-365 17 -409 35 -392 8 8 6 980 -2 1090 l-7 87 26 6 c14 4 30 4 36 1 6 -4 8
-146 7 -389 -2 -210 -1 -436 3 -502 l6 -120 28 -3 27 -3 0 225 c0 202 6 257
24 227 3 -5 11 -78 16 -162 10 -147 20 -199 37 -199 8 0 9 448 2 793 -4 190
-3 196 46 197 20 0 20 -8 23 -492 1 -270 4 -493 6 -495 2 -2 16 -5 32 -6 l28
-2 -3 201 c-2 165 0 202 11 207 10 3 15 -5 16 -32 6 -105 25 -271 35 -306 18
-62 33 539 19 750 -14 204 -12 212 41 217 23 3 44 3 46 1 3 -2 0 -52 -7 -111
-12 -112 -17 -834 -6 -890 7 -34 31 -47 66 -36 l23 7 -21 8 c-21 7 -22 11 -29
520 -7 488 -7 513 11 526 10 7 24 13 32 13 13 0 14 -14 6 -92 -10 -105 -10
-347 0 -673 7 -212 11 -263 23 -251 10 9 12 712 3 856 -9 129 -8 136 12 157
12 12 25 20 31 16 6 -3 7 -1 3 5 -4 7 -17 12 -28 12 -12 0 -18 3 -14 7 7 7
106 52 116 53 3 0 7 -331 9 -735z m2594 728 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12
5 14 0 19 -2 13 -5z m-2403 -85 c1 -2 3 -85 7 -185 4 -140 3 -183 -6 -183 -7
0 -9 -4 -6 -10 3 -5 1 -10 -4 -10 -8 0 -11 64 -11 195 0 123 4 195 10 195 6 0
10 -1 10 -2z m138 -12 c3 -3 7 -93 10 -199 l5 -194 -32 5 -31 4 -2 177 -3 176
-2 -178 c-3 -163 -5 -178 -21 -175 -15 3 -17 23 -20 201 l-2 198 46 -5 c25 -3
49 -7 52 -10z m56 -2 c14 -5 16 -32 16 -200 0 -187 -1 -194 -20 -194 -19 0
-20 7 -20 200 0 110 2 200 4 200 2 0 11 -3 20 -6z m259 -47 c16 -11 17 -32 15
-192 l-3 -180 -31 -3 -31 -3 -6 128 c-4 70 -7 158 -7 196 0 63 1 67 23 67 13
0 31 -6 40 -13z m114 -15 c8 -5 12 -62 14 -190 1 -101 -1 -185 -4 -188 -3 -3
-21 -2 -41 1 l-36 7 0 189 0 189 28 0 c15 0 32 -4 39 -8z m113 -197 l0 -185
-25 0 -25 0 0 185 0 185 25 0 25 0 0 -185z m44 179 c14 -5 16 -32 16 -190 0
-173 -1 -184 -19 -184 -17 0 -19 13 -23 183 -3 100 -3 185 -1 190 5 9 7 9 27
1z m-1290 -43 c8 -13 -6 -201 -15 -201 -11 0 -22 142 -14 179 6 30 19 40 29
22z m1546 -176 c0 -178 -1 -185 -20 -185 -19 0 -20 7 -20 185 0 178 1 185 20
185 19 0 20 -7 20 -185z m60 -11 c0 -171 -1 -185 -17 -182 -16 3 -18 21 -21
186 -2 177 -2 182 18 182 19 0 20 -6 20 -186z m87 -6 l5 -178 -38 0 -39 0 0
180 0 181 34 -3 34 -3 4 -177z m-2397 152 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5
10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m2570 -176 c0 -95 -4 -175 -9 -178 -5 -4
-16 -3 -25 0 -14 5 -16 31 -16 184 l0 177 25 -5 25 -4 0 -174z m-2070 92 c0
-79 -6 -109 -21 -100 -10 6 -12 157 -2 167 17 18 23 1 23 -67z m2134 -98 c-2
-169 -2 -173 -23 -173 -12 0 -21 3 -21 8 0 4 0 81 0 172 0 164 0 165 23 165
l22 0 -1 -172z m84 -16 l2 -172 -35 0 -35 0 0 179 0 178 33 -6 32 -7 3 -172z
m153 148 c5 0 10 -24 11 -52 l2 -53 3 53 c2 28 7 52 11 52 25 -1 30 -23 32
-145 4 -201 3 -204 -20 -198 -14 4 -20 15 -22 37 l-1 31 -4 -33 c-4 -27 -8
-33 -26 -30 -22 3 -22 7 -25 177 -2 156 -1 173 14 167 8 -3 20 -6 25 -6z
m-3106 -15 c-16 -7 -32 -11 -35 -9 -7 8 24 24 45 23 15 0 13 -4 -10 -14z
m3234 -12 c7 -6 14 -300 8 -316 -3 -10 -15 -13 -31 -10 l-26 5 0 170 0 169 23
-7 c12 -3 24 -8 26 -11z m-2699 -72 c0 -58 -4 -82 -15 -91 -13 -11 -15 -2 -15
72 0 47 3 88 7 91 17 18 23 1 23 -72z m-194 2 c-2 -21 -4 -59 -5 -85 0 -27 -5
-48 -11 -48 -15 0 -13 143 2 158 17 17 18 16 14 -25z m-186 -80 c0 -58 -9 -95
-21 -87 -10 6 -12 117 -2 127 16 16 23 4 23 -40z m1498 -480 l2 -183 -52 3
-53 2 -3 169 c-2 93 -1 174 2 181 3 8 22 12 53 11 l48 -1 3 -182z m-134 85 c4
-51 5 -130 4 -176 -2 -68 -5 -82 -18 -80 -22 5 -28 348 -6 348 11 0 16 -22 20
-92z m196 -88 c0 -173 -1 -180 -20 -180 -19 0 -20 7 -20 180 0 173 1 180 20
180 19 0 20 -7 20 -180z m254 144 c3 -9 6 -87 6 -175 l0 -159 -35 0 -35 0 0
175 0 175 29 0 c17 0 31 -6 35 -16z m116 -170 l0 -175 -37 3 -38 3 -3 173 -2
172 40 0 40 0 0 -176z m100 -4 l0 -170 -25 0 -25 0 0 170 0 170 25 0 25 0 0
-170z m60 -5 c0 -96 -3 -175 -7 -175 -25 1 -30 26 -37 183 l-6 167 25 0 25 0
0 -175z m242 -10 c4 -87 7 -160 5 -161 -1 -1 -11 -4 -22 -6 -19 -3 -20 3 -23
152 -3 161 0 184 21 178 8 -3 14 -51 19 -163z m63 -10 c0 -158 0 -160 -22
-163 l-23 -3 0 166 0 166 23 -3 c22 -3 22 -5 22 -163z m69 159 c14 -5 16 -29
16 -165 l0 -159 -35 0 -35 0 0 158 c0 87 3 162 7 165 8 9 27 9 47 1z m194
-177 l4 -159 -29 4 -28 3 -3 158 -3 158 28 -3 27 -3 4 -158z m62 -2 l0 -155
-25 0 -25 0 0 155 0 155 25 0 25 0 0 -155z m68 143 c8 -8 12 -58 12 -161 l0
-148 -32 3 -33 3 -3 158 -3 157 24 0 c13 0 28 -5 35 -12z m220 -17 c1 -4 18
-5 37 -3 l35 4 0 -141 c0 -78 0 -144 0 -148 0 -5 -11 -9 -25 -11 l-25 -3 -2
133 -2 133 -3 -134 c-4 -132 -4 -133 -26 -127 -12 3 -37 6 -54 6 l-33 0 0 148
c0 82 3 151 6 154 7 8 88 -3 92 -11z m-1448 -691 c0 -173 -1 -180 -20 -180
-19 0 -20 7 -20 180 0 173 1 180 20 180 19 0 20 -7 20 -180z m260 -4 c0 -112
-4 -177 -11 -181 -5 -4 -21 -4 -35 -1 l-24 6 0 175 0 175 35 0 35 0 0 -174z
m110 -7 l0 -180 -37 3 -38 3 -3 178 -2 177 40 0 40 0 0 -181z m98 3 l2 -172
-24 0 -24 0 -1 166 c0 91 2 170 5 176 3 5 13 8 22 6 15 -3 17 -24 20 -176z
m62 3 c0 -168 -1 -175 -20 -175 -19 0 -20 7 -20 175 0 168 1 175 20 175 19 0
20 -7 20 -175z m228 -8 c2 -162 2 -167 -18 -167 -19 0 -20 6 -20 163 0 90 3
167 7 171 22 22 28 -11 31 -167z m64 71 l2 -103 5 100 c6 94 7 100 29 103 21
3 22 1 22 -76 0 -43 3 -120 6 -170 l7 -92 -57 0 -56 0 0 170 c0 163 1 170 20
170 19 0 20 -7 22 -102z m114 70 c-3 -13 -6 -87 -6 -165 0 -120 -2 -143 -15
-143 -13 0 -15 25 -15 165 0 161 0 165 21 165 17 0 20 -4 15 -22z m154 -143
l0 -165 -25 0 -25 0 0 158 c0 87 3 162 7 165 3 4 15 7 25 7 17 0 18 -12 18
-165z m68 -2 l2 -163 -31 0 -32 0 7 61 c3 34 6 109 6 166 0 103 0 104 23 101
22 -3 22 -6 25 -165z m80 0 l2 -163 -35 0 -35 0 0 166 0 165 33 -3 32 -3 3
-162z m163 72 l5 -90 2 93 c2 91 3 93 25 90 22 -3 22 -5 24 -165 1 -115 -2
-163 -10 -166 -7 -2 -31 -2 -54 1 l-43 4 0 157 c0 87 3 161 7 165 4 4 14 5 23
4 12 -3 16 -23 21 -93z m107 89 c22 -6 22 -9 22 -170 0 -172 -1 -175 -44 -158
-14 5 -16 30 -16 170 0 90 3 164 8 164 4 0 17 -3 30 -6z m119 -111 c-2 -21 -4
-6 -4 32 0 39 2 55 4 38 2 -18 2 -50 0 -70z m-2595 -11 c3 -10 5 -45 4 -78
l-1 -59 -90 0 c-241 1 -904 18 -935 24 -35 7 -35 7 -38 64 l-3 57 198 3 c109
1 347 3 529 5 312 2 332 1 336 -16z m563 8 c4 -7 -80 -10 -244 -10 -161 0
-251 4 -251 10 0 6 88 10 244 10 153 0 247 -4 251 -10z m105 -10 c0 -13 -30
-13 -50 0 -11 7 -7 10 18 10 17 0 32 -4 32 -10z m1927 -102 c-2 -13 -4 -3 -4
22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m-2487 8 c0 -14 -4 -28 -10 -31 -6 -4
-10 7 -10 24 0 17 5 31 10 31 6 0 10 -11 10 -24z m88 -8 c-3 -34 -20 -42 -26
-13 -5 22 5 45 18 45 7 0 10 -14 8 -32z m123 20 c-1 -5 -1 -17 -1 -28 0 -13
-7 -20 -18 -20 -15 0 -18 6 -14 31 3 19 9 29 19 27 8 -2 14 -6 14 -10z m99
-13 c0 -16 -6 -25 -15 -25 -9 0 -15 9 -15 25 0 16 6 25 15 25 9 0 15 -9 15
-25z m100 9 c0 -18 -27 -48 -33 -37 -7 12 11 53 23 53 5 0 10 -7 10 -16z m144
-3 c6 -8 -25 -21 -51 -21 -19 0 -15 18 5 23 29 8 41 7 46 -2z m-1354 -150 c0
-14 -28 -21 -90 -21 -62 0 -90 7 -90 21 0 5 41 9 90 9 50 0 90 -4 90 -9z m350
-5 c0 -8 -10 -18 -21 -21 -29 -9 -137 3 -174 20 -27 12 -20 13 83 14 91 1 112
-2 112 -13z m383 -15 c20 -20 -35 -33 -111 -26 -85 8 -122 19 -122 36 0 14
219 4 233 -10z m-822 -83 l0 -23 9 23 c5 12 17 22 26 22 15 0 16 -12 9 -122
-4 -68 -10 -355 -14 -638 -4 -283 -8 -490 -10 -460 -4 54 -4 53 -11 -25 -7
-88 -4 -95 48 -95 17 0 32 -4 32 -9 0 -12 -36 -21 -86 -21 l-41 0 -6 107 c-4
58 -7 364 -7 679 0 448 3 573 13 577 26 11 37 6 38 -15z m105 -636 c1 -355 -2
-588 -6 -527 -4 61 -10 283 -14 495 -3 212 -9 443 -12 514 -3 72 -2 126 3 123
5 -3 8 8 7 25 -2 23 1 27 9 19 9 -9 12 -181 13 -649z m245 618 l7 -25 1 28 c1
20 6 27 22 27 24 0 24 4 0 -370 -10 -154 -16 -1118 -7 -1127 2 -3 23 -7 45
-10 23 -3 41 -9 41 -13 0 -13 -63 -19 -120 -12 l-55 7 23 23 24 24 -7 546 c-3
301 -8 634 -10 740 -4 177 -3 193 12 190 10 -2 20 -14 24 -28z m94 10 c0 -7
-6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m266
-22 l1 -33 9 33 c16 53 35 40 44 -30 l8 -63 9 40 c5 22 7 48 4 58 -2 9 0 17 5
17 5 0 9 -17 9 -37 0 -25 3 -33 9 -24 5 7 6 21 4 31 -3 10 0 21 6 24 13 8 14
-1297 2 -1449 -5 -53 -9 145 -10 485 -2 316 -3 636 -3 710 l-1 135 -7 -95 c-5
-75 -7 -83 -9 -40 -1 30 -5 73 -10 95 -14 77 -21 -197 -21 -835 0 -446 -3
-629 -11 -637 -19 -19 -1 -31 59 -39 l57 -7 -49 -13 c-27 -7 -66 -15 -85 -18
-20 -3 -37 -5 -38 -6 0 0 1 -9 4 -20 4 -16 14 -20 52 -20 69 0 85 -15 37 -35
-33 -14 -43 -15 -64 -3 -14 7 -32 23 -40 36 -14 21 -14 28 2 73 16 42 17 67
11 166 -4 64 -10 111 -14 105 -3 -5 -7 310 -8 701 -2 709 -1 726 30 727 4 0 7
-15 8 -32z m-257 -102 c8 -123 6 -1216 -3 -1281 -4 -27 -10 193 -14 490 -4
297 -10 618 -13 713 -5 147 -4 172 8 172 12 0 17 -21 22 -94z m2754 -128 c2
-87 0 -158 -5 -159 -4 -1 -29 -3 -55 -5 l-47 -4 -3 155 c-2 85 -2 159 0 164 2
5 27 9 55 8 l52 -2 3 -157z m70 0 l3 -158 -31 0 -30 0 0 161 0 160 28 -3 27
-3 3 -157z m-440 -15 c-3 -166 -3 -168 -25 -171 l-23 -3 0 170 0 171 25 0 25
0 -2 -167z m62 25 c0 -79 3 -153 7 -166 5 -21 3 -23 -23 -20 -16 2 -28 6 -26
11 1 4 2 77 2 162 0 148 1 155 20 155 19 0 20 -7 20 -142z m84 126 c3 -9 6
-83 6 -165 l0 -149 -35 0 -35 0 0 165 0 165 29 0 c17 0 31 -6 35 -16z m-466
-172 c2 -164 2 -172 -17 -172 -10 0 -22 7 -25 16 -8 22 -8 319 1 328 4 4 14 5
23 4 13 -3 15 -29 18 -176z m63 31 c2 -174 16 -149 18 30 l1 117 35 0 35 0 0
-170 0 -169 -70 0 -70 0 0 170 0 169 25 0 25 0 1 -147z m-573 -52 l-3 -176
-30 0 -30 0 -3 177 -2 177 22 3 c48 8 49 7 46 -181z m105 136 c4 -26 6 -105 5
-175 l-3 -127 -37 -3 -38 -3 0 174 c0 95 3 177 7 181 4 4 19 6 32 4 22 -3 27
-10 34 -51z m107 -121 c0 -153 -2 -175 -17 -180 -9 -4 -19 -2 -22 3 -3 5 -5
69 -4 143 2 221 1 208 23 208 19 0 20 -7 20 -174z m61 37 c1 -76 2 -154 3
-173 1 -29 -3 -36 -21 -38 l-23 -3 0 175 c0 169 1 176 20 176 19 0 20 -7 21
-137z m-541 112 c9 -11 10 -55 3 -187 -7 -153 -10 -173 -25 -176 -12 -2 -18 3
-19 15 -1 15 -2 16 -6 1 -3 -12 -19 -17 -61 -20 -31 -2 -61 -3 -67 -1 -6 2 -8
68 -4 186 3 100 6 183 7 185 1 1 27 2 57 2 l55 0 1 -102 c1 -57 5 -132 9 -168
5 -50 7 -24 8 108 2 139 5 172 16 172 7 0 19 -7 26 -15z m-216 -3 c3 -5 6 -89
7 -188 2 -176 1 -179 -20 -182 -22 -4 -23 0 -23 153 0 197 2 225 16 225 8 0
17 -4 20 -8z m1686 -692 l0 -200 -28 0 -29 0 4 188 c2 103 6 193 8 200 3 6 14
12 25 12 19 0 20 -6 20 -200z m71 33 c0 -93 3 -180 5 -195 4 -26 2 -28 -31
-28 l-35 0 0 188 c0 104 3 192 7 195 3 4 17 7 30 7 l23 0 1 -167z m-141 -37
c0 -161 -2 -195 -15 -199 -8 -4 -16 -3 -18 1 -2 4 -3 66 -2 137 3 241 4 255
20 255 13 0 15 -28 15 -194z m-156 107 c3 -42 6 -132 6 -200 l0 -123 -35 0
-35 0 0 200 0 200 29 0 c28 0 28 -1 35 -77z m-144 -132 c0 -206 -1 -211 -41
-211 -9 0 -3 367 6 398 3 6 11 12 20 12 13 0 15 -27 15 -199z m70 -1 l0 -200
-30 0 -30 0 0 200 0 200 30 0 30 0 0 -200z m-136 -17 c6 -189 6 -193 -14 -193
-19 0 -20 6 -20 193 0 182 4 218 21 200 3 -5 9 -95 13 -200z m-311 -20 c4
-108 4 -200 0 -204 -5 -4 -38 -10 -74 -14 l-66 -6 3 103 c2 57 5 148 7 203 3
94 5 100 25 103 19 3 22 -2 24 -35 1 -21 3 -47 4 -58 1 -11 3 -67 4 -125 2
-154 18 -95 19 71 1 91 5 139 13 147 31 31 35 12 41 -185z m59 -3 l3 -200 -22
0 c-24 0 -25 18 -25 360 0 36 2 40 22 40 18 0 19 -9 22 -200z m-217 -19 c0
-204 0 -204 -22 -207 -13 -2 -23 1 -23 7 0 6 -7 3 -16 -5 -8 -9 -20 -16 -25
-16 -5 0 -9 87 -9 210 0 180 2 210 15 210 10 0 15 -11 16 -32 1 -18 5 -94 8
-168 l8 -135 1 166 c1 91 5 169 9 173 4 4 14 5 23 4 13 -3 15 -31 15 -207z
m-275 -26 c3 -110 6 -201 5 -201 0 -1 -30 -4 -67 -8 l-68 -7 0 206 0 205 25 0
25 0 0 -195 c0 -128 4 -195 10 -195 7 0 10 66 10 193 0 105 -1 196 0 200 0 5
12 7 28 5 l27 -3 5 -200z m60 5 c0 -110 -2 -200 -4 -200 -2 0 -11 -3 -20 -6
-14 -5 -16 16 -16 200 0 199 1 206 20 206 19 0 20 -7 20 -200z m-220 123 c1
-32 3 -122 7 -200 5 -127 4 -143 -11 -149 -9 -4 -19 -4 -22 -2 -2 3 -7 96 -11
206 l-6 202 21 0 c20 0 22 -5 22 -57z m-152 -160 l2 -202 -57 -6 c-32 -3 -70
-9 -85 -11 l-28 -6 -1 94 c-2 215 -3 245 -1 284 l2 42 55 1 55 0 0 -119 c0
-73 4 -120 10 -120 6 0 10 49 10 126 0 114 2 125 18 122 15 -3 17 -22 20 -205z
m-198 50 c0 -76 3 -168 6 -204 5 -57 4 -67 -11 -73 -10 -3 -19 -5 -20 -4 -6 7
-14 406 -8 412 3 3 12 6 20 6 10 0 13 -30 13 -137z m-1323 -185 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2793 -298 l0 -220 -29 0 c-21 0 -30
6 -35 23 -3 12 -6 108 -6 214 0 202 0 201 48 202 l22 1 0 -220z m-80 -10 l0
-220 -25 0 c-20 0 -25 5 -26 28 -1 15 -5 77 -9 137 l-8 110 1 -130 c1 -72 -2
-136 -7 -143 -12 -21 -44 -14 -49 11 -4 19 -4 18 -6 -5 -1 -21 -6 -28 -20 -28
-19 0 -20 8 -18 223 l2 222 60 3 c32 2 61 5 64 7 2 3 13 5 23 5 17 0 18 -13
18 -220z m-380 -35 c3 -115 3 -213 0 -216 -3 -4 -15 -10 -27 -14 l-23 -6 0
226 0 226 23 -3 c22 -3 22 -5 27 -213z m-68 -6 c2 -116 -1 -217 -5 -225 -4 -8
-19 -14 -33 -14 l-24 0 0 225 1 225 29 0 29 0 3 -211z m-152 -29 c0 -125 -4
-221 -9 -225 -6 -3 -27 -8 -48 -11 l-39 -5 0 230 c0 175 3 231 12 231 8 0 14
-34 19 -107 9 -152 15 -160 11 -16 -3 117 -2 122 18 126 12 1 24 1 29 -1 4 -2
7 -102 7 -222z m72 33 c1 -209 1 -233 -1 -245 -2 -16 -50 -8 -53 9 -2 9 -2
110 0 225 l3 208 24 0 25 0 2 -197z m-284 -60 l-3 -227 -35 -7 c-19 -4 -63 -9
-97 -13 l-63 -6 3 229 c2 229 2 230 25 234 22 4 22 3 23 -112 1 -64 5 -161 9
-216 6 -85 7 -66 8 118 l2 217 30 0 30 0 1 -137 c1 -76 4 -176 8 -223 6 -66 8
-35 9 143 l2 227 25 0 25 0 -2 -227z m-216 22 c1 -102 2 -206 3 -232 0 -42 -3
-48 -22 -51 l-23 -3 0 235 c0 229 1 236 20 236 19 0 20 -7 22 -185z m-158 103
c3 -35 7 -139 8 -233 l3 -170 -92 -16 c-51 -9 -94 -13 -94 -10 -1 3 -2 110 -3
237 l-1 232 44 6 c88 12 80 30 84 -191 l2 -198 3 203 c2 190 3 202 21 202 16
0 20 -10 25 -62z m-600 -28 c12 -12 -9 -7 -49 11 -23 10 -77 32 -85 34 -29 7
-50 18 -50 25 0 10 168 -54 184 -70z m404 -173 c3 -233 2 -237 -18 -237 -20 0
-20 5 -20 241 0 224 1 240 18 237 15 -3 17 -24 20 -241z m-1314 161 c7 -12 7
-30 -2 -65 -7 -26 -14 -49 -16 -50 -7 -7 -166 72 -163 81 4 11 134 55 156 53
8 -1 19 -10 25 -19z m863 -55 c-8 -7 -147 37 -147 47 0 5 34 -3 76 -17 41 -14
73 -27 71 -30z m-66 -53 c38 -12 69 -26 69 -31 0 -6 -6 -9 -12 -7 -7 3 -38 13
-68 23 -51 16 -86 35 -66 35 5 0 40 -9 77 -20z m-204 -62 c-2 -13 -4 -3 -4 22
0 25 2 35 4 23 2 -13 2 -33 0 -45z m-460 23 c9 -13 9 -17 -3 -19 -18 -4 -64
24 -64 38 0 15 53 0 67 -19z m-397 -6 c0 -8 -4 -17 -10 -20 -6 -4 -10 5 -10
20 0 15 4 24 10 20 6 -3 10 -12 10 -20z m658 13 c16 -16 15 -57 -3 -107 -8
-22 -11 -41 -8 -41 4 0 4 -2 1 -5 -3 -3 -56 21 -117 53 l-112 59 58 7 c32 4
78 16 103 26 54 23 62 24 78 8z m450 -68 c59 -18 101 -35 94 -37 -15 -6 -101
15 -117 28 -11 8 -16 9 -44 9 -25 0 -51 26 -50 48 1 15 2 15 6 1 2 -10 41 -27
111 -49z m1899 -56 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-1952 -14 c3 -5 17 -10 31 -10 15 0 24 -6 24 -15 0 -20 -8 -19 -55 10 -23 14
-33 25 -23 25 9 0 20 -4 23 -10z m1510 -121 c-22 -4 -51 -8 -65 -7 -35 1 34
15 75 16 25 0 23 -2 -10 -9z m-1416 -3 c-7 -7 -195 -36 -234 -36 -44 0 -46 17
-2 24 68 12 244 20 236 12z m1289 -13 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4
22 1 32 -1 23 -4z m-105 -10 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m-50 -10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z
m-1081 -40 c21 -20 38 -32 38 -27 0 6 -9 19 -20 29 -32 29 -16 28 34 -2 53
-32 58 -43 19 -43 -16 0 -93 -11 -172 -25 -79 -14 -152 -25 -162 -25 -21 0
-33 32 -25 66 5 18 13 24 36 24 24 0 31 -6 41 -35 7 -19 17 -35 21 -35 11 0
10 5 -7 47 -19 44 -19 42 3 41 9 0 31 3 48 7 28 6 35 3 60 -29 37 -46 55 -47
20 -1 -34 45 -10 49 27 5 14 -16 29 -30 33 -30 4 0 -2 10 -13 23 -51 56 -36
64 19 10z m971 30 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z
m-623 -19 c0 -2 -9 -4 -21 -4 -11 0 -18 4 -14 10 5 8 35 3 35 -6z m1163 9 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-65 -10 c-10 -2 -28 -2
-40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m-69 -7 c-2 -2 -24 -6 -48 -9 -29 -4
-40 -2 -30 4 13 8 88 14 78 5z m-106 -13 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m85 -58 c-8 -8 -44 -14 -123 -21 -38 -3 -73 -8 -77 -11 -5
-2 -8 0 -8 6 0 5 30 12 68 16 37 3 76 7 87 9 37 6 58 6 53 1z"/>
<path d="M3887 8974 c-9 -9 -9 -236 -1 -325 5 -46 11 -66 20 -66 15 0 16 12
19 182 3 159 -11 236 -38 209z"/>
<path d="M3795 8693 c4 -82 9 -150 11 -152 10 -11 14 13 15 100 1 110 -10 199
-23 199 -6 0 -7 -54 -3 -147z"/>
<path d="M3720 8800 c-2 -25 -2 -74 -1 -217 1 -63 3 -72 17 -66 15 5 16 20 10
128 -7 121 -22 210 -26 155z"/>
<path d="M3639 8765 c-9 -17 2 -285 12 -279 10 6 10 137 0 229 -3 33 -8 56
-12 50z"/>
<path d="M3559 8705 c-5 -19 -5 -35 -1 -177 3 -102 22 -84 21 20 -1 101 -12
188 -20 157z"/>
<path d="M3471 8563 c-1 -76 3 -123 9 -123 12 0 12 183 0 220 -5 16 -8 -21 -9
-97z"/>
<path d="M3120 8590 c-155 -64 -319 -139 -335 -152 -13 -12 38 7 235 85 234
93 270 109 270 118 0 16 -24 9 -170 -51z"/>
<path d="M3375 8588 c-6 -9 -71 -39 -145 -67 -169 -62 -341 -129 -446 -174
-88 -38 -329 -171 -340 -188 -7 -11 46 14 123 59 26 15 95 47 153 70 130 51
639 242 645 242 3 0 5 -16 5 -35 0 -43 3 -41 -277 -140 -329 -117 -672 -259
-659 -273 5 -5 120 35 501 174 132 47 290 104 350 125 61 21 111 44 112 51 2
7 0 49 -5 93 -5 60 -10 76 -17 63z"/>
<path d="M2466 8235 c-11 -8 -15 -15 -10 -15 6 0 19 7 30 15 10 8 15 14 9 14
-5 0 -19 -6 -29 -14z"/>
<path d="M4291 6674 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4294 6565 c0 -49 1 -71 3 -48 2 23 2 64 0 90 -2 26 -3 8 -3 -42z"/>
<path d="M3925 7956 c-10 -22 2 -503 14 -566 6 -30 9 41 9 215 0 269 -7 386
-23 351z"/>
<path d="M2708 7705 c-17 -49 -28 -147 -28 -239 0 -186 23 -741 32 -751 4 -5
8 31 8 80 0 50 3 227 6 395 5 292 -4 557 -18 515z"/>
<path d="M3901 7134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3491 6916 c2 -2 83 -7 179 -10 100 -3 162 -1 145 3 -30 9 -332 15
-324 7z"/>
<path d="M3335 6830 c8 -13 465 -13 485 0 10 6 -80 10 -238 10 -171 0 -251 -3
-247 -10z"/>
<path d="M3952 6810 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3340 6741 c0 -19 43 -22 274 -15 133 4 230 11 216 15 -37 10 -490
11 -490 0z"/>
<path d="M4293 7080 c0 -47 2 -66 4 -42 2 23 2 61 0 85 -2 23 -4 4 -4 -43z"/>
<path d="M5232 6435 c0 -16 4 -43 8 -60 l8 -30 0 35 c0 19 -4 46 -8 60 -8 24
-8 24 -8 -5z"/>
<path d="M5811 6434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2432 6478 c5 -15 353 -24 428 -11 40 6 33 8 -60 14 -193 12 -372 10
-368 -3z"/>
<path d="M3238 6443 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3461 6094 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3428 4413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4262 5980 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4252 5205 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3665 4282 c29 -57 35 -65 35 -51 0 9 -9 27 -21 40 -11 13 -18 18
-14 11z"/>
<path d="M3634 4264 c5 -10 11 -27 13 -36 3 -10 9 -18 14 -18 11 0 -10 58 -24
67 -7 4 -8 0 -3 -13z"/>
<path d="M3545 4223 c8 -32 13 -39 21 -30 10 9 -5 57 -17 57 -7 0 -9 -10 -4
-27z"/>
<path d="M6010 9265 c-13 -16 -6 -51 13 -58 17 -6 57 10 57 23 0 42 -45 65
-70 35z"/>
<path d="M7235 9238 c4 -13 24 -57 45 -99 51 -99 56 -157 26 -270 -6 -21 -3
-27 20 -38 24 -11 30 -10 41 5 18 24 26 96 21 175 -8 109 -87 249 -140 249
-15 0 -18 -5 -13 -22z"/>
<path d="M6190 9215 c-19 -23 -8 -50 22 -53 19 -2 28 2 33 18 12 39 -29 66
-55 35z"/>
<path d="M6982 9158 c-17 -17 -15 -35 8 -58 26 -26 36 -25 55 4 13 19 14 29 5
45 -12 23 -49 28 -68 9z"/>
<path d="M7150 9155 c-6 -8 -10 -26 -8 -42 4 -36 41 -44 58 -14 19 37 -25 86
-50 56z"/>
<path d="M6360 9135 c-18 -22 -1 -50 31 -50 20 0 24 5 24 30 0 23 -5 31 -21
33 -11 2 -27 -4 -34 -13z"/>
<path d="M6649 9073 c-21 -74 -15 -164 16 -231 25 -53 69 -112 86 -112 23 0
18 46 -13 107 -27 56 -33 79 -33 133 1 42 7 76 18 97 16 31 16 33 -2 43 -41
21 -58 13 -72 -37z"/>
<path d="M7080 8964 c-14 -14 -38 -32 -53 -40 -26 -14 -31 -14 -53 1 -13 8
-24 20 -24 25 0 5 -9 14 -21 20 -16 9 -26 8 -45 -5 -28 -18 -25 -29 29 -87 30
-33 41 -38 79 -38 34 0 55 7 86 30 54 39 79 77 65 99 -15 24 -35 23 -63 -5z"/>
<path d="M7217 8743 c-27 -18 -68 -38 -90 -44 -77 -20 -89 -28 -85 -52 6 -45
121 -27 220 35 54 34 63 50 40 71 -30 25 -36 24 -85 -10z"/>
<path d="M6830 8730 c-16 -31 -12 -37 42 -64 68 -34 96 -34 104 0 8 33 1 39
-74 63 -60 18 -63 18 -72 1z"/>
<path d="M1972 8108 c-7 -7 -12 -24 -12 -38 0 -34 17 -50 52 -50 18 0 33 8 44
25 15 23 15 27 0 50 -18 27 -63 34 -84 13z"/>
<path d="M2204 7725 c-10 -25 4 -47 28 -43 17 2 24 11 26 31 3 23 0 27 -23 27
-14 0 -28 -7 -31 -15z"/>
<path d="M2106 7538 c-21 -30 -4 -60 32 -56 34 4 44 45 16 65 -24 18 -30 17
-48 -9z"/>
<path d="M1434 7477 c-192 -127 -275 -286 -274 -527 0 -138 21 -225 83 -344
39 -76 126 -202 189 -274 27 -31 28 -38 28 -130 0 -53 7 -119 14 -147 18 -62
18 -61 16 116 -1 164 -4 156 92 291 132 186 181 331 180 535 0 171 -26 250
-129 385 -42 55 -102 109 -135 121 -10 3 -36 -7 -64 -26z m14 -197 c-16 -92
-21 -165 -22 -362 0 -141 -5 -237 -9 -225 -5 12 -15 72 -23 132 -18 125 -12
343 11 441 7 33 12 60 10 62 -5 5 -51 -75 -70 -121 -8 -21 -25 -77 -36 -125
-37 -154 -16 -310 70 -517 45 -109 28 -100 -34 18 -63 118 -86 199 -92 324
-11 210 42 360 160 454 28 21 51 38 52 37 2 -2 -6 -55 -17 -118z m121 57 c22
-29 52 -79 66 -110 23 -54 28 -73 40 -175 10 -87 -32 -297 -82 -412 -14 -33
-27 -59 -29 -57 -2 1 7 41 20 87 34 126 49 250 43 354 -7 102 -14 131 -56 231
l-29 69 1 -179 c2 -173 -7 -340 -22 -435 -5 -28 -8 29 -9 155 -1 110 -4 234
-7 275 -13 195 -14 234 -9 241 12 20 36 6 73 -44z"/>
<path d="M6916 7365 c-3 -9 0 -15 9 -15 16 0 20 16 6 24 -5 3 -11 -1 -15 -9z"/>
<path d="M2024 7336 c-11 -28 4 -48 33 -44 22 2 28 8 28 28 0 20 -6 26 -28 28
-18 2 -29 -2 -33 -12z"/>
<path d="M7472 7325 c-75 -21 -159 -64 -193 -99 -44 -45 -80 -127 -86 -194 -6
-70 13 -144 63 -244 19 -37 34 -77 34 -88 0 -35 -42 -67 -98 -75 -55 -7 -119
-46 -148 -90 -10 -15 -19 -59 -23 -112 -4 -49 -15 -101 -23 -118 -22 -42 -138
-355 -188 -510 -61 -184 -74 -211 -103 -219 -34 -8 -84 -68 -97 -116 -14 -52
0 -106 47 -183 19 -32 36 -72 37 -89 1 -30 -2 -32 -69 -53 -164 -51 -221 -102
-255 -228 -30 -112 14 -276 101 -372 47 -50 184 -152 259 -190 l35 -18 -115
-23 c-202 -40 -380 -111 -406 -162 -6 -12 -38 -40 -72 -62 -76 -51 -110 -104
-118 -186 -10 -99 30 -155 57 -79 7 19 15 35 19 35 3 0 25 -12 50 -26 111 -65
369 -133 635 -169 22 -3 92 -14 155 -24 107 -18 111 -19 55 -20 -65 -1 -323
28 -529 59 -150 23 -159 24 -126 6 62 -33 318 -80 540 -100 91 -9 188 -17 215
-20 28 -3 62 -5 78 -5 24 -1 27 -5 28 -38 0 -21 4 -47 8 -58 6 -15 10 -5 15
38 7 51 10 57 30 57 20 0 24 -7 30 -46 3 -26 6 -72 6 -103 0 -73 10 -132 21
-125 5 3 9 66 9 140 0 124 1 134 18 134 17 0 20 -13 25 -127 4 -71 7 -232 7
-360 0 -226 10 -296 27 -196 4 27 8 191 8 366 l-1 317 23 0 23 0 1 -197 c0
-267 9 -486 18 -496 15 -15 22 109 21 401 0 286 0 292 20 292 20 0 20 -6 20
-270 0 -257 11 -413 32 -435 6 -6 7 1 2 19 -5 21 -4 27 5 22 9 -6 10 31 7 146
-3 84 -6 234 -7 333 -2 177 -1 180 20 183 21 3 21 3 21 -250 0 -276 14 -455
37 -455 9 0 13 14 13 41 2 180 -1 442 -6 544 -7 120 -7 122 15 122 l21 0 0
-297 c0 -164 3 -323 7 -353 l8 -55 6 40 6 40 2 -37 c0 -21 6 -38 12 -38 7 0
10 14 7 35 -3 25 0 35 10 35 12 0 13 27 8 158 -4 86 -8 227 -9 312 -2 140 0
155 16 158 16 3 17 -18 17 -337 0 -216 4 -342 10 -346 6 -4 10 7 10 24 0 24 4
31 20 31 11 0 19 3 19 8 -1 4 -1 148 0 320 1 246 4 313 14 309 13 -4 14 -316
1 -559 l-6 -118 98 -1 c55 -1 109 -1 122 -2 12 0 22 -4 22 -8 0 -8 -24 -12
-72 -12 -10 0 -16 -3 -13 -7 4 -7 -147 -20 -237 -20 -27 0 -48 -4 -48 -8 0 -5
78 -7 173 -4 94 2 170 1 167 -2 -7 -6 -219 -23 -395 -30 -332 -15 -395 -19
-395 -27 0 -15 73 -29 230 -45 317 -32 810 -4 1140 64 129 27 140 28 220 16
84 -13 177 -18 169 -10 -2 3 -22 7 -44 10 -22 2 -49 7 -60 10 -11 2 -42 5 -70
6 l-50 1 43 14 c38 12 162 12 181 0 4 -2 63 -6 132 -9 134 -6 121 3 -36 24
-100 14 -163 28 -129 29 11 1 24 5 30 11 6 6 34 6 79 -1 87 -12 133 -16 260
-23 l100 -5 -85 14 c-250 39 -300 49 -300 57 0 10 -16 9 220 10 l195 0 -135
15 c-166 17 -183 21 -177 36 7 19 245 5 332 -20 11 -3 17 -2 13 2 -9 9 -258
54 -300 55 -23 0 -28 4 -28 25 0 21 4 25 23 21 12 -3 58 -8 102 -11 44 -3 91
-9 104 -12 15 -4 22 -2 18 4 -4 6 -28 14 -54 18 -27 5 -52 9 -58 10 -5 1 -36
5 -67 9 -35 5 -58 12 -58 20 0 9 18 10 73 5 93 -10 132 -11 124 -3 -3 4 -17 8
-29 9 -13 2 -58 8 -100 15 l-77 13 -22 54 c-15 37 -37 67 -70 95 l-48 40 -3
282 c-3 265 -4 283 -25 321 -24 46 -104 125 -151 149 -17 9 -40 29 -51 45 -57
80 -273 153 -616 208 l-50 8 3 52 c5 67 -18 135 -67 205 -36 51 -37 57 -24 81
8 15 25 29 41 33 48 10 110 35 143 57 65 44 86 117 65 224 -7 34 -6 44 7 48 8
4 120 -28 248 -70 l234 -76 27 -44 c43 -72 93 -98 201 -105 51 -4 105 -13 122
-22 58 -30 274 -100 290 -95 8 4 15 14 15 24 1 10 17 59 36 108 46 118 51 134
33 118 -19 -19 -32 -16 -24 5 13 35 -14 -4 -44 -63 -17 -33 -30 -57 -31 -53 0
10 29 88 41 111 6 11 7 22 3 25 -4 4 -9 2 -11 -4 -2 -6 -11 -20 -19 -30 -15
-19 -15 -18 -9 1 4 11 9 26 12 33 3 6 -2 12 -10 12 -8 0 -20 -12 -26 -28 -6
-15 -23 -41 -37 -57 l-26 -30 21 36 c25 44 26 50 5 42 -12 -4 -11 1 5 28 24
40 29 69 8 47 -7 -7 -30 -38 -50 -68 -47 -69 -48 -59 -2 17 19 31 32 59 29 62
-3 3 -10 -2 -15 -12 -14 -25 -32 -21 -24 6 6 21 6 21 -14 -3 -18 -22 -21 -23
-21 -6 0 20 0 20 -16 0 -15 -19 -16 -19 -9 1 4 11 9 35 12 53 3 18 12 32 19
32 8 0 55 -13 106 -30 110 -35 260 -71 267 -65 4 5 -85 48 -273 132 -106 47
-110 49 -145 107 -20 33 -53 73 -74 90 -37 29 -44 31 -125 32 -76 1 -101 6
-197 41 -137 51 -312 121 -341 136 l-22 12 43 113 c24 63 50 122 57 132 7 9
27 36 45 59 40 53 59 133 44 188 -6 21 -31 63 -56 92 -65 76 -61 85 60 147 55
28 119 69 141 91 80 77 110 232 70 353 -87 257 -514 533 -986 636 -147 33
-395 42 -478 19z m416 -86 c438 -90 823 -315 932 -545 75 -158 37 -298 -97
-362 -30 -14 -35 -14 -43 -2 -7 11 0 20 26 36 50 31 83 64 105 106 25 50 25
122 -1 158 -38 54 -250 198 -271 184 -5 -3 -9 -1 -9 4 0 5 -19 18 -42 29 -24
10 -90 43 -148 73 -95 48 -260 115 -260 104 0 -2 29 -17 65 -33 72 -33 78 -46
44 -96 -11 -16 -32 -52 -46 -80 -14 -27 -27 -51 -28 -53 -3 -3 -42 14 -53 23
-1 1 11 46 28 101 17 57 26 102 21 107 -11 11 -64 -79 -87 -145 -14 -42 -18
-47 -34 -38 -10 6 -28 10 -40 10 -30 0 -35 23 -17 93 9 34 18 74 20 90 7 47
-22 11 -64 -78 -46 -97 -59 -84 -35 33 19 92 19 92 7 92 -12 0 -59 -96 -71
-147 -9 -34 -14 -40 -30 -36 -11 3 -22 9 -25 13 -3 4 0 47 6 95 6 47 8 89 6
92 -10 10 -58 -78 -69 -128 -11 -45 -14 -50 -37 -47 -23 3 -26 8 -29 46 -2 23
4 67 12 96 20 67 20 72 0 55 -24 -20 -61 -99 -69 -148 -5 -30 -10 -39 -16 -30
-11 17 -1 99 17 153 17 46 17 46 4 46 -18 0 -61 -89 -72 -149 -15 -79 -29 -59
-22 31 8 104 -33 80 -46 -27 -6 -47 -7 -48 -14 -20 -4 17 -14 46 -22 65 l-15
35 5 -60 c4 -41 3 -52 -3 -35 -5 14 -16 41 -25 60 l-17 35 6 -55 c4 -30 16
-79 28 -110 l22 -55 140 -6 c199 -9 319 -38 525 -126 125 -54 236 -115 270
-149 21 -20 3 -15 -95 29 -66 30 -172 72 -235 94 -130 46 -335 97 -350 88 -6
-3 -10 -1 -10 5 0 8 -13 10 -42 5 -32 -4 -39 -3 -28 4 26 19 -51 0 -86 -21
-50 -30 -73 -17 -121 66 -76 130 -90 204 -53 281 43 90 88 120 179 117 291 -9
538 -78 846 -237 100 -51 154 -84 363 -223 62 -41 115 -73 117 -70 2 2 -9 27
-26 56 -52 92 -227 219 -410 298 -281 121 -571 198 -761 203 -47 1 -65 15 -30
24 36 9 219 -5 310 -24z m382 -316 c14 -9 26 -17 28 -18 1 -1 -18 -41 -44 -89
-33 -63 -51 -86 -63 -83 -19 4 -19 2 14 90 14 37 25 79 25 92 0 29 7 31 40 8z
m100 -62 c0 -11 -61 -123 -78 -143 -30 -35 -31 -17 -2 45 17 34 33 77 36 94 5
28 9 31 25 22 10 -5 19 -13 19 -18z m74 -39 c6 -9 -69 -148 -85 -157 -23 -15
-18 6 21 84 38 76 52 92 64 73z m75 -49 c-16 -43 -71 -135 -76 -129 -5 5 75
156 82 156 3 0 0 -12 -6 -27z m57 -70 c-20 -38 -45 -70 -46 -58 0 11 48 85 56
85 2 0 -2 -12 -10 -27z m-1047 -37 c138 -37 330 -112 491 -192 135 -68 237
-131 226 -141 -2 -2 -31 9 -64 26 -167 84 -444 177 -665 222 -86 18 -157 37
-157 42 0 19 42 67 60 67 10 0 59 -11 109 -24z m1083 -38 c-7 -7 -12 -8 -12
-2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m56 -69 c-34 -43 -49 -52 -26 -16 8
12 20 32 27 45 7 12 16 20 21 17 6 -4 -4 -24 -22 -46z m80 -36 c-26 -40 -99
-115 -105 -109 -2 2 12 21 32 42 20 22 48 56 62 77 14 20 28 35 31 32 3 -2 -6
-22 -20 -42z m44 -30 c-25 -47 -57 -78 -97 -92 -28 -10 -29 -10 -10 4 48 36
96 91 107 123 8 20 14 28 16 19 2 -8 -6 -33 -16 -54z m-1425 36 c94 -13 263
-51 353 -80 201 -66 525 -211 517 -232 -6 -17 -22 -13 -122 31 -108 48 -386
144 -499 173 -204 53 -456 86 -456 61 0 -5 33 -12 73 -16 193 -18 646 -162
852 -270 92 -49 63 -41 -128 33 -268 105 -548 177 -705 183 -75 2 -102 -9
-102 -43 0 -26 21 -35 156 -65 381 -85 774 -239 1044 -409 131 -82 146 -87
177 -50 l24 27 -24 32 c-21 27 -53 54 -184 155 -47 36 59 -19 147 -78 41 -27
78 -47 83 -44 12 7 8 43 -9 71 -8 15 -65 63 -125 106 -60 44 -109 81 -109 84
0 5 105 -55 160 -91 107 -72 140 -112 140 -169 0 -30 -6 -42 -35 -65 -19 -15
-35 -33 -35 -39 0 -6 -67 -181 -150 -388 -82 -207 -150 -380 -150 -384 0 -4
-7 -18 -16 -30 l-15 -22 -34 36 c-32 33 -33 38 -20 58 26 40 177 436 227 596
23 72 34 91 43 69 3 -8 -46 -152 -109 -320 -102 -273 -139 -384 -123 -369 7 6
122 297 203 515 l64 170 -36 30 c-21 17 -58 42 -83 57 -44 26 -52 40 -10 18
10 -6 19 -8 19 -5 0 8 -144 78 -276 133 -148 63 -445 163 -585 197 -86 21
-314 55 -368 55 -36 0 3 -10 94 -25 150 -24 305 -61 450 -106 135 -43 323
-115 339 -130 5 -5 -18 -67 -57 -151 -36 -79 -100 -224 -142 -323 -80 -188
-89 -205 -97 -183 -3 7 35 114 84 238 88 221 148 385 148 402 0 5 -5 6 -10 3
-17 -10 -80 -143 -179 -375 -100 -235 -106 -248 -117 -237 -11 10 21 101 132
380 52 131 93 241 91 243 -13 13 -237 -460 -272 -573 -6 -21 -34 -31 -47 -17
-6 5 18 75 67 194 43 106 125 331 125 343 -1 26 -43 -59 -141 -280 -78 -178
-106 -233 -120 -233 -10 0 -20 4 -23 9 -3 5 24 80 60 167 77 185 135 337 130
341 -1 2 -54 -107 -117 -242 -100 -215 -116 -245 -136 -245 -13 0 -23 5 -23
11 0 6 23 64 51 128 58 132 111 272 106 278 -2 2 -44 -86 -92 -194 -49 -109
-90 -200 -92 -202 -1 -2 -22 2 -45 8 -171 46 -241 61 -352 77 -69 9 -126 20
-126 24 0 31 214 615 262 717 30 62 34 81 30 123 -10 107 54 135 245 109z
m1098 -87 c-5 -6 -65 40 -65 50 0 5 16 -4 35 -19 19 -15 32 -29 30 -31z m145
-143 c0 -5 -7 -9 -15 -9 -17 0 -65 67 -65 90 1 16 80 -65 80 -81z m-150 -26
c0 -14 -19 -38 -25 -32 -8 8 5 39 16 39 5 0 9 -3 9 -7z m73 -87 c-9 -16 -11
-16 -32 2 -18 14 -22 24 -16 41 l7 23 25 -25 c19 -18 23 -29 16 -41z m-333
-52 c0 -2 -9 0 -20 6 -11 6 -20 13 -20 16 0 2 9 0 20 -6 11 -6 20 -13 20 -16z
m-100 -170 c0 -13 -244 -582 -275 -640 -7 -14 -15 -22 -17 -17 -9 15 103 284
114 277 7 -5 8 -2 4 5 -10 16 13 73 27 64 6 -3 7 -1 3 6 -4 7 17 74 48 150 31
76 56 143 56 149 0 7 9 12 20 12 11 0 20 -3 20 -6z m70 -28 c0 -15 -241 -577
-261 -608 -11 -18 -19 -26 -16 -18 22 65 209 520 247 603 11 22 30 37 30 23z
m22 -172 c-27 -60 -86 -193 -132 -297 -46 -103 -85 -186 -87 -184 -4 4 61 169
127 322 29 66 63 147 77 180 52 124 56 132 60 109 2 -11 -18 -69 -45 -130z
m132 -200 c-36 -98 -89 -245 -116 -326 -32 -95 -55 -148 -64 -148 -8 0 -14 3
-14 7 0 3 44 119 99 257 54 138 111 284 126 324 15 40 29 71 31 69 2 -2 -26
-84 -62 -183z m-1433 -95 c242 -40 470 -110 665 -203 127 -61 161 -90 53 -45
-40 16 -95 36 -123 44 -89 27 -31 -7 102 -60 67 -26 121 -50 118 -52 -2 -3
-53 13 -113 35 -312 114 -546 173 -741 189 -72 5 -84 4 -96 -12 -12 -16 -14
-16 -34 10 -27 35 -28 69 -2 95 25 25 20 25 171 -1z m1721 -98 c57 -21 157
-58 223 -81 127 -45 204 -82 231 -111 17 -19 21 -91 8 -131 l-8 -23 -7 25 c-4
14 -7 42 -8 63 -1 45 -30 85 -32 45 -1 -19 -3 -18 -9 7 -7 28 -8 26 -9 -15 -2
-54 -21 -94 -21 -44 0 43 -11 94 -20 94 -4 0 -13 -18 -20 -40 -16 -50 -32 -53
-18 -4 14 53 -7 70 -27 21 -8 -20 -17 -37 -19 -37 -2 0 -2 21 1 48 3 40 2 44
-9 29 -37 -53 -39 -54 -32 -14 5 31 3 37 -11 37 -9 0 -19 -9 -22 -20 -3 -11
-10 -20 -15 -20 -6 0 -8 9 -5 19 2 10 1 25 -4 32 -9 15 -9 15 -31 -31 l-17
-35 6 48 c7 54 -8 63 -27 17 -7 -17 -17 -33 -22 -36 -6 -4 -5 3 1 14 6 11 11
33 11 49 -1 27 -1 27 -16 8 -14 -19 -15 -19 -21 3 -5 22 -7 21 -24 -15 -19
-41 -26 -32 -9 13 8 21 8 31 -4 42 -12 13 -17 10 -35 -24 -20 -37 -21 -37 -15
-9 3 17 7 36 10 43 5 17 -22 15 -41 -4 -15 -15 -16 -14 -10 8 10 39 -21 45
-40 8 -9 -17 -18 -30 -21 -30 -5 0 5 69 13 83 7 12 18 9 125 -32z m-1772 -150
c0 -29 -12 -43 -37 -47 -16 -3 -17 30 -3 57 16 30 40 24 40 -10z m2316 4 c74
-56 95 -172 48 -266 -41 -82 -122 -131 -180 -108 l-29 11 30 14 c17 7 35 13
41 14 22 0 83 79 90 115 12 68 0 138 -33 188 -29 41 -29 51 -4 56 2 1 19 -10
37 -24z m-2242 2 c26 -19 13 -57 -20 -57 -29 0 -40 21 -24 49 13 25 20 26 44
8z m128 -23 c5 -3 8 -13 9 -22 0 -11 3 -12 6 -4 6 16 36 15 81 -4 20 -9 48
-18 60 -20 13 -3 38 -11 55 -19 32 -14 32 -14 -18 -8 -27 3 -52 9 -55 12 -3 3
-21 6 -40 7 -59 1 -182 22 -193 33 -20 20 13 43 52 36 20 -4 39 -9 43 -11z
m-19 -154 c59 -11 146 -32 193 -46 255 -74 660 -272 773 -378 48 -46 111 -168
111 -217 0 -45 -59 -177 -97 -220 -32 -35 -97 -68 -110 -56 -3 3 13 17 34 30
66 42 108 102 127 180 4 16 2 27 -4 27 -5 0 -10 -6 -10 -13 0 -25 -63 -109
-103 -138 -62 -45 -64 -34 -4 21 38 34 62 68 78 105 19 46 25 53 37 42 13 -10
14 -9 4 9 -26 49 -204 175 -274 195 -37 10 -47 22 -28 34 9 6 186 -80 268
-130 42 -26 42 -26 42 -4 0 104 -270 259 -691 398 -239 79 -450 123 -661 138
-72 5 -86 8 -77 20 6 7 26 17 43 23 46 14 226 4 349 -20z m1023 -71 c131 -78
156 -89 173 -79 26 13 26 38 1 70 -22 28 -25 40 -10 40 16 0 60 -73 60 -102 0
-26 -39 -68 -65 -68 -6 0 -30 17 -51 38 -22 20 -93 68 -157 106 -101 60 -135
86 -111 86 4 0 75 -41 160 -91z m-1166 -20 c163 -31 338 -84 488 -148 173 -74
263 -117 262 -125 0 -3 -3 -6 -6 -5 -10 1 -51 -65 -94 -148 -37 -75 -56 -89
-25 -21 69 156 86 206 66 194 -5 -4 -11 -1 -13 6 -10 28 -42 -7 -78 -84 -21
-46 -42 -95 -45 -110 -4 -16 -12 -28 -17 -28 -14 0 0 75 28 146 37 94 30 134
-17 90 -13 -12 -37 -56 -53 -97 -45 -117 -47 -120 -41 -54 4 33 15 86 26 118
11 32 17 63 13 68 -20 34 -98 -119 -99 -193 0 -27 -1 -48 -2 -48 -1 0 -9 7
-17 16 -22 21 -20 49 9 138 14 43 22 82 19 88 -4 7 -2 8 4 4 7 -4 12 -2 12 3
0 6 -7 11 -15 11 -34 0 -123 -169 -111 -213 4 -15 2 -16 -11 -5 -15 12 -15 71
3 203 l5 40 -30 -32 c-28 -30 -60 -112 -64 -163 -1 -20 -1 -20 -21 -1 -18 19
-19 26 -9 113 6 51 10 94 8 96 -2 2 -12 -7 -24 -19 -27 -29 -54 -113 -49 -150
5 -32 -11 -39 -33 -13 -15 18 -14 111 1 163 5 19 8 37 5 39 -17 18 -68 -81
-80 -156 -4 -23 -11 -40 -16 -36 -17 10 -9 115 15 192 12 37 -10 25 -43 -25
-25 -38 -31 -57 -31 -101 0 -64 -9 -63 -21 3 -8 42 -1 107 16 148 5 12 2 17
-9 17 -9 0 -16 -5 -16 -12 0 -6 -8 -20 -17 -31 -14 -15 -18 -36 -18 -94 1 -62
-1 -72 -12 -57 -19 26 -26 97 -15 150 11 51 10 60 -6 50 -17 -11 -35 -96 -30
-148 l5 -53 -18 40 c-12 26 -19 64 -19 110 0 49 -3 65 -10 55 -5 -8 -10 -26
-11 -40 -1 -16 -4 -11 -8 15 l-7 40 -8 -55 c-7 -55 -5 -101 9 -152 4 -18 3
-28 -4 -28 -14 0 -28 59 -36 153 -4 42 -11 77 -15 77 -11 0 -17 -74 -10 -137
5 -47 4 -53 -7 -37 -7 10 -13 23 -14 28 0 6 -7 38 -15 71 l-14 60 3 -75 c3
-60 10 -88 35 -137 35 -70 26 -84 -14 -24 -54 82 -68 196 -36 281 17 43 19 45
67 51 73 10 215 0 335 -22z m923 -59 c-7 -28 -13 -25 -13 6 0 14 4 23 9 20 5
-3 7 -15 4 -26z m1507 -11 c-7 -11 -14 -18 -17 -15 -3 3 0 12 7 21 18 21 23
19 10 -6z m-1449 -32 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7
-28z m69 4 c0 -5 -5 -22 -10 -37 -8 -20 -14 -24 -22 -16 -9 9 -9 18 0 37 11
24 32 35 32 16z m85 -81 c-10 -40 -23 -47 -47 -23 -20 21 -20 23 -4 47 14 23
19 24 37 13 15 -9 18 -18 14 -37z m-323 -124 c2 -10 -105 -206 -110 -202 -2 2
17 45 42 95 25 50 46 98 46 107 0 15 21 16 22 0z m43 -26 c-3 -5 -10 -10 -16
-10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-792 -85 c152 -38 235
-67 242 -83 3 -8 4 -16 3 -18 -2 -1 -63 17 -136 42 -74 24 -150 46 -170 50
-20 4 -41 10 -47 14 -5 4 -19 10 -30 13 -15 3 -13 5 8 6 16 0 74 -10 130 -24z
m801 -45 c-17 -33 -23 -38 -20 -16 2 13 24 46 31 46 2 0 -3 -13 -11 -30z
m-484 -75 c0 -21 -16 -19 -40 5 -30 30 -24 43 10 25 17 -9 30 -22 30 -30z
m540 0 c-31 -45 -55 -65 -28 -22 8 12 21 32 29 45 8 12 17 22 22 22 4 0 -6
-20 -23 -45z m-94 3 c-18 -26 -22 -17 -5 14 6 11 13 17 16 15 3 -3 -2 -16 -11
-29z m-386 -29 c0 -17 -3 -18 -20 -9 -11 6 -20 20 -20 31 0 17 3 18 20 9 11
-6 20 -20 20 -31z m565 -4 c-10 -19 -22 -35 -27 -35 -5 0 1 18 13 40 27 48 41
43 14 -5z m-505 -17 c0 -26 -24 -18 -28 10 -3 16 1 20 12 16 9 -3 16 -15 16
-26z m599 -10 c-18 -36 -81 -110 -87 -103 -3 3 10 22 30 43 20 20 41 47 47 60
7 12 14 22 17 22 2 0 -1 -10 -7 -22z m-541 -17 c3 -16 -1 -22 -10 -19 -7 3
-15 15 -16 27 -3 16 1 22 10 19 7 -3 15 -15 16 -27z m59 -12 c6 -6 9 -17 6
-25 -7 -18 -33 -5 -33 17 0 21 11 24 27 8z m73 -51 c0 -26 -24 -18 -28 10 -3
16 1 20 12 16 9 -3 16 -15 16 -26z m65 -28 c0 -22 -29 -18 -33 3 -3 14 1 18
15 15 10 -2 18 -10 18 -18z m731 -29 c222 -45 394 -111 394 -151 0 -22 -49
-50 -139 -81 -67 -23 -56 -10 13 16 27 10 58 27 70 39 l21 21 -24 3 c-13 2
-49 -3 -80 -12 -161 -45 -522 -105 -501 -84 3 3 66 15 140 27 74 12 187 35
250 51 l115 28 -155 2 c-85 1 -198 6 -250 10 l-95 7 93 1 c59 1 91 6 87 12 -3
6 -26 10 -49 10 -24 0 -59 3 -77 7 l-34 7 50 13 50 12 -66 -2 -66 -2 26 53
c22 46 29 52 51 48 14 -2 93 -18 176 -35z m-1351 -30 c55 -24 120 -53 145 -64
l45 -22 -140 -10 -140 -10 105 -13 c58 -6 143 -15 190 -19 l85 -8 -70 -6 -70
-5 75 -3 c41 -2 100 -6 130 -11 55 -7 55 -7 -30 -10 l-85 -3 80 -8 c44 -4 166
-11 270 -16 l190 -7 -180 -8 c-226 -9 -455 -2 -763 23 -73 6 -135 9 -138 6 -4
-4 111 -26 221 -42 222 -33 811 -62 1030 -50 72 3 202 15 290 26 88 10 169 19
180 18 40 0 -9 -16 -70 -24 -33 -3 -71 -8 -85 -10 -14 -1 -43 -7 -65 -13 -22
-5 -47 -10 -55 -10 -8 0 -58 -4 -110 -8 -123 -11 -782 -11 -925 -1 -149 11
-341 35 -452 58 -154 31 -313 101 -321 141 -9 48 379 164 518 154 25 -2 90
-22 145 -45z m704 13 c4 3 7 -4 7 -15 0 -19 -1 -19 -13 -3 -7 10 -13 23 -12
28 0 6 3 5 6 -2 2 -6 8 -10 12 -8z m52 -41 c13 -16 12 -17 -3 -4 -10 7 -18 15
-18 17 0 8 8 3 21 -13z m342 -70 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19
-2 13 -5z m-1829 -102 c87 -81 434 -175 791 -215 28 -3 70 -8 95 -11 117 -13
339 -25 476 -25 142 0 443 23 478 36 9 3 16 1 16 -5 0 -6 -15 -11 -34 -11 -19
0 -38 -5 -42 -12 -16 -26 348 11 551 57 100 23 244 80 231 92 -2 3 -28 -2 -58
-11 -164 -47 -692 -138 -620 -107 12 6 50 13 85 16 34 4 105 16 157 27 52 11
114 24 136 29 111 22 205 64 270 122 36 31 51 29 87 -11 40 -44 1 -81 -129
-124 -87 -29 -313 -84 -384 -93 -25 -3 -97 -15 -160 -25 -175 -29 -172 -29
-375 -41 -401 -24 -995 9 -1290 72 -164 35 -348 115 -375 162 -14 25 -13 28
20 62 39 40 46 42 74 16z m1813 -27 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m396 -11 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m512 -450 c6 -167 5 -223 -3 -223 -8 0 -12 23 -12 68 -1 83 -9 159 -25 237
-13 59 -13 58 -15 -95 -1 -85 -5 -180 -10 -210 l-7 -55 -2 45 c-1 25 -4 122
-7 215 -3 94 -7 172 -9 174 -2 2 -11 1 -20 -2 -13 -6 -15 -37 -15 -219 0 -141
-4 -208 -10 -198 -5 8 -10 97 -10 197 0 100 -2 188 -6 196 -4 12 -13 12 -62 1
-92 -22 -94 -15 -3 12 87 25 163 70 182 106 10 19 9 20 -7 14 -85 -34 -267
-82 -359 -96 -38 -5 -81 -13 -95 -16 -16 -3 -21 -2 -15 4 6 5 43 14 83 21 122
19 254 67 334 121 l73 49 6 -62 c4 -34 10 -162 14 -284z m-1009 -43 c6 -118
-6 -460 -17 -460 -5 0 -9 123 -9 276 0 192 3 275 11 272 6 -2 13 -41 15 -88z
m103 -97 c0 -104 -4 -197 -9 -208 -5 -12 -9 62 -9 188 -1 132 3 207 9 207 6 0
10 -69 9 -187z m101 2 c0 -117 -4 -185 -10 -185 -6 0 -10 68 -10 185 0 117 4
185 10 185 6 0 10 -68 10 -185z m97 143 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m0 -100 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0
-60z m0 -165 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m724 -46
c-39 -48 -221 -107 -461 -151 -179 -32 -190 -34 -295 -41 -49 -4 -96 -8 -102
-10 -7 -3 -13 0 -13 4 0 5 35 12 78 16 114 10 421 63 464 80 21 8 45 12 54 8
13 -5 15 -3 9 7 -6 10 -4 12 9 7 19 -8 72 4 131 29 64 26 31 26 -40 -1 -37
-14 -69 -24 -72 -22 -2 2 36 19 85 36 48 18 101 41 117 51 32 21 57 12 36 -13z
m104 -85 c6 -30 3 -37 -33 -71 -25 -24 -68 -49 -112 -66 -83 -32 -102 -29 -30
5 28 13 56 30 63 38 14 18 6 16 -107 -17 -81 -24 -97 -22 -29 4 68 26 121 61
173 115 50 53 62 51 75 -8z m-1007 -119 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4
33 0 48 -2 33 -4z m579 -109 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m-290 -50 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-172
-19 c-23 -8 -83 -10 -79 -3 3 4 24 8 47 7 23 0 38 -2 32 -4z"/>
<path d="M6870 5632 c0 -5 30 -12 66 -16 36 -4 102 -13 147 -21 45 -8 98 -17
117 -20 l35 -5 -36 14 c-64 25 -329 64 -329 48z"/>
<path d="M7248 5563 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6680 7245 c-71 -38 -62 -151 15 -201 24 -16 27 -15 46 2 45 41 61
121 34 173 -21 43 -51 51 -95 26z"/>
<path d="M1997 7104 c-4 -4 -7 -16 -7 -26 0 -14 6 -19 23 -16 14 2 22 10 22
23 0 20 -25 32 -38 19z"/>
<path d="M9187 7054 c-4 -4 -7 -18 -7 -30 0 -17 7 -25 26 -30 31 -8 47 6 42
37 -2 17 -10 25 -28 27 -14 2 -29 0 -33 -4z"/>
<path d="M2234 7005 c-31 -31 -28 -61 8 -88 24 -18 31 -19 53 -7 46 24 46 87
0 108 -34 16 -32 16 -61 -13z"/>
<path d="M6863 6955 c-21 -9 -47 -36 -72 -74 -21 -33 -41 -57 -45 -54 -3 4 -6
1 -6 -6 0 -7 -11 1 -25 17 -52 62 -127 68 -139 11 -12 -62 49 -158 129 -200
51 -27 51 -27 60 -128 7 -70 6 -74 -11 -67 -76 29 -124 9 -124 -52 0 -52 31
-81 98 -89 47 -5 51 -8 57 -37 3 -17 12 -44 20 -61 15 -30 15 -30 8 15 -3 25
-7 72 -8 105 -2 33 -9 116 -16 184 -12 118 -12 124 7 134 34 19 94 90 121 145
33 67 37 137 9 158 -24 17 -23 17 -63 -1z"/>
<path d="M9265 6789 c-5 -13 -2 -27 8 -36 24 -24 59 -8 55 25 -4 36 -53 45
-63 11z"/>
<path d="M860 6636 c0 -114 78 -289 162 -362 48 -42 118 -68 163 -61 34 6 37
3 107 -81 77 -93 186 -202 201 -202 12 0 -4 20 -75 95 -60 63 -154 178 -169
207 -5 9 -16 48 -24 86 -43 207 -118 307 -263 352 -99 31 -102 30 -102 -34z
m176 -73 c33 -21 65 -72 92 -146 34 -89 26 -90 -22 -2 -24 44 -60 99 -80 123
-39 44 -34 54 10 25z m-72 -30 c36 -46 163 -243 157 -243 -10 0 -74 69 -98
105 -35 53 -95 165 -88 165 4 0 17 -12 29 -27z"/>
<path d="M1974 6513 c-20 -32 -98 -119 -174 -193 -119 -116 -143 -145 -174
-210 -95 -196 -84 -465 27 -628 l29 -43 -36 -144 c-20 -79 -36 -147 -36 -152
1 -22 29 44 70 166 l45 134 83 46 c99 55 165 111 226 189 91 119 145 258 153
398 7 109 -2 153 -70 334 -68 183 -85 195 -143 103z m-24 -171 c0 -4 -43 -57
-95 -117 -124 -144 -178 -254 -194 -395 -6 -48 -10 -60 -16 -46 -10 23 9 171
31 234 25 76 82 155 177 244 97 92 97 92 97 80z m35 -21 c-9 -51 -24 -82 -92
-185 -83 -128 -120 -199 -155 -306 l-28 -85 6 65 c8 95 53 198 153 353 49 75
92 148 95 162 9 35 27 31 21 -4z m112 -147 c14 -169 -86 -408 -223 -534 -21
-19 -33 -28 -27 -20 6 8 34 46 62 84 117 155 154 271 154 486 0 140 0 143 14
100 8 -25 17 -77 20 -116z m-112 -36 c-7 -24 -18 -54 -24 -68 -10 -23 -11 -22
-5 9 6 35 31 101 38 101 2 0 -2 -19 -9 -42z"/>
<path d="M9347 6523 c-12 -11 -7 -41 7 -46 21 -8 50 17 43 37 -6 16 -38 22
-50 9z"/>
<path d="M9214 6261 c-53 -24 -93 -77 -121 -160 -15 -45 -24 -60 -28 -49 -9
23 -73 44 -88 29 -19 -19 8 -129 42 -172 l30 -37 -34 -126 c-38 -138 -41 -160
-23 -154 7 3 31 65 55 140 l43 135 59 16 c59 17 112 52 132 89 17 31 -6 48
-62 48 -27 0 -49 4 -49 8 0 5 12 19 26 33 32 29 99 126 108 156 5 15 1 28 -13
42 -25 25 -26 25 -77 2z"/>
<path d="M630 6065 c-29 -76 -39 -278 -19 -363 17 -70 82 -204 146 -301 144
-218 328 -326 525 -306 l70 7 109 -108 c60 -60 109 -102 109 -94 0 14 -138
175 -182 213 -19 16 -20 20 -7 65 20 69 18 262 -5 336 -44 147 -142 291 -256
377 -76 57 -208 124 -333 168 -111 39 -144 40 -157 6z m269 -140 c229 -113
362 -271 400 -479 19 -105 11 -113 -20 -21 -81 240 -173 340 -448 485 -35 19
-85 54 -110 77 l-46 43 75 -34 c41 -19 108 -51 149 -71z m-19 -111 c107 -65
239 -190 285 -268 29 -51 70 -170 55 -161 -5 3 -14 22 -20 43 -28 85 -132 197
-306 327 -99 74 -154 128 -154 151 0 4 10 -2 23 -13 12 -11 65 -47 117 -79z
m-184 -28 c54 -211 234 -423 471 -556 94 -53 99 -65 10 -26 -85 37 -155 86
-245 171 -176 165 -264 324 -261 467 1 35 4 50 6 33 2 -16 11 -57 19 -89z
m207 -266 c23 -30 77 -92 122 -138 44 -45 78 -81 74 -80 -22 8 -182 174 -221
229 -51 71 -132 227 -144 274 -3 17 24 -28 61 -100 37 -71 86 -155 108 -185z
m83 65 c82 -80 115 -119 110 -130 -3 -9 -2 -14 3 -10 5 3 24 -14 42 -37 19
-24 26 -37 16 -29 -30 24 -18 1 28 -53 25 -29 45 -57 45 -61 0 -11 -46 39
-113 125 -33 41 -85 102 -117 135 -97 103 -176 199 -153 187 12 -7 75 -64 139
-127z"/>
<path d="M1110 5416 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M6510 6060 c-24 -24 -25 -38 -4 -68 18 -26 46 -28 68 -6 30 30 10 94
-30 94 -8 0 -23 -9 -34 -20z"/>
<path d="M2278 5835 c-17 -38 3 -75 41 -75 54 0 80 50 43 83 -28 26 -70 22
-84 -8z"/>
<path d="M9275 5681 c-9 -16 3 -31 26 -31 14 0 19 6 17 17 -4 20 -33 29 -43
14z"/>
<path d="M1999 5442 c-96 -61 -163 -165 -193 -299 -16 -74 -21 -233 -7 -267 5
-13 -2 -48 -21 -99 -43 -122 -32 -133 21 -22 27 57 67 123 93 152 88 98 138
187 157 273 5 25 13 100 16 168 8 139 8 140 -66 94z m-14 -193 c-19 -77 -46
-151 -52 -145 -6 5 23 118 44 174 23 60 27 45 8 -29z m-85 -99 c-15 -63 -33
-128 -39 -145 l-11 -30 5 35 c9 66 24 127 52 213 26 80 23 48 -7 -73z"/>
<path d="M9410 5415 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M1515 5330 c14 -120 77 -442 111 -560 37 -129 100 -311 134 -390 11
-25 14 -37 7 -26 -6 10 -36 46 -65 80 -29 33 -72 97 -95 141 -94 180 -231 259
-429 247 -72 -5 -73 -14 -20 -121 44 -90 133 -186 207 -222 44 -21 63 -24 165
-24 l114 0 84 -100 c92 -110 112 -145 197 -345 69 -163 92 -210 105 -210 5 0
7 6 5 13 -2 6 -16 52 -29 100 -24 84 -25 90 -11 144 8 32 15 70 15 86 0 49 25
82 72 96 146 43 263 190 275 346 7 85 -1 114 -70 253 -54 109 -71 122 -100 78
-9 -13 -59 -70 -111 -126 -65 -68 -106 -123 -128 -168 -31 -63 -33 -73 -33
-172 0 -110 1 -111 64 -215 17 -27 17 -39 7 -124 l-11 -94 -29 74 c-16 41 -64
155 -108 254 -74 169 -177 455 -212 590 -8 33 -33 140 -55 238 -43 188 -67
256 -56 157z m675 -560 c-6 -11 -30 -44 -54 -74 -85 -104 -126 -193 -126 -272
0 -18 -4 -36 -10 -39 -6 -4 -9 21 -7 68 4 97 36 163 128 262 74 78 87 89 69
55z m88 -144 c-6 -82 -36 -150 -93 -208 -51 -52 -64 -48 -21 5 74 91 113 223
84 283 -8 16 -12 38 -10 49 4 16 8 12 24 -20 17 -31 20 -52 16 -109z m-900 95
c54 -27 173 -161 144 -161 -13 0 -62 39 -99 81 -17 19 -56 47 -85 60 -112 54
-108 50 -51 45 28 -3 69 -14 91 -25z m847 -13 c-4 -13 -31 -60 -61 -106 -33
-49 -66 -118 -84 -170 -24 -70 -30 -80 -30 -52 0 40 21 124 40 160 7 14 37 57
66 97 30 40 54 77 54 83 0 5 5 10 11 10 6 0 8 -9 4 -22z m-782 -165 c61 -31
55 -42 -8 -14 -61 27 -134 81 -163 120 -19 26 -10 22 50 -25 40 -31 95 -67
121 -81z"/>
<path d="M9446 4929 c-10 -17 -16 -33 -13 -36 3 -3 13 9 22 27 21 41 14 48 -9
9z"/>
<path d="M9567 4923 c-9 -2 -15 -9 -12 -14 10 -15 32 -10 32 6 0 8 -1 14 -1
14 -1 -1 -9 -3 -19 -6z"/>
<path d="M9603 4858 c-53 -87 -99 -204 -93 -235 1 -5 8 -7 17 -5 10 2 13 -1 8
-10 -6 -9 -5 -10 2 -3 16 15 84 217 79 231 -3 8 2 18 12 23 9 5 14 13 10 17
-5 4 -3 13 3 21 6 7 7 13 2 13 -5 0 -23 -24 -40 -52z"/>
<path d="M9644 4803 c-34 -77 -64 -168 -64 -193 0 -33 17 -23 35 23 47 116 74
197 65 197 -6 0 -10 4 -10 10 0 5 7 7 15 4 8 -4 15 -1 15 5 0 6 -7 11 -15 11
-9 0 -26 -24 -41 -57z"/>
<path d="M9750 4842 c0 -6 -9 -20 -20 -30 -34 -32 -109 -235 -90 -247 4 -3 12
1 18 8 13 17 72 173 72 192 0 9 7 30 16 46 8 17 13 33 10 37 -3 3 -6 0 -6 -6z"/>
<path d="M8550 4772 c-45 -36 3 -108 55 -84 31 14 37 47 13 77 -23 30 -38 31
-68 7z"/>
<path d="M9768 4765 c-19 -37 -68 -190 -68 -213 1 -14 4 -13 20 7 24 28 73
182 68 214 -3 21 -5 20 -20 -8z"/>
<path d="M9880 4769 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M9827 4751 c-3 -13 -1 -18 9 -14 7 2 10 11 7 19 -7 19 -10 18 -16 -5z"/>
<path d="M9868 4705 c-3 -13 -2 -22 2 -20 11 7 20 45 10 45 -4 0 -10 -11 -12
-25z"/>
<path d="M9799 4638 c-35 -122 -34 -115 -16 -100 16 14 57 123 57 154 0 41
-21 13 -41 -54z"/>
<path d="M9925 4668 c-34 -85 -54 -148 -45 -148 7 0 48 104 65 168 10 37 -2
25 -20 -20z"/>
<path d="M5940 4655 c-63 -8 -123 -14 -132 -14 -10 -1 -18 -8 -18 -17 0 -12 7
-14 28 -10 15 3 59 8 97 11 85 7 184 25 205 36 21 12 -40 9 -180 -6z"/>
<path d="M8367 4632 c-10 -10 -17 -30 -17 -44 0 -55 76 -79 106 -33 38 59 -42
129 -89 77z"/>
<path d="M8757 4632 c-34 -37 -9 -82 44 -82 41 0 59 38 35 75 -20 30 -56 33
-79 7z"/>
<path d="M2693 4530 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M2791 4470 l2 -75 7 65 c5 37 4 69 -1 75 -5 5 -8 -21 -8 -65z"/>
<path d="M2742 4485 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2846 4463 c9 -65 14 -67 14 -5 0 30 -4 52 -11 52 -7 0 -8 -16 -3
-47z"/>
<path d="M8608 4503 c-18 -5 -34 -46 -29 -73 4 -21 31 -40 57 -40 52 0 75 74
32 104 -23 16 -29 17 -60 9z"/>
<path d="M2894 4461 c3 -18 6 -54 6 -79 0 -59 6 -51 14 18 5 43 3 59 -9 75
-16 19 -16 19 -11 -14z"/>
<path d="M8922 4473 c-22 -9 -34 -41 -22 -63 15 -27 66 -27 80 1 9 16 8 26 -5
45 -15 23 -27 28 -53 17z"/>
<path d="M2951 4399 c0 -35 4 -72 7 -82 4 -9 7 16 7 57 0 41 -3 78 -7 82 -5 4
-8 -22 -7 -57z"/>
<path d="M9457 4412 c-34 -34 -38 -46 -41 -96 -2 -31 -7 -60 -10 -64 -4 -3
-18 6 -32 22 -29 31 -58 31 -62 0 -2 -12 8 -47 22 -78 21 -47 23 -61 14 -87
-6 -16 -8 -33 -4 -36 8 -8 7 -11 60 94 32 62 52 90 61 87 19 -8 84 25 96 49
13 23 -2 37 -40 37 -22 0 -23 1 -7 25 26 40 17 85 -18 85 -1 0 -19 -17 -39
-38z"/>
<path d="M3006 4373 c11 -96 23 -106 23 -20 1 64 -2 77 -15 77 -13 0 -14 -9
-8 -57z"/>
<path d="M3071 4383 c0 -15 4 -50 9 -78 l7 -50 2 72 c0 39 -3 75 -9 78 -6 4
-10 -6 -9 -22z"/>
<path d="M3132 4360 c0 -14 4 -36 8 -50 8 -24 8 -24 8 5 0 17 -3 39 -8 50 -8
18 -9 18 -8 -5z"/>
<path d="M3200 4295 c0 -27 2 -46 4 -44 8 8 10 78 3 85 -4 4 -7 -14 -7 -41z"/>
<path d="M2542 4293 c-13 -24 3 -53 29 -53 24 0 36 27 22 49 -15 23 -40 25
-51 4z"/>
<path d="M3263 4270 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M5760 4209 c-372 -35 -737 -90 -811 -123 l-34 -14 45 -1 c56 -1 213
16 355 38 39 6 115 18 170 27 55 9 159 26 230 39 145 27 197 34 238 35 15 0
27 4 27 10 0 5 -10 9 -22 8 -13 -2 -102 -10 -198 -19z"/>
<path d="M5690 4134 c-124 -13 -277 -34 -340 -48 l-115 -24 65 -1 c54 -1 520
59 570 74 8 2 33 6 54 10 22 3 42 8 45 10 7 8 -40 4 -279 -21z"/>
<path d="M2448 4074 c-11 -23 -10 -29 11 -45 29 -24 36 -24 61 1 30 30 10 70
-38 70 -15 0 -27 -9 -34 -26z"/>
<path d="M3800 4060 c-74 -4 -196 -15 -270 -24 -74 -8 -202 -22 -285 -31 -311
-33 -481 -76 -776 -196 -286 -117 -568 -271 -883 -483 -163 -109 -240 -168
-328 -255 -79 -76 -76 -111 11 -136 l36 -11 3 -64 3 -65 -155 -84 c-86 -46
-165 -93 -176 -103 -33 -31 -25 -61 35 -131 58 -69 51 -66 185 -83 85 -11 540
-77 880 -128 91 -14 262 -39 380 -56 118 -17 269 -40 335 -52 66 -11 126 -21
133 -21 6 0 12 -4 12 -7 0 -4 24 -18 53 -30 141 -60 197 -95 197 -122 0 -5
-45 15 -101 42 -55 28 -123 60 -152 71 -95 37 -34 2 114 -66 89 -40 158 -78
175 -97 16 -16 45 -38 64 -48 49 -25 171 -60 209 -60 17 0 57 -9 89 -21 31
-12 68 -23 82 -26 26 -5 69 -23 133 -55 67 -33 39 -10 -33 28 -103 54 -88 78
18 29 73 -34 78 -31 14 5 -29 17 -50 35 -47 40 7 12 38 13 49 3 3 -4 17 -8 29
-10 12 -2 72 -27 132 -57 113 -56 165 -79 165 -72 0 6 -224 123 -251 131 -66
20 -114 55 -76 55 21 0 128 -46 286 -121 79 -38 146 -69 150 -69 8 0 -145 83
-229 124 -177 86 -168 84 122 -32 179 -71 218 -91 219 -107 1 -18 2 -18 6 -2
3 12 10 16 21 12 10 -4 53 -19 97 -34 44 -16 177 -65 295 -111 558 -213 942
-357 1084 -405 161 -55 160 -55 281 53 44 38 202 170 350 291 217 178 277 222
308 227 21 4 34 10 27 14 -8 5 -7 10 2 17 9 7 47 8 113 2 115 -9 190 -11 184
-4 -3 2 -61 10 -129 15 -69 6 -127 13 -129 16 -11 10 29 36 60 38 58 5 60 6
54 16 -4 6 -11 8 -16 5 -5 -4 -9 1 -9 9 0 24 38 31 175 29 137 -2 124 8 -27
22 -43 4 -78 11 -78 16 0 5 24 20 53 35 46 22 64 25 147 24 146 -3 166 2 50
12 -58 5 -115 9 -127 9 -33 -1 -28 14 11 33 29 13 76 16 302 16 147 0 265 2
263 4 -4 4 -308 25 -420 29 -97 3 -89 20 16 32 90 10 98 25 13 25 -40 0 -41 0
-23 20 17 19 31 20 233 20 119 0 213 2 211 4 -2 2 -53 6 -114 10 -232 13 -270
18 -250 33 12 8 48 13 101 13 46 0 85 4 88 9 3 5 -26 12 -65 15 -64 7 -69 9
-52 21 13 11 52 14 142 15 128 0 166 8 51 10 -36 1 -84 4 -107 7 l-42 5 22 30
c21 28 24 29 77 23 37 -5 52 -3 45 3 -5 6 -28 12 -50 14 -35 3 -40 6 -43 31
-4 34 -53 61 -197 106 -55 18 -105 38 -112 44 -15 15 -53 301 -46 344 l5 32
-131 66 c-81 40 -129 70 -125 76 4 6 31 22 61 35 74 33 181 122 182 151 2 42
-57 57 -264 68 -33 1 -78 6 -100 10 -40 7 -377 -54 -685 -124 -69 -16 -153
-34 -187 -40 -34 -6 -83 -21 -110 -33 -26 -12 -131 -45 -233 -73 -102 -29
-205 -59 -230 -68 -145 -49 -279 -105 -385 -160 -66 -35 -141 -71 -166 -80
-60 -23 -100 -65 -102 -109 l-2 -33 95 1 c127 1 334 19 392 34 102 26 62 2
-66 -40 -75 -24 -136 -47 -136 -50 0 -4 -18 -13 -41 -20 -48 -16 -56 -26 -30
-39 24 -14 181 14 336 59 186 54 216 61 227 58 5 -2 -66 -29 -159 -61 -92 -31
-163 -57 -158 -58 6 0 -19 -11 -55 -24 -36 -13 -58 -24 -50 -24 8 -1 136 39
285 88 250 83 656 196 679 188 5 -2 -71 -27 -170 -56 -200 -60 -463 -146 -509
-166 -16 -7 -61 -25 -99 -39 -92 -35 -117 -56 -81 -70 8 -3 14 -1 12 5 -2 12
171 66 413 130 341 90 604 171 682 210 52 26 61 23 13 -4 -110 -62 -291 -132
-460 -177 -44 -12 -174 -51 -290 -87 -115 -36 -226 -70 -245 -76 -23 -7 -29
-12 -18 -14 15 -4 334 90 526 153 86 28 64 11 -80 -62 -82 -41 -148 -76 -148
-79 0 -8 51 15 164 74 61 33 170 80 241 105 72 26 157 58 190 72 33 15 107 45
165 69 58 23 161 70 229 104 68 34 125 61 127 59 6 -6 -197 -126 -284 -168
-45 -22 -142 -79 -215 -128 -73 -48 -151 -100 -174 -115 -63 -40 -52 -41 15
-2 101 60 135 81 237 147 140 90 180 113 172 102 -6 -10 -255 -179 -444 -300
-50 -32 -163 -108 -251 -167 l-160 -108 -119 59 c-222 112 -527 254 -680 318
-258 107 -420 150 -603 161 -219 12 -375 -37 -554 -175 -92 -71 -53 -18 103
138 264 266 694 594 1168 894 144 91 234 130 352 152 177 34 471 -11 740 -112
51 -19 408 -185 481 -223 9 -5 33 -6 55 -2 l39 6 -27 22 c-44 34 -410 216
-537 266 -230 92 -402 128 -600 129 -136 0 -235 -20 -338 -67 -30 -14 -56 -24
-57 -23 -65 110 -104 151 -201 214 -111 71 -277 134 -416 158 -105 18 -431 27
-608 16z m620 -90 c195 -40 338 -102 431 -188 59 -54 105 -131 95 -157 -8 -23
-26 -18 -26 8 0 33 -55 122 -90 147 l-31 22 37 -58 c41 -64 70 -154 50 -154
-7 0 -20 21 -29 48 -23 62 -77 135 -129 172 -61 45 -69 36 -15 -17 52 -51 103
-146 112 -208 l6 -40 -20 47 c-44 103 -105 180 -180 229 -59 38 -76 37 -23 0
50 -37 92 -97 126 -181 35 -85 32 -111 -3 -34 -33 70 -151 195 -151 159 0 -3
16 -26 36 -52 45 -58 80 -128 88 -173 l6 -35 -12 30 c-22 58 -76 132 -133 181
-76 66 -98 77 -52 25 83 -92 143 -205 134 -253 -3 -22 -4 -22 -11 -3 -49 122
-87 168 -209 253 -77 54 -150 92 -245 126 -102 36 -112 32 -19 -8 104 -46 235
-129 301 -192 53 -51 122 -170 114 -196 -3 -7 -15 10 -29 37 -35 72 -118 157
-191 195 l-63 34 68 -64 c73 -68 143 -174 152 -230 l6 -35 -18 39 c-32 66
-151 188 -240 245 -81 51 -231 126 -239 118 -2 -2 38 -27 89 -55 100 -56 153
-100 245 -204 56 -63 81 -103 97 -155 12 -41 -9 -24 -28 21 -19 45 -81 116
-103 116 -6 0 -26 17 -43 38 -17 20 -29 30 -25 21 12 -37 -27 -24 -97 33 -41
33 -96 70 -124 84 -51 24 -47 21 30 -29 23 -15 87 -64 140 -110 74 -61 106
-97 131 -142 41 -73 45 -108 5 -44 -54 86 -194 196 -351 274 -128 64 -181 81
-85 27 186 -103 310 -210 364 -312 18 -33 27 -60 22 -60 -6 0 -11 4 -11 8 0 5
-31 42 -69 83 -69 75 -175 156 -261 199 -55 27 -54 26 41 -47 73 -56 179 -165
201 -208 11 -19 10 -19 -8 -5 -10 8 -45 40 -77 70 -87 82 -168 136 -305 202
-135 65 -174 74 -59 14 157 -83 212 -121 296 -204 123 -121 133 -153 16 -48
-89 79 -211 163 -222 152 -2 -2 20 -20 50 -40 57 -38 197 -178 197 -197 0 -6
-28 12 -62 40 -100 81 -227 165 -315 210 -46 22 -86 41 -90 41 -4 0 36 -27 90
-59 120 -73 135 -85 225 -174 71 -70 119 -133 109 -143 -2 -2 -44 32 -93 77
-96 87 -145 124 -211 158 -55 27 -56 20 -1 -13 49 -29 225 -201 234 -228 12
-38 -6 -30 -65 31 -63 64 -165 143 -216 168 -28 14 -28 13 5 -13 49 -38 183
-174 205 -207 34 -53 6 -39 -72 33 -43 40 -100 90 -128 111 -51 38 -185 115
-185 106 0 -3 30 -27 67 -53 72 -51 239 -212 268 -257 32 -50 6 -42 -52 17
-91 92 -152 140 -246 195 -106 61 -127 67 -38 11 78 -51 266 -232 280 -271 5
-16 7 -29 4 -29 -3 0 -45 37 -92 83 -47 45 -112 100 -144 121 -65 43 -257 139
-264 132 -2 -2 40 -29 95 -60 124 -70 208 -132 256 -191 20 -24 42 -50 49 -57
7 -7 20 -25 29 -39 36 -57 0 -35 -91 55 -88 86 -180 156 -205 156 -6 0 12 -20
39 -44 51 -44 176 -188 177 -203 0 -4 10 -21 21 -38 20 -28 20 -29 2 -15 -11
8 -22 18 -25 21 -47 60 -139 153 -201 202 -83 67 -242 153 -299 162 -32 5 -30
3 20 -22 154 -79 350 -240 415 -340 15 -24 30 -41 34 -39 4 3 7 -4 7 -15 0
-15 -15 -5 -61 40 -79 77 -187 166 -222 184 -62 32 -77 28 -28 -6 71 -49 229
-216 243 -257 11 -31 6 -27 -70 46 -89 86 -156 136 -216 160 l-37 16 40 -33
c71 -57 199 -193 223 -237 32 -58 8 -54 -42 6 -67 81 -158 153 -242 193 -102
49 -117 50 -39 3 107 -65 192 -148 258 -254 19 -31 19 -33 2 -20 -10 8 -48 44
-85 80 -48 47 -95 80 -169 119 -56 29 -105 50 -108 47 -4 -3 -2 -6 4 -6 6 0
44 -26 84 -58 63 -50 119 -108 188 -197 16 -20 16 -20 -9 0 -14 11 -45 38 -70
60 -80 72 -244 174 -258 161 -3 -3 0 -6 6 -6 6 0 56 -34 111 -75 113 -85 225
-207 289 -314 54 -92 54 -105 0 -21 -57 88 -204 238 -291 298 -171 118 -435
223 -667 267 -224 42 -694 21 -997 -45 -46 -10 -83 -15 -83 -12 1 11 191 149
364 263 226 150 433 269 631 364 394 190 462 207 1200 290 338 39 681 41 855
5z m2410 -717 l22 -28 -44 -8 c-23 -4 -74 -14 -111 -22 -38 -8 -73 -13 -78 -9
-5 3 -16 18 -24 33 l-14 29 87 15 c128 22 137 21 162 -10z m204 -2 c3 -5 -7
-11 -23 -14 -16 -3 -39 -9 -52 -12 -17 -4 -27 0 -37 15 -12 19 -10 20 47 20
33 0 62 -4 65 -9z m110 0 c10 -15 -22 -20 -34 -5 -10 11 -9 14 8 14 12 0 23
-4 26 -9z m-608 -57 c9 -34 2 -38 -81 -53 -38 -8 -138 -31 -222 -52 -83 -22
-154 -39 -156 -39 -11 0 -29 62 -21 70 5 4 70 22 144 38 74 17 162 37 195 45
33 8 77 15 97 16 33 1 38 -2 44 -25z m-544 -91 c20 -24 27 -45 34 -106 8 -69
31 -116 60 -123 26 -7 379 79 545 132 87 28 108 38 117 59 15 33 15 33 -5 38
-14 4 -13 5 4 6 37 2 59 -63 30 -88 -28 -23 -627 -181 -686 -181 -30 0 -91 61
-91 90 0 32 -39 158 -50 165 -13 8 -3 25 14 25 7 0 20 -8 28 -17z m-78 -67
c21 -44 21 -43 -14 -38 -31 4 -115 -17 -455 -115 -221 -63 -310 -93 -419 -140
-44 -18 -84 -32 -89 -30 -14 4 90 67 168 102 152 68 713 252 776 254 10 1 24
-14 33 -33z m-3464 -5 c181 -37 401 -120 539 -203 85 -50 215 -159 261 -219
l35 -44 -64 56 c-260 230 -819 379 -1236 330 -148 -17 -153 -18 -368 -62 -207
-43 -279 -49 -109 -10 422 97 590 119 783 102 214 -20 460 -81 592 -148 27
-13 51 -22 54 -19 11 10 -187 97 -277 120 -187 50 -323 68 -495 68 -187 -1
-292 -15 -564 -78 -106 -25 -195 -44 -197 -42 -1 2 -5 16 -8 30 -3 20 0 29 12
32 133 40 390 90 537 105 138 15 393 5 505 -18z m4517 -66 c46 -31 83 -62 83
-69 0 -18 -16 -31 -170 -135 -654 -444 -1124 -771 -1153 -803 -12 -15 -21 -17
-30 -10 -55 45 -621 299 -862 387 -290 105 -553 152 -805 143 -139 -5 -143 -5
-95 9 28 8 106 17 175 20 158 7 298 -13 504 -71 83 -24 162 -46 176 -50 l25
-7 -25 15 c-71 40 -305 105 -495 136 -169 28 -405 5 -517 -50 -22 -11 -22 -10
2 9 43 35 168 71 286 83 264 27 571 -59 1084 -305 74 -36 212 -101 305 -146
107 -51 173 -88 179 -101 5 -11 17 -22 27 -25 19 -6 33 3 269 174 230 166 424
301 760 524 l312 209 -24 16 c-36 25 -108 88 -108 95 0 14 15 6 97 -48z
m-3178 -49 c-3 -3 -11 5 -18 17 -13 21 -12 21 5 5 10 -10 16 -20 13 -22z
m3136 4 c3 -5 -20 -26 -52 -46 -169 -107 -658 -439 -868 -589 -307 -220 -351
-249 -370 -242 -25 10 -7 31 83 99 304 227 1148 786 1189 788 6 0 15 -4 18
-10z m-4455 -55 c94 -18 316 -81 340 -96 12 -9 12 -9 -3 -5 -11 3 -31 8 -45
11 -25 4 -25 4 3 -8 103 -42 252 -123 323 -177 46 -34 81 -65 78 -67 -3 -3
-32 11 -65 31 -163 99 -421 199 -609 237 -136 26 -361 33 -487 15 -105 -16
-256 -46 -408 -82 -60 -15 -110 -24 -113 -22 -2 3 26 12 64 22 95 24 320 76
367 86 27 5 32 8 15 9 -29 1 -154 -18 -354 -54 -76 -13 -143 -22 -147 -19 -18
11 -8 24 29 33 58 16 387 81 487 96 115 18 406 12 525 -10z m4667 -102 c1 -36
12 -126 23 -198 12 -73 20 -147 18 -166 l-4 -34 -13 45 c-11 37 -37 175 -56
297 -4 28 -13 43 -27 43 -7 0 -8 -12 -4 -32 4 -18 9 -67 12 -108 3 -41 9 -94
14 -117 4 -24 4 -43 0 -43 -4 0 -10 15 -14 33 -15 87 -35 214 -35 229 -1 15
-13 10 -68 -23 -75 -46 -237 -156 -295 -202 -21 -15 -42 -26 -48 -22 -6 4 -9
2 -8 -3 2 -6 -49 -49 -112 -98 -110 -83 -259 -202 -270 -214 -25 -29 -403
-340 -413 -340 -4 0 -7 9 -7 19 0 12 25 39 63 70 35 28 70 58 78 68 13 17 12
17 -6 2 -11 -8 -45 -36 -76 -62 -31 -26 -59 -47 -63 -47 -4 0 -6 12 -4 27 4
33 70 85 418 334 230 164 290 204 290 196 0 -2 -52 -45 -114 -96 -63 -50 -118
-96 -122 -102 -3 -6 52 35 122 92 71 56 128 104 127 108 0 3 67 52 150 108 84
57 213 147 287 202 74 54 140 99 145 100 6 1 11 -29 12 -66z m-4667 -38 c222
-36 395 -102 570 -219 46 -31 81 -56 77 -56 -4 0 -54 25 -112 56 -293 155
-674 217 -1020 165 -251 -38 -349 -50 -437 -53 -87 -3 -99 5 -22 15 22 3 89
17 150 30 127 29 314 65 364 70 97 10 343 6 430 -8z m-1142 -37 c-2 -7 -23
-23 -47 -35 -82 -42 -89 -57 -33 -72 20 -5 53 -14 72 -20 l35 -10 -35 4 c-19
3 -71 7 -115 10 -43 3 -82 8 -84 11 -9 8 37 40 119 84 81 43 94 47 88 28z
m132 2 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10 15 0 15 -2 2 -10z m5570 1 c0
-5 -89 -78 -197 -163 -108 -84 -253 -203 -322 -263 -69 -61 -191 -166 -271
-234 -80 -68 -144 -127 -143 -131 2 -7 -53 -60 -97 -94 -14 -10 -1 4 28 33 91
87 53 64 -144 -86 -19 -14 -34 -21 -34 -15 0 6 37 41 83 78 45 37 120 102 167
144 90 80 352 302 454 384 34 27 77 63 96 81 33 30 63 51 255 183 44 30 89 63
100 73 22 22 25 23 25 10z m-3542 -36 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z m1369 9 c2 -7 -11 -13 -32 -16 -33 -4 -46 8 -28 26 11 10 56
3 60 -10z m-1337 -68 c0 -12 -5 -7 -19 24 -11 24 -11 24 3 6 9 -11 16 -24 16
-30z m-959 13 c123 -16 258 -56 364 -109 111 -56 109 -57 -17 -5 -234 96 -458
121 -803 90 -160 -14 -252 -19 -244 -11 4 4 270 42 344 49 59 6 269 -2 356
-14z m4464 11 c-4 -6 -48 -42 -98 -81 -113 -87 -348 -300 -408 -369 -39 -46
-230 -214 -300 -265 -15 -11 -56 -44 -91 -74 l-63 -54 55 54 c245 238 740 682
840 752 70 50 75 52 65 37z m-4505 -75 c47 -8 99 -17 115 -20 99 -19 446 -158
431 -173 -2 -2 -51 17 -108 41 -124 54 -199 79 -338 111 -150 36 -236 45 -390
41 -146 -3 -154 -1 -45 14 87 12 223 6 335 -14z m4514 10 c-4 -8 -10 -15 -15
-15 -17 0 -210 -162 -394 -330 -99 -91 -216 -196 -260 -235 -44 -38 -142 -130
-218 -202 -77 -73 -143 -130 -148 -127 -5 3 -9 2 -9 -3 0 -4 -17 -21 -38 -38
l-37 -29 33 39 c19 22 50 54 70 72 20 17 34 34 31 37 -3 3 -28 -15 -55 -40
-61 -57 -104 -88 -104 -76 0 5 12 18 28 28 15 10 51 40 80 67 28 28 52 44 52
38 0 -7 43 27 95 76 93 86 126 110 110 82 -5 -8 -4 -11 1 -6 5 5 9 12 9 16 0
10 13 22 120 113 50 42 117 104 150 138 98 101 302 275 423 361 73 52 84 57
76 34z m-4977 -22 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z
m-114 -10 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m5117 -6 c0
-2 -15 -16 -32 -33 l-33 -29 29 33 c28 30 36 37 36 29z m-5755 -31 c61 -8 135
-17 166 -21 191 -20 423 -48 549 -65 41 -5 134 -17 205 -25 129 -16 166 -21
215 -30 14 -2 45 -7 70 -10 25 -3 72 -10 105 -15 33 -5 76 -12 95 -14 137 -20
193 -30 182 -34 -16 -5 -260 21 -487 53 -93 14 -224 31 -290 39 -360 45 -840
109 -905 120 -102 18 -41 19 95 2z m2572 -22 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m3063 -56 c-30 -28 -134 -126 -230 -217 -96 -92 -242
-228 -325 -304 -82 -75 -208 -191 -279 -258 -132 -123 -190 -170 -180 -147 5
11 266 256 413 388 114 102 246 224 326 300 124 117 317 290 324 290 3 0 -19
-24 -49 -52z m363 21 c-10 -4 -24 -2 -30 3 -23 19 -14 26 17 15 25 -10 27 -13
13 -18z m-184 -42 c-1 -31 -1 -31 -9 -7 -11 36 -11 52 0 45 6 -3 10 -21 9 -38z
m-2798 8 c312 -41 652 -172 1060 -406 134 -77 248 -145 254 -150 15 -14 -18
-11 -40 5 -83 57 -454 232 -476 224 -5 -2 49 -34 119 -72 144 -78 384 -233
401 -258 6 -9 -29 6 -77 34 -226 131 -496 286 -577 332 -410 227 -747 311
-1058 262 -60 -10 -127 -24 -149 -32 -68 -24 -67 -14 2 12 153 60 338 76 541
49z m-1091 -5 c13 -8 13 -10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m4194
-28 c-12 -9 -91 -71 -175 -137 -254 -197 -973 -776 -1119 -900 -52 -45 -109
-91 -126 -104 l-31 -22 -84 29 c-101 36 -673 251 -1039 392 -146 56 -348 133
-450 172 -269 101 -621 241 -617 245 12 11 485 -147 837 -280 422 -160 535
-203 755 -290 323 -127 513 -198 552 -204 39 -7 56 4 173 102 108 90 392 316
535 425 61 46 207 159 325 250 278 215 420 320 445 331 33 13 41 10 19 -9z
m-518 -98 c-59 -58 -110 -103 -113 -101 -5 6 206 207 217 206 3 0 -44 -47
-104 -105z m294 96 c0 -5 -33 -34 -72 -63 -250 -185 -345 -254 -358 -261 -44
-22 142 122 298 233 56 40 100 76 97 81 -3 5 3 11 12 13 10 3 19 5 21 6 1 0 2
-3 2 -9z m-3769 -46 c57 -29 132 -117 167 -197 l22 -52 -22 30 c-95 126 -173
194 -239 210 -23 6 -36 -2 -83 -44 -31 -28 -59 -51 -62 -51 -9 0 86 119 103
129 23 12 55 5 114 -25z m-478 -51 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0
24 -2 18 -5z m449 -16 c32 -15 138 -105 138 -119 0 -5 -21 9 -47 30 -58 48
-120 82 -150 82 -13 0 -23 5 -23 10 0 14 49 12 82 -3z m988 -27 c112 -24 301
-87 402 -134 40 -19 112 -52 161 -74 83 -39 112 -43 65 -8 l-23 17 25 -12
c116 -55 517 -305 506 -316 -3 -3 21 -21 53 -40 32 -19 71 -46 87 -59 l29 -24
-45 19 c-104 43 -329 155 -452 224 -378 212 -597 305 -829 356 -131 28 -325
51 -433 51 -49 0 -87 2 -84 5 3 3 38 9 79 15 114 14 349 4 459 -20z m2390 -68
c0 -5 -35 -39 -77 -77 l-78 -68 75 76 c78 80 80 82 80 69z m-2883 -18 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m40 0 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m283 -9 c117 -19 217 -51 380 -120 191 -82 324
-145 450 -217 63 -36 157 -87 207 -113 51 -27 91 -50 89 -52 -6 -6 -171 64
-285 121 -230 116 -480 222 -697 296 -146 51 -220 70 -329 85 l-90 13 90 1
c50 0 133 -6 185 -14z m-1179 -8 c24 -8 50 -12 58 -9 8 3 11 0 7 -6 -3 -5 1
-13 10 -16 8 -3 13 -2 9 3 -3 5 2 8 10 7 20 -3 32 -16 15 -16 -9 0 -7 -5 6
-14 10 -8 16 -18 12 -22 -4 -4 -14 -1 -22 6 -9 6 -55 27 -103 46 -87 33 -88
47 -2 21z m1868 -53 c14 -12 -19 -1 -35 12 -18 14 -18 14 6 3 14 -6 27 -13 29
-15z m-1614 -48 c18 -51 23 -58 74 -83 46 -22 70 -27 136 -27 73 -1 83 -3 117
-31 21 -16 38 -33 38 -37 0 -3 -24 3 -52 15 -38 14 -78 21 -138 22 -100 1
-170 29 -201 79 -27 44 -23 62 6 29 30 -34 31 -24 5 45 -11 30 -17 51 -12 48
5 -3 17 -30 27 -60z m1315 24 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10
10 -5 10 6 0 17 -5 25 -10z m90 -36 c33 -15 59 -29 57 -31 -2 -2 -33 10 -68
26 -80 37 -72 40 11 5z m203 -95 c67 -34 183 -98 257 -142 121 -72 439 -232
530 -267 19 -7 39 -17 45 -22 12 -11 -90 27 -205 77 -98 42 -164 77 -345 185
-71 43 -195 112 -275 154 -174 91 -179 101 -7 15z m1615 -42 c-12 -11 -107
-92 -153 -129 -11 -9 -63 -52 -115 -95 -221 -183 -320 -259 -320 -246 0 9 33
37 294 248 266 215 291 234 299 234 5 1 2 -5 -5 -12z"/>
<path d="M2445 3549 c-38 -3 38 -6 170 -6 171 0 220 2 170 8 -75 9 -227 8
-340 -2z"/>
<path d="M2220 3430 c-14 -4 91 -8 235 -8 143 0 247 4 230 8 -40 10 -433 10
-465 0z"/>
<path d="M2203 3333 c-90 -8 -36 -20 120 -27 89 -4 195 -10 235 -14 41 -4 75
-5 77 -1 5 8 -103 27 -225 39 -94 10 -126 10 -207 3z"/>
<path d="M2055 3222 c-28 -5 5 -10 115 -15 148 -7 372 -30 415 -43 15 -5 17
-3 9 5 -6 7 -53 21 -105 32 -100 21 -359 34 -434 21z"/>
<path d="M2660 2800 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M6259 2193 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M6155 2124 c-102 -81 -114 -98 -17 -23 74 56 100 79 90 79 -2 0 -34
-25 -73 -56z"/>
<path d="M6115 1768 l-100 -103 103 100 c56 55 102 101 102 102 0 8 -12 -4
-105 -99z"/>
<path d="M5070 1946 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M5120 1917 c0 -5 252 -157 308 -186 48 -25 19 -1 -40 34 -35 20 -83
48 -108 63 -80 50 -160 94 -160 89z"/>
<path d="M5800 4020 c-24 -24 -25 -43 -4 -64 37 -38 103 -2 85 46 -9 23 -29
38 -52 38 -5 0 -18 -9 -29 -20z"/>
<path d="M1110 4025 c0 -3 60 -35 133 -71 227 -114 364 -217 456 -345 41 -56
41 -25 -1 55 -42 79 -155 192 -238 239 -36 20 -129 57 -205 82 -154 49 -145
47 -145 40z"/>
<path d="M1180 3931 c81 -68 433 -290 417 -264 -19 31 -312 222 -417 272 l-25
12 25 -20z"/>
<path d="M1205 3833 c39 -37 97 -86 130 -108 72 -48 279 -155 300 -155 32 0
10 17 -86 65 -112 56 -251 142 -349 217 l-65 49 70 -68z"/>
<path d="M5905 3482 c33 -14 138 -66 233 -114 175 -89 249 -114 187 -62 -44
37 -257 139 -361 174 -111 36 -141 38 -59 2z"/>
<path d="M5170 3451 c-19 -7 -8 -9 45 -11 39 -1 94 -5 124 -9 46 -7 51 -6 35
6 -10 7 -28 13 -39 13 -12 0 -49 2 -81 5 -32 2 -70 0 -84 -4z"/>
<path d="M5749 3428 c40 -17 137 -63 215 -103 77 -39 156 -75 175 -78 l34 -7
-19 21 c-40 46 -400 200 -462 199 -10 0 16 -15 57 -32z"/>
<path d="M5587 3381 c28 -11 124 -53 214 -95 89 -42 174 -76 188 -76 26 1 26
1 6 17 -61 48 -328 155 -420 168 -39 5 -39 5 12 -14z"/>
<path d="M4955 3361 c-13 -5 37 -10 135 -14 85 -3 166 -9 180 -12 14 -3 7 2
-15 11 -45 18 -267 30 -300 15z"/>
<path d="M5460 3315 c0 -2 37 -16 82 -31 46 -15 108 -38 138 -52 45 -20 135
-51 148 -52 2 0 0 5 -3 11 -10 16 -131 69 -215 94 -74 22 -150 37 -150 30z"/>
<path d="M4853 3242 c-61 -6 -33 -22 40 -22 39 0 112 -7 161 -16 48 -8 90 -13
93 -10 3 2 -25 14 -61 25 -64 20 -170 31 -233 23z"/>
<path d="M5410 3227 c7 -8 173 -47 178 -42 5 4 -92 32 -143 41 -22 3 -38 4
-35 1z"/>
<path d="M5640 3160 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4706 3151 c-3 -4 32 -11 77 -14 45 -3 122 -13 171 -22 49 -9 91 -13
93 -11 7 7 -92 35 -163 46 -73 12 -171 13 -178 1z"/>
<path d="M7328 3063 c-3 -54 -1 -120 2 -148 5 -34 8 -4 9 98 0 81 -1 147 -3
147 -2 0 -5 -44 -8 -97z"/>
<path d="M5450 3122 c8 -5 26 -13 40 -16 17 -5 20 -4 10 2 -8 5 -26 13 -40 16
-17 5 -20 4 -10 -2z"/>
<path d="M4690 3050 c69 -10 156 -26 195 -34 75 -16 96 -14 49 5 -73 29 -138
40 -249 44 l-120 5 125 -20z"/>
<path d="M9500 3040 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M9590 2960 c19 -13 30 -13 30 0 0 6 -10 10 -22 10 -19 0 -20 -2 -8
-10z"/>
<path d="M4450 2951 c0 -7 21 -11 58 -11 32 0 109 -9 172 -20 62 -12 118 -19
124 -17 6 2 -20 14 -59 27 -81 25 -295 41 -295 21z"/>
<path d="M4355 2851 c-35 -6 -28 -8 46 -14 104 -9 125 -13 212 -33 68 -16 95
-14 60 4 -57 30 -242 55 -318 43z"/>
<path d="M7883 2853 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M7938 2853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M9505 2849 c3 -4 18 -9 34 -11 17 -2 28 -1 26 3 -3 4 -18 9 -34 11
-17 2 -28 1 -26 -3z"/>
<path d="M9328 2813 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M9383 2803 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4305 2764 c39 -8 121 -26 183 -40 62 -14 118 -24 125 -21 20 7 -122
47 -223 62 -122 19 -178 18 -85 -1z"/>
<path d="M8898 2723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5890 2453 c-36 -20 -92 -54 -125 -76 l-59 -40 -49 17 c-27 10 -51
16 -53 13 -3 -2 17 -12 43 -22 67 -25 68 -28 29 -58 -69 -53 -72 -53 -187 -4
-132 58 -186 71 -109 28 95 -54 209 -111 221 -111 6 0 44 31 84 68 39 38 120
101 178 141 59 40 107 75 107 77 0 9 -18 1 -80 -33z"/>
<path d="M5028 2463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5919 2379 l-24 -20 28 17 c15 9 27 18 27 20 0 8 -8 4 -31 -17z"/>
<path d="M7778 2153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2819 2065 c63 -31 115 -54 118 -52 3 4 -189 95 -217 103 -8 2 36
-21 99 -51z"/>
<path d="M2130 1997 c0 -23 13 -54 39 -95 43 -66 108 -142 122 -142 12 0 11
37 -2 62 -6 11 -9 22 -6 24 2 2 75 -41 162 -97 158 -101 246 -149 276 -149 26
0 42 25 31 47 -6 10 -52 42 -104 70 -125 70 -293 181 -293 195 0 7 20 17 45
23 76 20 77 20 54 33 -21 11 -182 45 -271 57 l-53 7 0 -35z"/>
<path d="M2950 2006 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M7812 1858 c-29 -29 -2 -72 40 -66 30 4 38 46 12 65 -22 16 -37 16
-52 1z"/>
<path d="M3042 1821 c-19 -12 -11 -45 12 -49 24 -5 37 17 25 40 -11 20 -18 22
-37 9z"/>
<path d="M3940 1705 c36 -19 70 -34 75 -34 6 0 -19 15 -55 34 -36 19 -69 34
-75 34 -5 0 19 -15 55 -34z"/>
<path d="M4140 1676 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4280 1660 c19 -11 40 -19 45 -19 6 0 -6 8 -25 19 -19 11 -39 19 -45
19 -5 0 6 -8 25 -19z"/>
<path d="M7472 1648 c-19 -19 -14 -46 12 -63 27 -18 53 -11 61 16 14 43 -41
79 -73 47z"/>
<path d="M2902 1564 c-29 -20 -28 -59 3 -89 26 -27 73 -33 93 -13 22 22 14 75
-14 97 -32 25 -52 26 -82 5z"/>
<path d="M7162 1504 c-12 -8 -22 -22 -22 -30 0 -16 35 -54 50 -54 13 0 50 37
50 50 0 12 -37 50 -48 50 -4 0 -18 -7 -30 -16z"/>
<path d="M3172 1434 c-31 -21 -29 -67 3 -92 43 -34 95 -10 95 44 0 34 -28 64
-60 64 -9 0 -26 -7 -38 -16z"/>
<path d="M6831 1376 c-7 -8 -11 -27 -9 -43 2 -23 8 -29 35 -31 24 -2 34 2 43
18 23 44 -39 93 -69 56z"/>
<path d="M3380 1335 c-20 -24 -8 -50 26 -53 38 -4 49 16 28 47 -18 25 -37 27
-54 6z"/>
<path d="M6560 1290 c-15 -29 -4 -59 27 -66 36 -10 60 17 47 53 -12 36 -57 44
-74 13z"/>
<path d="M3503 1284 c-14 -37 41 -71 66 -40 9 11 10 20 1 35 -13 25 -58 28
-67 5z"/>
<path d="M3650 1245 c-15 -19 -4 -52 21 -60 38 -12 65 35 37 63 -16 16 -43 15
-58 -3z"/>
<path d="M6300 1211 c0 -34 22 -55 49 -46 46 14 31 75 -19 75 -26 0 -30 -4
-30 -29z"/>
<path d="M3800 1205 c-20 -24 -8 -50 26 -53 39 -4 53 22 28 49 -20 23 -37 24
-54 4z"/>
<path d="M3952 1168 c-32 -32 8 -80 48 -58 23 12 25 23 10 51 -12 21 -40 25
-58 7z"/>
<path d="M4102 1138 c-18 -18 -15 -56 7 -63 44 -14 72 24 41 55 -23 23 -31 25
-48 8z"/>
<path d="M4252 1108 c-32 -32 2 -79 43 -60 29 13 32 34 9 56 -19 19 -36 20
-52 4z"/>
<path d="M4404 1076 c-12 -31 0 -51 31 -51 27 0 30 3 30 30 0 25 -4 30 -28 33
-17 2 -29 -2 -33 -12z"/>
<path d="M4564 1056 c-12 -31 0 -51 31 -51 27 0 30 3 30 30 0 25 -4 30 -28 33
-17 2 -29 -2 -33 -12z"/>
<path d="M4740 1041 c-13 -25 -4 -48 22 -57 29 -9 52 18 44 50 -8 32 -50 36
-66 7z"/>
<path d="M5570 1045 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z"/>
<path d="M4941 1032 c-8 -16 -7 -25 7 -42 16 -20 21 -21 41 -11 33 18 26 65
-11 69 -18 2 -30 -3 -37 -16z"/>
<path d="M5147 1043 c-12 -12 -7 -50 8 -63 32 -27 82 23 55 55 -13 15 -51 20
-63 8z"/>
<path d="M5357 1043 c-14 -13 -6 -53 12 -63 38 -20 71 13 51 51 -10 18 -50 26
-63 12z"/>
</g>
</svg>
  )
}

export const Case8SVG = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">

<g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill="#fae7e7" stroke="none">
<path d="M3070 9060 c-98 -17 -173 -77 -311 -248 -91 -113 -114 -158 -114
-222 0 -62 17 -52 -155 -97 -113 -30 -183 -69 -209 -116 -35 -64 5 -49 -596
-208 -605 -160 -616 -164 -667 -263 -44 -85 -26 -161 122 -521 32 -77 88 -214
125 -305 37 -91 90 -217 117 -280 28 -63 67 -158 88 -211 l39 -97 -82 -83
c-99 -101 -190 -198 -317 -339 -52 -58 -124 -135 -158 -172 -37 -38 -62 -73
-60 -83 3 -17 198 176 483 478 l149 159 19 -37 c22 -43 37 -19 -133 -205 -63
-69 -191 -211 -285 -315 -93 -105 -187 -208 -207 -230 -47 -50 -51 -81 -5 -39
136 123 583 596 651 690 10 13 14 10 28 -20 16 -33 16 -36 -5 -65 -12 -16 -60
-72 -106 -123 -358 -395 -536 -608 -508 -608 5 0 275 276 362 370 43 47 102
110 130 140 28 30 76 85 108 123 l56 67 16 -29 c8 -17 15 -32 15 -35 0 -7
-155 -188 -260 -306 -91 -101 -259 -303 -285 -343 -10 -15 -14 -31 -11 -35 7
-7 58 44 225 223 134 144 165 179 260 287 83 95 96 106 106 90 26 -39 24 -49
-22 -93 -115 -112 -443 -521 -443 -552 0 -7 4 -18 9 -25 6 -10 17 -2 42 30
110 140 447 528 459 528 4 0 12 -11 18 -25 11 -24 7 -31 -61 -108 -156 -176
-407 -526 -407 -568 0 -33 16 -21 76 59 132 175 399 493 421 500 7 2 16 -3 19
-11 3 -9 -32 -64 -91 -142 -142 -185 -289 -435 -269 -455 18 -18 48 15 113
123 85 141 265 367 293 367 23 0 -2 -50 -79 -160 -85 -120 -203 -336 -203
-369 0 -60 49 -11 119 118 44 82 100 162 180 261 44 54 20 -13 -49 -140 -113
-206 -141 -280 -105 -280 16 0 43 38 106 156 59 110 119 204 130 204 12 0 -44
-146 -131 -337 -80 -176 30 -90 122 96 47 94 53 102 62 81 13 -29 -8 -90 -69
-197 -29 -51 -43 -86 -39 -97 9 -23 34 -16 53 15 25 40 93 139 97 139 2 0 10
-15 19 -34 15 -31 18 -33 53 -26 46 9 72 20 72 30 0 15 58 32 77 22 18 -10 70
-16 106 -13 35 3 21 47 -117 356 -37 81 -406 975 -406 982 0 2 -31 77 -69 166
-348 819 -396 937 -384 956 5 8 77 35 159 59 82 24 203 59 269 78 66 19 124
39 130 45 5 5 22 9 37 9 21 0 29 6 34 26 9 35 20 15 27 -53 7 -59 48 -147 76
-163 47 -25 134 -8 416 77 83 25 206 62 275 83 69 21 222 67 340 103 118 36
289 86 380 112 91 25 248 72 350 105 102 32 290 88 419 124 128 37 258 76 289
89 94 37 126 113 81 193 -11 20 -17 40 -12 44 13 12 105 39 153 46 l45 5 28
-53 c15 -29 71 -136 124 -238 52 -102 120 -230 149 -286 30 -56 54 -106 54
-112 0 -6 -15 -14 -32 -17 -111 -24 -590 -179 -753 -243 -90 -36 -51 -65 55
-42 76 17 576 173 673 210 43 16 82 30 87 30 4 0 31 -45 59 -99 l51 -100 -55
-20 c-31 -12 -60 -21 -66 -21 -19 0 -433 -141 -446 -152 -21 -17 -15 -28 15
-28 33 0 209 42 272 65 39 14 299 95 306 95 5 0 94 -168 94 -178 0 -5 -69 -28
-152 -52 -84 -23 -209 -62 -278 -86 -234 -83 -424 -145 -675 -220 -137 -42
-333 -102 -435 -134 -102 -32 -246 -77 -320 -98 -551 -163 -1243 -379 -1328
-414 -77 -32 -102 -57 -77 -78 23 -19 106 1 395 95 458 150 746 233 774 222 7
-3 34 -60 60 -127 52 -134 52 -131 -14 -150 -19 -5 -46 -17 -59 -26 -35 -25
-9 -33 57 -18 31 8 64 14 73 14 14 0 68 -109 114 -232 5 -12 -4 -21 -40 -37
-34 -15 -46 -25 -43 -38 3 -15 14 -18 65 -16 l61 1 57 -128 c32 -71 55 -135
52 -142 -3 -8 -25 -20 -48 -27 -24 -7 -229 -76 -456 -152 -227 -76 -416 -139
-421 -139 -8 0 -32 53 -118 257 l-14 32 178 56 c97 31 234 74 303 96 141 45
173 62 149 79 -34 24 -130 2 -457 -104 -95 -31 -180 -56 -188 -56 -9 0 -21 13
-26 28 -5 16 -32 76 -59 133 -36 75 -46 105 -37 110 6 4 77 25 157 48 249 69
465 144 465 161 0 8 -7 14 -15 14 -8 -1 -149 -43 -313 -93 -164 -50 -309 -91
-322 -91 -13 0 -25 -4 -27 -8 -1 -5 -23 -16 -47 -24 -66 -21 -68 -43 -3 -34
l50 6 51 -129 c28 -71 51 -133 51 -139 0 -5 -10 -12 -22 -15 -13 -4 -32 -13
-43 -20 -17 -13 -17 -17 -5 -31 10 -13 26 -16 63 -14 l49 3 60 -144 c33 -79
56 -147 51 -151 -4 -3 -29 -14 -55 -24 -51 -19 -67 -42 -44 -64 12 -12 26 -13
75 -5 l60 9 25 -45 c30 -56 86 -196 86 -216 0 -7 -9 -17 -20 -20 -29 -9 -50
-33 -44 -50 4 -10 21 -15 54 -15 26 0 52 -6 57 -12 6 -7 29 -56 52 -108 23
-52 52 -115 65 -140 l24 -46 -44 -18 c-46 -19 -70 -47 -60 -72 8 -22 45 -17
153 20 54 18 119 37 144 40 101 15 256 68 384 133 109 55 134 72 138 92 10 53
-14 64 -81 35 -20 -9 -91 -35 -157 -59 -66 -24 -165 -60 -220 -80 -154 -57
-208 -74 -216 -69 -11 6 -109 251 -109 271 0 23 30 38 195 100 182 69 233 83
279 77 31 -4 66 3 146 31 58 20 134 46 170 57 45 14 76 31 100 57 22 22 57 43
90 54 30 10 96 38 145 61 50 24 102 48 116 54 31 12 43 46 23 63 -16 13 -65
-1 -259 -75 -241 -92 -1028 -366 -1050 -366 -14 0 -125 263 -116 273 5 4 48
23 97 41 146 54 809 276 826 276 8 0 26 -17 39 -37 13 -20 29 -36 34 -34 6 1
9 24 8 51 l-2 49 47 15 c26 8 74 26 107 39 49 20 61 30 63 51 6 46 -44 45
-191 -6 -32 -11 -61 -18 -63 -15 -8 7 -124 259 -124 267 0 13 467 170 505 170
17 0 130 -244 120 -260 -3 -4 -39 -20 -80 -35 -60 -22 -77 -33 -81 -52 -12
-45 8 -49 111 -24 l95 23 19 -23 c16 -20 21 -21 34 -10 9 7 17 24 19 38 3 24
12 29 113 59 209 62 270 82 334 108 l63 25 -36 -42 c-20 -23 -106 -127 -190
-232 -168 -207 -232 -281 -342 -395 -40 -41 -100 -108 -133 -148 -34 -40 -70
-83 -81 -94 -21 -23 -24 -31 -13 -41 11 -11 41 14 182 149 228 220 557 575
776 839 50 60 110 132 135 160 76 86 165 187 215 245 90 104 178 190 200 196
27 7 107 -13 285 -71 74 -24 180 -58 235 -74 55 -17 199 -60 320 -97 121 -37
285 -86 365 -109 191 -56 637 -192 985 -300 154 -48 339 -104 410 -125 194
-58 271 -86 341 -125 101 -57 99 -81 -22 -233 -39 -48 -76 -85 -90 -88 -27 -7
-30 -14 -12 -32 8 -8 5 -20 -16 -49 -47 -67 -55 -75 -65 -69 -12 8 -49 -39
-57 -71 -4 -13 -25 -53 -48 -88 -22 -35 -57 -89 -76 -119 -19 -31 -38 -56 -41
-56 -3 0 -32 -38 -63 -85 -31 -46 -61 -87 -66 -90 -5 -3 -31 -42 -57 -86 -26
-45 -58 -92 -69 -105 -12 -12 -20 -25 -17 -27 8 -9 -29 -71 -53 -90 -13 -10
-24 -28 -24 -39 0 -11 -20 -45 -44 -76 -24 -32 -46 -64 -49 -73 -3 -9 -29 -53
-59 -97 -29 -45 -73 -113 -98 -152 -25 -40 -55 -83 -67 -96 -12 -13 -35 -43
-52 -66 -16 -24 -53 -65 -81 -93 -36 -34 -63 -74 -86 -123 -19 -41 -54 -102
-78 -135 -24 -34 -55 -82 -69 -107 -15 -25 -56 -79 -93 -120 -37 -41 -100
-126 -141 -187 -75 -115 -108 -152 -57 -65 15 26 56 91 91 145 34 53 59 97 54
97 -16 0 -169 -197 -197 -253 -15 -29 -46 -75 -69 -102 -72 -85 -155 -206
-155 -226 0 -46 30 -18 112 105 63 94 61 73 -3 -34 -28 -47 -55 -96 -60 -109
-5 -13 -19 -37 -30 -53 -20 -28 -22 -29 -41 -11 -24 21 -44 11 -72 -37 -21
-36 -11 -35 31 3 12 11 29 17 38 13 20 -7 -3 -44 -125 -206 -75 -99 -142 -194
-187 -266 -10 -16 -13 -17 -22 -4 -14 20 -36 -2 -27 -25 5 -14 9 -15 21 -5 8
7 15 8 15 2 0 -21 -90 -90 -143 -111 -49 -18 -64 -19 -107 -10 -27 6 -66 21
-85 35 -20 13 -44 24 -53 24 -9 0 -42 14 -72 31 -30 17 -93 51 -140 76 -97 52
-136 81 -83 63 17 -7 42 -10 55 -8 19 2 14 8 -32 34 -30 17 -81 41 -113 55
-57 23 -60 23 -72 5 -15 -20 -45 -15 -66 10 -10 13 -10 17 2 24 12 8 12 12 -3
26 -18 18 -42 10 -34 -12 3 -9 -1 -14 -14 -14 -24 0 -74 51 -58 61 17 11 -1
29 -29 29 -20 0 -24 -4 -20 -20 7 -26 -6 -25 -54 2 -40 22 -47 32 -29 43 14 8
13 25 -2 25 -6 0 -19 -7 -29 -16 -16 -14 -20 -14 -48 1 -26 13 -30 19 -21 35
5 10 10 21 10 24 0 11 -36 6 -55 -8 -18 -12 -32 -7 -135 50 -63 35 -225 115
-360 178 -135 63 -374 178 -532 256 -271 134 -287 143 -277 163 9 16 8 23 -7
34 -24 17 -43 0 -24 -22 7 -8 11 -18 8 -20 -5 -5 -187 82 -503 241 -123 61
-165 88 -182 113 -26 38 -22 81 11 122 12 14 57 71 100 125 44 55 84 104 90
109 6 6 38 42 71 80 33 39 112 129 175 201 63 71 156 184 207 250 50 66 118
149 150 184 151 169 217 255 210 274 -6 15 -34 15 -61 -1 -47 -27 -342 -336
-466 -487 -30 -37 -105 -125 -165 -194 -143 -164 -177 -207 -261 -322 -38 -53
-73 -97 -78 -98 -5 -2 -23 41 -41 95 -55 168 -64 185 -94 181 -21 -2 -26 -8
-27 -33 -1 -16 -2 -37 -3 -45 0 -8 -28 -51 -61 -95 -33 -44 -60 -86 -60 -93 0
-19 157 -239 246 -345 13 -16 43 -39 66 -51 35 -18 51 -38 99 -126 77 -138
113 -179 196 -222 86 -45 392 -185 583 -267 80 -34 170 -75 200 -91 30 -17 97
-51 147 -77 51 -25 91 -48 88 -51 -2 -3 -39 -16 -82 -31 -100 -34 -110 -56
-22 -48 35 3 95 13 132 21 59 14 72 14 95 2 32 -17 38 -15 -122 -50 -165 -37
-206 -59 -151 -84 21 -10 54 -6 191 23 91 19 181 35 199 35 65 0 43 -16 -48
-34 -82 -17 -293 -67 -339 -82 -10 -3 -18 -12 -18 -20 0 -27 113 -14 350 40
126 29 205 40 224 29 15 -8 -4 -16 -93 -37 -106 -26 -427 -129 -439 -140 -3
-3 4 -6 15 -6 25 0 302 54 383 75 105 27 258 47 277 36 15 -8 14 -10 -12 -16
-142 -33 -328 -82 -415 -110 -58 -19 -125 -39 -149 -45 -23 -6 -41 -14 -38
-16 7 -7 132 14 457 77 230 45 269 50 295 39 27 -11 28 -13 10 -20 -11 -4
-108 -29 -215 -55 -185 -45 -415 -113 -408 -121 5 -4 347 52 398 66 19 5 49
12 67 15 18 3 63 12 100 20 167 37 178 38 219 19 l38 -18 -29 -7 c-17 -4 -88
-19 -160 -34 -158 -33 -362 -85 -510 -130 -60 -18 -119 -36 -130 -39 -68 -18
167 20 430 70 432 83 512 93 529 65 3 -4 -43 -18 -102 -30 -100 -22 -457 -110
-727 -181 -199 -52 -195 -69 6 -24 63 14 166 36 229 49 63 13 178 37 255 54
310 65 450 83 484 62 16 -10 15 -10 -89 -35 -234 -55 -384 -91 -515 -126 -82
-22 -226 -60 -319 -85 -92 -24 -171 -46 -174 -49 -24 -24 57 -11 303 49 135
34 314 72 700 151 139 29 191 35 228 30 26 -4 45 -11 42 -15 -2 -5 -33 -14
-67 -20 -174 -34 -701 -171 -738 -192 -32 -18 41 -8 255 37 121 25 254 52 295
59 41 8 126 23 189 34 123 23 187 26 195 12 3 -5 -11 -12 -32 -16 -119 -21
-605 -149 -632 -167 -22 -13 55 -2 255 37 420 83 507 97 529 89 29 -11 7 -22
-67 -34 -76 -13 -474 -125 -567 -160 -53 -20 -54 -20 -15 -15 41 4 146 24 385
70 354 68 405 75 398 54 -3 -9 -55 -25 -144 -45 -76 -17 -220 -54 -319 -82
-325 -93 -364 -117 -102 -63 78 16 211 43 296 61 85 17 188 40 230 50 88 23
131 25 186 6 l39 -14 -64 -20 c-36 -11 -114 -30 -175 -41 -120 -24 -217 -50
-395 -106 -269 -84 -85 -68 300 27 174 43 204 53 195 69 -6 8 26 14 65 11 8 0
62 8 120 20 86 16 117 28 168 61 44 28 68 38 77 31 59 -44 65 -46 80 -34 8 7
15 17 15 23 0 6 14 26 32 44 40 42 31 52 -17 18 -35 -23 -105 -35 -105 -17 0
4 13 22 28 41 27 32 30 33 67 22 49 -13 55 -8 25 24 -34 36 -22 53 37 48 36
-4 52 -1 62 10 16 20 7 36 -31 51 l-30 13 24 26 c23 27 23 27 67 12 47 -17 76
-13 85 12 8 19 -7 31 -46 40 -29 6 -37 26 -15 40 6 4 44 8 82 8 67 0 70 1 70
24 0 20 -7 26 -35 33 -63 16 -62 15 -43 44 16 25 20 26 73 20 45 -5 60 -3 70
9 16 19 7 29 -43 45 -55 17 -37 28 38 22 61 -4 65 -3 65 16 0 15 -10 24 -35
33 -36 13 -45 31 -22 46 6 4 41 8 77 8 54 0 65 3 68 18 3 12 -8 23 -37 35 -38
18 -40 20 -25 40 11 16 27 22 71 24 70 4 78 20 23 48 l-41 21 32 44 31 44 34
-17 c42 -19 54 -9 26 21 -36 38 -23 69 19 47 29 -16 69 -4 69 20 0 10 -9 21
-19 25 -32 10 -46 32 -30 51 9 12 24 14 58 10 56 -8 81 5 65 31 -6 10 -20 20
-30 22 -10 2 -25 8 -31 13 -23 18 -2 29 42 23 81 -13 109 31 39 60 -57 24 -43
39 30 31 49 -5 66 -3 76 8 10 13 6 19 -22 36 -18 11 -36 20 -40 20 -5 0 -8 7
-8 15 0 12 13 15 72 13 62 -3 73 0 76 14 3 17 -10 26 -60 42 -17 5 -17 7 0 39
15 31 20 33 44 25 14 -5 43 -8 65 -6 53 4 49 27 -11 56 -26 13 -46 25 -43 28
3 3 29 1 57 -5 57 -12 96 -7 104 15 6 15 -9 25 -66 43 -21 7 -38 15 -38 17 0
2 10 20 23 39 17 28 26 34 42 29 11 -4 41 -9 68 -11 43 -5 47 -3 47 16 0 16
-13 27 -51 44 l-51 22 18 22 c17 20 24 22 59 13 79 -18 94 -19 105 -5 18 21 6
30 -55 45 -31 7 -54 18 -52 24 5 12 25 12 78 -2 37 -9 69 -1 69 19 0 6 -23 20
-50 32 -45 20 -49 24 -38 40 11 14 19 15 48 7 49 -14 86 -12 100 5 17 21 1 39
-50 54 -22 6 -40 14 -40 17 0 10 46 74 53 74 4 0 20 -6 35 -14 15 -7 42 -17
60 -21 27 -6 32 -4 32 12 0 15 -14 24 -55 37 -86 27 -71 55 20 36 90 -19 99
13 14 49 l-49 22 24 37 c14 21 30 35 38 33 7 -3 37 -7 66 -9 71 -5 72 20 2 43
-27 9 -50 20 -50 24 0 22 30 31 107 31 46 0 83 4 83 9 0 17 -30 39 -62 46 -47
10 -49 13 -34 43 12 24 19 27 60 25 36 -1 49 3 61 20 16 23 12 37 -13 37 -32
0 -45 16 -33 38 9 18 15 19 55 10 83 -17 119 30 46 60 -22 9 -40 22 -40 27 1
6 14 25 31 43 25 28 35 32 69 30 30 -1 44 3 55 18 20 27 19 31 -15 43 -33 11
-38 26 -15 51 13 14 24 16 63 9 26 -5 53 -7 60 -4 21 8 -3 41 -38 53 -43 16
-38 33 10 30 52 -3 73 16 37 33 -29 13 -35 33 -14 46 6 4 41 10 77 13 56 4 65
8 65 25 0 14 -11 22 -44 32 -56 16 -57 17 -32 44 18 19 29 22 87 20 59 -2 65
-1 62 16 -2 11 -20 24 -50 33 -38 13 -45 19 -37 33 8 13 20 15 73 10 45 -4 65
-2 71 7 9 15 -7 26 -58 40 -59 17 -35 32 37 25 50 -6 64 -4 75 9 19 23 -3 46
-44 46 -44 0 -47 7 -25 51 11 21 20 55 20 76 0 57 -47 418 -66 499 -21 97 -42
128 -111 162 -65 33 -301 114 -468 162 -60 17 -188 56 -284 86 -96 31 -211 66
-255 79 -45 13 -270 80 -501 150 -710 215 -1096 327 -1250 365 -49 12 -119 31
-153 42 -42 13 -78 18 -105 15 -44 -6 -10 25 -419 -395 -154 -157 -159 -162
-225 -182 -37 -11 -115 -38 -173 -59 -115 -43 -398 -131 -418 -131 -16 0 -125
223 -120 247 2 13 40 29 143 61 192 60 451 142 627 197 80 26 160 57 178 70
18 13 39 21 50 18 24 -6 28 22 4 31 -43 16 -122 -3 -394 -94 -48 -16 -584
-182 -633 -196 -25 -8 -135 222 -115 241 12 12 178 67 453 150 61 18 223 68
360 109 138 42 263 77 279 79 27 2 32 -4 83 -103 91 -178 104 -194 118 -151 4
12 -7 46 -29 88 -58 115 -87 193 -73 198 7 3 53 17 102 33 101 33 155 58 155
72 0 6 13 10 29 10 35 0 49 -17 110 -136 55 -107 67 -122 81 -108 15 15 -16
88 -151 357 -67 134 -95 199 -87 202 91 31 127 48 132 62 12 31 -9 36 -100 23
-82 -12 -88 -11 -102 6 -18 25 -82 151 -82 163 0 9 100 61 118 61 7 0 12 5 12
10 0 14 -23 12 -39 -2 -10 -10 -15 -10 -18 -2 -3 8 -15 9 -43 2 -22 -6 -46 -8
-54 -5 -21 8 -111 190 -97 195 7 2 24 14 39 27 15 12 42 26 60 29 45 10 40 27
-5 21 -21 -2 -61 -7 -90 -10 l-51 -5 -48 87 c-26 49 -55 102 -64 118 -155 279
-254 475 -243 485 15 13 308 94 342 94 l34 1 91 -175 c50 -96 136 -260 190
-365 54 -104 148 -284 208 -398 61 -115 135 -263 165 -330 52 -118 268 -553
312 -630 11 -21 21 -46 21 -57 0 -27 38 -52 62 -39 34 18 21 67 -62 229 -44
85 -165 326 -269 535 -103 209 -222 441 -263 515 -41 74 -148 280 -238 458
-123 244 -168 324 -184 328 -12 3 -41 4 -66 2 l-45 -3 -51 106 c-88 180 -127
191 -424 111 -102 -27 -246 -64 -320 -82 -74 -18 -177 -43 -227 -55 l-93 -23
-36 29 c-58 46 -96 44 -338 -17 -99 -24 -189 -46 -200 -48 -47 -7 -70 13 -136
121 -85 140 -106 155 -215 151 -44 -1 -96 -5 -115 -8z m199 -69 c10 -6 38 -45
61 -88 94 -175 138 -201 271 -163 19 6 95 24 169 40 74 17 153 35 175 40 22 6
57 9 78 7 30 -2 41 -9 55 -34 28 -52 94 -156 111 -176 18 -21 49 -18 145 15
68 24 148 20 190 -7 27 -18 106 -164 106 -196 0 -40 -87 -81 -245 -113 -39 -8
-83 -20 -98 -25 -56 -22 -64 -8 -14 26 17 12 88 36 157 54 69 18 130 37 137
42 24 18 -33 119 -79 138 -15 6 -40 8 -55 5 -95 -23 -101 -26 -112 -56 -12
-36 -30 -40 -24 -6 4 21 1 23 -36 23 -22 0 -54 6 -71 13 -30 13 -91 87 -143
175 l-28 47 -52 -7 c-29 -4 -132 -27 -229 -51 -226 -56 -255 -57 -311 -5 -23
21 -63 74 -89 117 -57 95 -101 144 -131 144 -33 0 -84 -35 -102 -70 -9 -17
-20 -80 -26 -142 -17 -183 -27 -194 -242 -264 -140 -45 -140 -57 0 -19 54 15
116 31 138 36 22 5 51 12 65 15 16 3 21 2 15 -4 -5 -5 -107 -42 -226 -82 -233
-78 -239 -81 -239 -150 0 -41 -24 -45 274 39 82 23 150 40 152 38 4 -3 -156
-57 -326 -108 -41 -13 -81 -27 -87 -32 -19 -14 -16 -57 3 -57 17 0 262 70 333
95 24 8 49 15 55 15 21 -1 -49 -27 -189 -69 -183 -55 -200 -62 -183 -72 8 -5
9 -12 3 -18 -15 -15 -195 -84 -314 -121 -81 -24 -113 -40 -138 -65 -39 -39
-41 -59 -13 -147 25 -80 25 -86 -2 -84 -19 1 -26 13 -44 76 -41 137 -27 213
44 249 20 10 96 37 167 60 72 22 135 44 142 49 23 15 36 69 38 162 1 52 3 102
3 112 3 23 65 50 242 104 183 55 223 73 238 111 25 61 43 153 49 246 l6 99 46
21 c51 24 150 34 180 18z m1771 -71 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m-2070 -60 c-5 -29 -10 -78 -10 -109 0 -70
-24 -113 -79 -141 -79 -40 -95 -22 -48 52 44 69 82 145 91 183 7 28 45 79 53
72 2 -2 -1 -28 -7 -57z m2005 -30 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6
8 10 10 10 2 0 7 -4 10 -10z m-1730 -60 c15 -17 15 -21 0 -49 -28 -56 -98 -28
-79 32 15 48 45 55 79 17z m1009 -16 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0
14 7 11 14 -7z m126 10 c0 -8 -19 -13 -24 -6 -3 5 1 9 9 9 8 0 15 -2 15 -3z
m-920 -284 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20
-4 20 -10z m-993 -82 c-3 -13 -7 -61 -8 -108 -2 -117 -8 -125 -98 -140 l-43
-7 6 75 c7 91 41 157 96 184 46 23 53 23 47 -4z m685 -10 c-7 -7 -12 -8 -12
-2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-217 -226 c-12 -11 -87 -32 -106
-31 -8 1 11 9 41 19 64 21 78 24 65 12z m-698 -34 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m1158 -68 c-11 -5 -27 -9 -35 -9 -13 -1 -13 0 0 9
8 5 24 9 35 9 l20 0 -20 -9z m-58 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-1417 -62 c0 -7 -4 -10 -10 -7 -5 3 -10 16 -10 28 0 18 2 19
10 7 5 -8 10 -21 10 -28z m-241 -78 c1 -15 -56 -23 -69 -10 -17 17 -11 28 10
21 12 -4 20 -2 20 5 0 6 6 17 13 24 10 12 13 10 19 -8 3 -11 6 -26 7 -32z
m1186 -9 c-88 -29 -214 -69 -280 -89 -66 -20 -166 -52 -222 -71 -99 -32 -139
-32 -97 1 31 23 356 120 659 197 123 31 95 14 -60 -38z m-1559 -2 c-10 -10
-19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-170 -89 c-4 -9 -9 -15 -11
-12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m219 -73 c3 -5 1 -12
-5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-135 -55 c0 -15 -14 -35 -21 -30
-15 9 -10 34 6 34 8 0 15 -2 15 -4z m107 -117 c7 -13 40 -91 74 -174 75 -185
124 -303 234 -560 47 -110 153 -369 236 -575 82 -206 181 -449 219 -540 91
-218 112 -272 105 -278 -6 -6 -42 75 -86 191 -17 45 -44 111 -61 147 -17 36
-50 112 -75 170 -25 58 -59 132 -76 165 -40 79 -145 320 -327 751 -230 542
-293 702 -285 722 7 19 28 10 42 -19z m4258 -334 c48 -82 73 -143 68 -167 -3
-13 -12 -15 -41 -12 -24 3 -81 -8 -165 -32 -70 -20 -130 -35 -132 -33 -9 10
-105 214 -105 224 0 16 271 95 314 91 13 -1 34 -25 61 -71z m-385 -141 c28
-58 50 -111 50 -118 0 -13 -24 -21 -280 -100 -180 -55 -333 -104 -609 -195
-118 -38 -218 -68 -223 -65 -5 3 -27 42 -49 87 -40 79 -74 127 -91 127 -18 0
-6 -52 33 -145 22 -54 39 -98 37 -99 -2 -1 -37 -15 -78 -30 -86 -33 -123 -53
-115 -65 6 -11 60 -4 152 19 l72 18 51 -121 c38 -92 47 -123 38 -129 -18 -11
-471 -158 -489 -158 -9 0 -35 48 -67 122 -60 139 -59 145 28 167 27 7 53 17
56 22 10 16 -23 21 -63 9 -100 -30 -93 -35 -156 105 -31 69 -57 129 -57 134 0
5 46 21 103 36 56 15 230 70 387 121 157 51 332 107 390 124 58 18 152 47 210
65 293 93 567 172 608 174 7 1 35 -47 62 -105z m3560 -1700 c0 -8 -4 -14 -8
-14 -4 0 -8 9 -8 19 0 11 4 17 8 15 4 -3 8 -12 8 -20z m-6495 -84 c7 -22 6
-23 -9 -11 -9 7 -16 25 -16 40 1 29 11 17 25 -29z m6495 -29 c-1 -20 -7 -47
-15 -61 -14 -23 -15 -21 -13 47 2 55 6 69 16 61 6 -6 12 -27 12 -47z m-60 18
c0 -17 -4 -28 -10 -24 -5 3 -10 17 -10 31 0 13 5 24 10 24 6 0 10 -14 10 -31z
m-58 -114 c-3 -48 -7 -64 -13 -50 -10 25 -11 119 -2 128 15 15 20 -11 15 -78z
m57 8 c0 -54 -12 -96 -20 -72 -8 22 2 119 12 119 5 0 9 -21 8 -47z m-110 -108
c1 -49 -2 -65 -9 -55 -7 11 -10 -1 -10 -39 0 -30 -2 -52 -5 -49 -3 3 -3 61 1
129 8 130 22 139 23 14z m-68 -65 c-9 -23 -10 -23 -10 11 -1 20 2 39 7 43 11
12 14 -27 3 -54z m-42 -97 c-2 -76 -2 -76 -9 -28 -12 72 -11 105 0 105 6 0 9
-32 9 -77z m38 -35 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z
m-106 -58 l-8 -95 -2 87 c0 47 2 94 6 104 12 31 13 2 4 -96z m-60 -161 c-8
-19 -10 -13 -7 31 3 30 6 75 7 100 2 30 4 20 6 -31 2 -41 -1 -86 -6 -100z
m-54 -76 c-2 -32 -3 -6 -3 57 0 63 1 89 3 58 2 -32 2 -84 0 -115z m-47 -11 c0
-48 -5 -94 -10 -102 -7 -10 -10 20 -10 93 0 70 4 106 10 102 6 -3 10 -45 10
-93z m-76 -124 c-5 -68 -10 -112 -12 -98 -5 32 8 233 15 226 3 -2 1 -60 -3
-128z m-68 -90 c-3 -72 -8 -134 -11 -136 -7 -8 -6 200 1 237 13 63 16 28 10
-101z m-58 -83 c-1 -70 -1 -70 -8 -20 -5 28 -8 70 -8 95 0 37 1 40 8 20 4 -14
8 -56 8 -95z m-59 -95 c0 -36 -4 -74 -8 -85 -13 -32 -20 28 -13 114 6 90 21
70 21 -29z m-72 -87 c-2 -21 -4 -6 -4 32 0 39 2 55 4 38 2 -18 2 -50 0 -70z
m-62 -211 c-12 -99 -17 -44 -9 90 9 131 9 132 12 48 2 -47 0 -109 -3 -138z
m-46 81 c-1 -69 -2 -74 -11 -43 -9 31 -3 120 8 120 2 0 4 -35 3 -77z m-57
-213 l-9 -85 -2 131 c0 72 2 134 7 138 11 12 14 -90 4 -184z m53 33 c-10 -92
-16 -72 -8 23 4 43 9 70 11 60 2 -10 1 -48 -3 -83z m-126 -217 c-9 -8 -10 1
-5 38 3 27 7 99 8 160 2 100 3 96 5 -38 3 -104 0 -153 -8 -160z m-52 69 c-4
-96 -21 -184 -33 -172 -3 2 2 50 11 105 8 56 16 128 16 159 1 32 4 49 6 38 3
-11 3 -69 0 -130z m-56 -106 c-8 -20 -10 -16 -10 27 -1 28 2 73 7 100 7 48 7
48 10 -27 1 -41 -2 -86 -7 -100z m-44 -6 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18
2 -10 2 -26 0 -35z m-40 -143 l0 -75 -7 85 c-5 47 -9 90 -10 95 -1 6 -1 15 0
20 6 25 18 -58 17 -125z m58 33 c-3 -23 -11 -43 -16 -43 -6 0 -8 12 -5 28 20
108 20 107 24 83 2 -13 1 -44 -3 -68z m-154 -114 c-7 -104 -8 -112 -9 -49 -2
73 7 184 14 177 2 -2 0 -59 -5 -128z m49 -67 c-9 -13 -10 -9 -6 23 3 22 7 67
8 100 2 50 3 47 5 -23 2 -49 -1 -89 -7 -100z m-101 -9 c0 -59 -4 -112 -9 -118
-4 -5 -11 -26 -13 -45 -3 -19 -4 24 -2 95 4 116 10 175 21 175 2 0 3 -48 3
-107z m-74 -187 c-4 -49 -10 -98 -14 -110 -8 -20 -8 -20 -15 2 -8 24 1 201 11
237 13 43 24 -40 18 -129z m-3985 114 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
0 6 5 10 10 10 6 0 10 -4 10 -10z m70 -41 c0 -11 -4 -18 -10 -14 -5 3 -7 12
-3 20 7 21 13 19 13 -6z m3845 -246 c-3 -18 -10 -35 -15 -38 -9 -5 -6 67 8
215 l7 75 3 -110 c2 -60 0 -124 -3 -142z m-3759 201 c18 -14 18 -14 -6 -3 -31
14 -36 19 -24 19 6 0 19 -7 30 -16z m119 -54 c3 -6 -1 -7 -9 -4 -18 7 -21 14
-7 14 6 0 13 -4 16 -10z m3594 -120 c1 -64 -22 -139 -48 -154 -16 -10 -14 24
11 159 24 129 35 128 37 -5z m8 88 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-97 -199 c-7 -71 -15 -129 -18 -129 -5 0 5 162 15 238 13 94 15
17 3 -109z m-59 1 c-6 -28 -12 -72 -12 -98 -1 -26 -5 -60 -10 -77 -12 -44 -1
118 13 183 6 28 13 50 16 48 2 -3 -1 -28 -7 -56z m-65 -152 c-4 -73 -10 -136
-14 -140 -4 -4 -4 50 0 120 8 161 7 152 14 152 4 0 4 -60 0 -132z m-59 -108
c-4 -61 -11 -118 -17 -128 -11 -17 -8 72 6 191 11 87 17 48 11 -63z m-92 -125
c-6 -61 -17 -120 -24 -130 -11 -18 -12 -17 -7 10 3 17 10 66 16 110 21 164 33
172 15 10z m-94 -120 c-8 -36 -9 -37 -10 -10 -1 36 12 87 16 65 2 -9 -1 -33
-6 -55z m-110 -218 c-13 -13 -15 1 -4 30 7 17 8 17 11 -1 2 -10 -1 -23 -7 -29z
m-174 -50 c-54 -73 -78 -103 -71 -87 15 31 88 130 96 130 4 0 -7 -20 -25 -43z
m83 -18 c0 -20 -21 -49 -35 -49 -18 0 -18 2 -3 35 11 25 38 35 38 14z m-101
-108 c-13 -16 -28 -30 -32 -30 -4 -1 4 15 19 34 15 20 29 33 32 31 2 -3 -6
-18 -19 -35z m71 -22 c-5 -16 -23 -46 -40 -65 -17 -20 -33 -49 -37 -65 -8 -43
-25 -35 -20 10 3 28 17 56 48 95 24 30 47 55 51 56 4 0 3 -14 -2 -31z"/>
<path d="M3892 6631 c-12 -10 -19 -21 -15 -25 13 -13 39 -5 62 19 l23 25 -24
0 c-14 0 -35 -8 -46 -19z"/>
<path d="M7133 2915 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M7394 9030 c-250 -32 -408 -93 -628 -242 -201 -136 -393 -411 -455
-653 -37 -142 -42 -219 -26 -415 15 -200 140 -434 335 -630 141 -142 285 -230
499 -304 155 -54 223 -67 371 -73 416 -17 726 120 982 433 118 144 169 266
209 493 43 245 -5 525 -131 758 -80 147 -241 333 -367 422 -231 165 -528 244
-789 211z m291 -110 c-3 -5 -15 -10 -26 -10 -11 0 -17 5 -14 10 3 6 15 10 26
10 11 0 17 -4 14 -10z m70 0 c39 -15 21 -30 -20 -17 -19 6 -35 14 -35 19 0 11
23 10 55 -2z m-490 -20 c3 -5 -7 -12 -22 -15 -52 -12 -153 -57 -190 -86 -39
-32 -51 -32 -34 -1 32 60 223 140 246 102z m840 -130 c8 -13 -1 -13 -26 0 -10
6 -19 15 -19 21 0 11 35 -5 45 -21z m-511 -42 c3 -13 6 -48 6 -78 l0 -55 87
-23 c137 -35 287 -113 314 -163 9 -17 4 -27 -33 -67 -24 -26 -55 -62 -70 -81
l-26 -34 -49 32 c-57 38 -207 111 -228 111 -12 0 -15 -19 -17 -102 0 -57 -1
-130 -2 -163 l-1 -60 55 -18 c166 -56 206 -73 253 -105 80 -53 105 -81 141
-153 79 -158 46 -325 -87 -432 -91 -74 -171 -99 -315 -98 l-94 1 4 -105 c1
-58 -1 -106 -6 -108 -13 -4 -160 15 -167 22 -4 3 -4 52 0 109 l6 103 -25 5
c-135 29 -310 116 -353 176 l-19 26 28 39 c16 21 53 64 83 95 l54 57 45 -40
c55 -48 208 -127 221 -113 5 5 12 81 16 169 l7 160 -86 16 c-171 33 -316 131
-368 248 -28 62 -23 198 11 261 34 66 119 159 160 176 89 38 159 56 225 57
l71 2 7 67 c3 37 9 72 13 78 3 5 35 10 69 10 58 0 64 -2 70 -22z m421 -39 c12
-19 -5 -23 -22 -6 -16 16 -16 17 -1 17 9 0 20 -5 23 -11z m-1195 -9 c0 -5 -4
-10 -9 -10 -4 0 -38 -27 -75 -61 -82 -75 -87 -61 -7 21 54 55 91 75 91 50z
m1515 -117 c26 -26 64 -89 71 -120 7 -25 6 -27 -9 -14 -10 7 -17 19 -17 26 0
7 -18 33 -40 58 -39 44 -50 67 -31 67 5 0 17 -7 26 -17z m-1765 -290 c0 -10
-11 -36 -25 -58 -22 -36 -24 -38 -25 -15 0 23 33 90 45 90 3 0 5 -8 5 -17z
m2036 -500 c-8 -63 -26 -80 -26 -26 0 48 8 73 22 73 7 0 8 -17 4 -47z m-2123
3 c6 -15 -1 -26 -15 -26 -4 0 -8 9 -8 20 0 23 15 27 23 6z m84 -317 c23 -46
30 -84 13 -74 -9 6 -60 108 -60 121 0 19 28 -9 47 -47z m1943 -88 c-35 -75
-60 -107 -60 -77 0 31 77 163 88 151 2 -2 -11 -36 -28 -74z m-1640 -321 c16
-30 12 -32 -20 -8 -35 25 -43 56 -10 38 11 -6 24 -19 30 -30z m1398 18 c-18
-27 -48 -36 -48 -14 0 16 26 35 49 36 11 0 11 -4 -1 -22z m-284 -192 c-40 -24
-84 -36 -84 -23 0 15 94 57 99 44 2 -5 -5 -15 -15 -21z m-779 -24 c-6 -7 -35
18 -35 31 0 5 9 2 20 -8 11 -10 18 -20 15 -23z m325 -52 c-20 -13 -43 -13 -35
0 3 6 16 10 28 10 18 0 19 -2 7 -10z"/>
<path d="M7346 8376 c-51 -19 -104 -72 -112 -111 -7 -41 21 -110 55 -133 39
-27 121 -56 131 -46 4 5 10 74 12 154 l3 145 -25 2 c-14 2 -42 -4 -64 -11z"/>
<path d="M7550 7641 l0 -161 71 0 c80 0 116 16 144 62 26 45 25 126 -2 164
-28 39 -108 82 -167 90 l-46 7 0 -162z"/>
<path d="M6117 8933 c-2 -4 -7 -20 -11 -35 -3 -15 -21 -35 -41 -48 -102 -63
-110 -182 -16 -230 40 -20 41 -22 41 -70 0 -28 -2 -50 -5 -50 -2 0 -18 14 -35
30 -40 39 -87 42 -96 5 -9 -37 30 -94 83 -117 36 -16 43 -23 43 -48 0 -24 21
-60 35 -60 14 0 35 36 35 59 0 19 9 34 31 50 41 30 72 100 64 143 -8 40 -20
57 -62 87 -26 17 -33 30 -33 54 0 18 5 39 10 47 8 12 13 11 33 -8 31 -29 61
-28 75 3 10 21 8 29 -14 55 -14 16 -39 37 -54 47 -22 13 -30 25 -32 52 -2 28
-8 37 -25 39 -11 2 -23 0 -26 -5z m-27 -203 c0 -16 -4 -30 -9 -30 -13 0 -21
27 -15 45 10 25 24 17 24 -15z m80 -184 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10
14 0 12 5 21 10 21 6 0 10 -6 10 -14z"/>
<path d="M5607 8832 c-36 -40 -9 -102 44 -102 58 0 92 66 53 104 -21 22 -77
20 -97 -2z"/>
<path d="M1870 8620 c-22 -22 -25 -42 -10 -71 23 -41 110 -9 110 40 0 22 -35
51 -62 51 -10 0 -27 -9 -38 -20z"/>
<path d="M4140 7598 c-11 -18 -33 -63 -48 -100 -15 -37 -30 -68 -35 -68 -4 0
-47 25 -94 55 -48 30 -95 55 -104 55 -9 0 -27 -8 -39 -18 -21 -17 -21 -18 -3
-33 10 -9 64 -44 121 -78 56 -35 102 -64 102 -66 0 -1 -9 -31 -21 -66 -11 -35
-21 -86 -22 -114 -2 -47 0 -50 24 -53 25 -3 28 1 54 87 16 50 32 91 37 91 5 0
40 -18 78 -40 73 -43 104 -49 114 -25 7 19 -11 37 -102 100 -39 28 -71 55 -72
60 0 6 16 52 35 104 19 51 35 104 35 117 0 36 -36 31 -60 -8z"/>
<path d="M3571 7446 c-8 -9 -14 -90 -18 -234 -6 -214 -6 -220 14 -226 40 -13
62 12 63 71 0 18 9 25 43 33 23 6 64 18 92 26 l49 14 36 -35 c52 -51 92 -42
76 18 -6 23 -318 347 -334 347 -5 0 -15 -6 -21 -14z m142 -214 l28 -29 -38 -6
c-77 -13 -87 -4 -63 58 11 30 27 25 73 -23z"/>
<path d="M3395 7445 c-5 -2 -75 -24 -155 -50 -80 -26 -149 -50 -153 -54 -5 -5
-1 -25 7 -46 17 -41 7 -39 116 -20 l36 6 53 -163 c55 -166 72 -201 97 -191 31
12 26 67 -20 219 -30 98 -42 152 -36 158 6 6 36 20 67 31 70 26 84 45 63 83
-15 25 -47 37 -75 27z"/>
<path d="M4561 7377 c-30 -12 -56 -27 -58 -33 -7 -20 36 -23 85 -7 62 21 76
32 61 49 -15 18 -24 17 -88 -9z"/>
<path d="M937 7152 c-23 -25 -21 -49 3 -57 23 -7 60 10 60 30 0 45 -34 60 -63
27z"/>
<path d="M2730 7149 c-36 -11 -117 -36 -180 -55 -217 -66 -545 -176 -554 -185
-41 -39 112 -8 384 76 101 31 452 154 458 159 8 9 -9 26 -26 25 -9 0 -46 -9
-82 -20z"/>
<path d="M2585 6789 c-312 -96 -443 -149 -363 -149 62 0 473 122 526 156 12 8
22 19 22 24 0 18 -54 9 -185 -31z"/>
<path d="M8530 6806 c-6 -8 -10 -25 -8 -38 2 -17 10 -24 34 -26 44 -4 62 20
40 53 -18 28 -47 33 -66 11z"/>
<path d="M935 6644 c-22 -23 -15 -47 19 -59 23 -8 30 -6 42 10 18 24 18 30 -2
49 -20 20 -38 20 -59 0z"/>
<path d="M1158 6553 c-106 -99 -192 -193 -184 -200 4 -5 21 9 122 104 73 68
167 173 161 179 -3 3 -48 -34 -99 -83z"/>
<path d="M8375 6583 c-88 -22 -131 -82 -122 -167 7 -69 21 -97 64 -126 44 -30
109 -31 147 -3 32 23 62 77 71 126 18 95 -73 192 -160 170z m59 -89 c26 -26
19 -41 -21 -46 -42 -5 -73 0 -73 12 0 8 60 50 72 50 4 0 14 -7 22 -16z m6 -94
c0 -19 -34 -41 -58 -38 -35 4 -39 24 -6 35 37 12 64 14 64 3z"/>
<path d="M1354 6542 c-22 -16 -116 -114 -210 -218 -95 -104 -197 -217 -228
-251 -52 -57 -67 -83 -48 -83 8 0 255 249 327 330 58 65 190 223 199 238 11
18 3 15 -40 -16z"/>
<path d="M5660 6442 c-41 -42 -136 -149 -210 -237 -74 -88 -171 -200 -215
-250 -113 -126 -155 -180 -155 -197 0 -30 56 -53 365 -152 77 -24 158 -52 180
-62 35 -15 273 -95 1470 -500 154 -52 316 -107 360 -121 44 -14 138 -48 208
-76 l128 -50 24 24 c35 36 263 356 318 445 26 44 86 141 132 216 119 194 126
209 112 238 -17 32 -58 61 -103 71 -22 4 -84 24 -139 44 -105 38 -514 167
-845 267 -107 32 -258 78 -335 103 -77 24 -230 69 -340 100 -110 31 -234 67
-275 80 -41 14 -93 29 -115 34 -22 5 -121 30 -219 55 -99 25 -200 46 -225 46
-44 0 -49 -3 -121 -78z m184 -2 c10 -10 -33 -140 -46 -140 -4 0 -8 5 -8 10 0
6 -9 30 -20 52 -23 48 -25 69 -8 86 13 13 65 7 82 -8z m133 -28 l22 -7 -30
-63 c-22 -48 -33 -62 -45 -58 -9 3 -20 6 -26 6 -13 0 -3 58 18 99 16 32 22 34
61 23z m-252 -52 c-3 -5 4 -29 16 -53 l22 -45 -30 -32 -29 -31 -29 66 -28 66
28 29 c22 23 32 27 42 19 7 -6 10 -15 8 -19z m475 -48 c0 -4 -9 -8 -20 -9 -11
-1 -18 2 -15 8 7 11 35 12 35 1z m-575 -32 c-3 -5 3 -31 14 -56 23 -51 26 -74
11 -74 -5 0 -20 -12 -33 -26 l-24 -25 -15 23 c-8 13 -23 38 -32 55 -16 33 -16
33 20 73 20 22 43 40 51 40 8 0 11 -4 8 -10z m378 -65 c151 -41 198 -55 412
-121 44 -14 118 -36 165 -50 170 -51 510 -157 725 -226 121 -39 234 -74 250
-78 17 -5 79 -26 139 -49 60 -22 113 -38 118 -35 4 3 8 14 8 25 0 32 32 20 44
-17 6 -21 64 -54 94 -54 10 0 43 -9 74 -20 31 -11 64 -17 72 -14 32 12 16 -52
-40 -162 -66 -128 -70 -134 -97 -134 -11 0 -173 50 -361 111 -188 61 -431 140
-541 176 -516 167 -1387 457 -1401 467 -13 10 -6 21 46 76 33 36 74 84 91 108
16 23 32 42 35 42 3 0 78 -20 167 -45z m-482 -105 c7 -22 10 -41 8 -43 -2 -2
-21 -15 -41 -30 -45 -33 -56 -34 -64 -2 -6 26 9 59 49 102 25 28 33 23 48 -27z
m374 -209 c143 -49 664 -222 1158 -385 l897 -298 -16 -28 c-16 -27 -18 -27
-50 -14 -32 13 -450 152 -854 283 -102 33 -248 78 -325 101 -77 23 -232 71
-345 107 -343 109 -520 163 -620 189 -126 33 -200 56 -200 64 0 3 14 22 31 43
22 26 37 36 48 32 9 -4 133 -46 276 -94z m-502 73 c8 -9 -11 -44 -24 -44 -5 0
-20 -9 -32 -20 -32 -30 -33 -6 -2 35 26 33 44 42 58 29z m-83 -146 c0 -17 -54
-88 -67 -88 -19 0 -63 22 -63 32 0 5 18 28 41 53 40 43 42 44 65 28 13 -8 24
-20 24 -25z m364 -60 c11 -16 13 -24 4 -29 -22 -14 -58 10 -58 39 0 20 38 14
54 -10z m41 -149 c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16 -11z
m50 -27 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m523 -19 c22
-30 13 -34 -28 -13 -22 11 -40 25 -40 31 0 16 52 1 68 -18z m142 -70 c0 -2 -3
-3 -7 -1 -46 22 -63 33 -63 39 0 5 16 -1 35 -12 19 -12 35 -23 35 -26z m-152
-59 c27 -18 29 -34 4 -34 -21 0 -42 18 -42 37 0 17 11 16 38 -3z m122 -55 c0
-6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m1530
-313 c0 -16 -14 -36 -26 -36 -16 0 -16 2 -8 24 5 14 34 24 34 12z m-136 -151
c-4 -8 -10 -15 -15 -15 -5 0 -9 7 -9 15 0 8 7 15 15 15 9 0 12 -6 9 -15z"/>
<path d="M7812 6488 c-18 -18 -14 -53 8 -73 16 -15 26 -17 48 -9 33 11 39 37
18 69 -18 27 -54 33 -74 13z"/>
<path d="M5080 6383 c-80 -32 -114 -53 -103 -64 18 -18 206 48 211 74 5 25
-30 22 -108 -10z"/>
<path d="M5022 6254 c-71 -19 -97 -33 -84 -46 13 -13 153 21 159 38 6 19 -20
22 -75 8z"/>
<path d="M3460 5994 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M3410 5986 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"/>
<path d="M4901 5519 c-14 -5 -53 -35 -89 -66 -126 -111 -141 -170 -54 -213 77
-38 380 -160 482 -195 150 -51 350 -124 410 -150 30 -13 100 -43 155 -66 55
-23 123 -52 150 -64 28 -13 111 -46 185 -75 74 -29 216 -91 315 -139 158 -76
187 -86 237 -87 31 0 63 -6 70 -11 29 -25 451 -198 545 -225 21 -6 40 -5 52 2
21 11 135 169 165 227 25 50 21 62 -31 84 -28 12 -56 33 -68 51 -17 25 -44 39
-165 84 -165 62 -256 88 -277 79 -12 -4 -14 -15 -9 -45 5 -34 4 -40 -12 -40
-30 0 -61 -29 -73 -70 -14 -45 -16 -46 -63 -23 -46 21 -50 36 -26 83 26 51 55
70 107 70 44 0 53 9 30 28 -24 20 -129 42 -169 36 -33 -6 -38 -4 -38 12 -1 26
-19 48 -59 68 -92 48 -465 176 -512 176 -10 0 -48 -28 -84 -61 -69 -65 -72
-61 -24 32 26 52 16 80 -38 107 -71 36 -368 144 -399 145 -40 1 -92 -37 -148
-109 -44 -57 -50 -60 -108 -44 l-37 11 42 59 c37 52 46 59 77 60 73 1 77 41 5
54 -42 8 -53 23 -29 40 10 8 13 15 7 19 -17 11 -438 154 -469 160 -16 3 -40 2
-53 -4z m212 -111 c70 -27 127 -53 127 -56 0 -4 -29 -35 -64 -69 l-63 -63 -79
26 c-143 47 -229 77 -238 85 -5 4 21 38 57 74 79 79 65 78 260 3z m652 -239
c91 -34 91 -33 39 -99 l-46 -58 -51 15 c-82 24 -197 74 -197 85 0 6 22 33 49
59 l48 49 49 -15 c27 -8 76 -24 109 -36z m489 -200 c39 -12 87 -28 107 -36
l37 -14 -30 -32 c-16 -18 -35 -45 -40 -60 -10 -26 -14 -28 -44 -22 -48 8 -204
75 -204 86 0 13 80 99 93 99 5 0 42 -10 81 -21z m1022 -415 c20 -48 11 -55
-15 -13 -24 38 -26 49 -10 49 5 0 17 -16 25 -36z m169 -52 c-3 -3 0 -11 6 -19
7 -8 8 -13 1 -13 -5 0 -15 14 -21 31 -9 27 -9 30 4 19 9 -7 13 -15 10 -18z
m-122 -89 c2 -10 8 -26 12 -37 6 -13 4 -17 -4 -15 -7 3 -19 19 -27 37 -11 27
-11 32 0 32 8 0 16 -8 19 -17z m78 -26 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3
-3 0 -11 -7 -18z"/>
<path d="M4085 5276 c-129 -54 -100 -81 32 -29 72 28 93 40 93 54 0 17 -46 8
-125 -25z"/>
<path d="M4455 5093 c-42 -21 -282 -261 -289 -289 -5 -19 35 -144 63 -194 4
-8 76 -46 159 -85 84 -38 152 -73 152 -77 0 -3 -28 -37 -62 -75 -35 -38 -88
-100 -118 -137 -30 -38 -66 -82 -80 -98 l-25 -30 -50 20 c-88 35 -362 169
-370 181 -7 12 44 93 88 141 61 67 128 163 118 173 -22 22 -63 -8 -161 -115
-136 -149 -139 -157 -115 -255 11 -41 26 -84 35 -94 8 -10 92 -52 185 -94 94
-42 204 -92 245 -112 41 -20 107 -48 145 -63 121 -48 197 -81 275 -117 41 -19
109 -50 150 -68 41 -18 107 -48 145 -67 39 -19 78 -33 87 -31 10 3 86 -32 170
-75 251 -131 565 -274 587 -267 21 6 66 63 165 210 38 55 79 109 93 119 14 11
32 34 39 53 13 30 16 32 39 21 l26 -11 -29 -31 c-57 -63 -182 -226 -316 -414
-75 -105 -141 -192 -147 -192 -26 0 1 65 63 146 21 28 38 56 38 62 0 26 -81
44 -95 22 -4 -6 -21 2 -42 21 -41 35 -153 89 -398 191 -93 39 -191 81 -217 94
-40 20 -51 22 -78 12 -29 -10 -34 -8 -54 15 -25 30 -109 73 -306 156 -80 34
-172 75 -205 91 -63 32 -131 40 -153 18 -9 -9 -12 -8 -12 7 -1 42 -38 69 -175
128 -44 19 -132 57 -195 84 -191 83 -175 79 -212 51 -42 -32 -173 -165 -194
-198 -10 -14 -30 -39 -47 -57 -28 -30 -29 -33 -18 -80 7 -26 22 -66 34 -88 20
-36 42 -49 287 -172 191 -96 275 -133 301 -133 21 0 52 -11 80 -28 55 -35 487
-263 553 -292 33 -15 58 -20 81 -16 28 6 48 -1 122 -43 567 -317 536 -301 601
-301 46 0 57 -4 85 -32 45 -44 505 -278 547 -278 17 0 40 5 50 10 23 12 101
117 200 266 39 60 80 116 89 124 33 27 304 420 376 543 22 39 26 55 21 87 -6
38 -5 40 28 54 19 8 44 28 57 45 12 17 29 31 36 31 17 0 78 79 169 217 109
165 113 181 57 205 -34 15 -43 26 -43 55 0 18 -16 31 -77 62 -144 71 -415 174
-462 175 -43 1 -44 0 -56 -40 -7 -23 -48 -91 -91 -151 -43 -61 -87 -125 -96
-142 -11 -21 -22 -29 -32 -25 -9 3 -16 10 -16 16 0 5 45 70 101 143 76 102 99
139 94 155 -3 11 -11 20 -16 20 -5 0 -13 8 -16 18 -4 9 -17 34 -30 55 -27 43
-61 61 -318 166 -189 77 -257 101 -283 101 -28 0 -90 -25 -111 -44 -19 -17
-19 -17 -14 21 9 65 -42 108 -202 169 -33 12 -109 44 -170 69 -208 89 -218 89
-298 2 -43 -47 -46 -49 -32 -18 13 26 15 39 6 58 -6 13 -11 35 -11 48 0 35
-33 54 -235 132 -211 82 -230 87 -270 66z m243 -119 c97 -36 182 -71 189 -77
9 -9 -4 -28 -55 -82 -37 -39 -90 -97 -119 -130 l-51 -60 -89 32 c-225 81 -333
127 -333 143 0 3 51 58 113 123 90 95 117 117 140 117 15 0 107 -30 205 -66z
m487 -191 c33 -14 107 -44 165 -68 138 -56 198 -84 204 -93 4 -7 -63 -95 -180
-239 l-35 -43 -52 21 c-29 11 -88 34 -132 50 -185 70 -295 121 -295 137 0 22
84 130 162 210 68 69 60 68 163 25z m705 -287 c89 -32 382 -150 401 -162 5 -3
-15 -38 -44 -77 -29 -40 -72 -100 -95 -133 -70 -105 -68 -104 -162 -64 -25 10
-106 42 -180 71 -74 28 -168 67 -209 85 l-73 34 17 27 c27 41 103 141 153 201
54 63 62 64 192 18z m-1025 -176 c44 -21 105 -49 135 -63 30 -13 92 -41 137
-62 l81 -37 -130 -162 c-72 -88 -137 -162 -144 -164 -35 -8 -381 147 -483 217
-20 13 183 291 213 291 12 0 32 6 46 14 31 17 45 14 145 -34z m1538 -100 c-3
-12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m274 -56 c41 -19 72 -38
69 -41 -3 -3 -34 8 -70 26 -35 17 -69 31 -75 31 -6 0 -11 5 -11 10 0 15 7 13
87 -26z m-1169 -104 c20 -11 121 -61 225 -110 103 -49 201 -99 218 -111 l31
-21 -31 -32 c-16 -17 -59 -71 -95 -121 -36 -49 -77 -107 -92 -127 -15 -20 -37
-39 -49 -42 -34 -9 -106 17 -255 92 l-135 67 -3 58 c-4 70 -22 76 -22 7 0 -27
-3 -50 -7 -50 -10 0 -133 70 -133 77 0 3 44 68 97 144 74 106 103 140 124 144
15 3 35 15 45 26 22 24 36 24 82 -1z m1165 4 c67 -34 87 -96 45 -141 -41 -43
-125 -19 -149 44 -15 40 25 111 64 113 4 0 22 -7 40 -16z m-2848 -59 c83 -36
180 -80 216 -97 l67 -30 -70 -66 c-38 -36 -92 -94 -119 -129 -45 -56 -54 -62
-77 -56 -31 7 -340 153 -377 177 -14 9 -25 20 -25 25 0 25 196 241 219 241 9
0 84 -29 166 -65z m3257 -107 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9
-15 0 -24z m-2657 -156 c199 -85 310 -136 334 -153 21 -14 19 -17 -76 -139
-54 -69 -107 -138 -118 -153 l-20 -27 -74 35 c-54 26 -72 39 -67 50 5 15 -47
296 -59 315 -14 22 -25 7 -25 -32 0 -53 45 -255 61 -274 16 -19 5 -18 -40 6
-37 18 -40 23 -56 97 -9 43 -21 82 -25 87 -15 15 -20 -30 -10 -84 5 -29 10
-54 10 -57 0 -10 -120 59 -154 89 l-40 34 59 90 c72 111 134 187 149 180 6 -2
74 -31 151 -64z m1804 22 c10 -22 155 -115 201 -129 14 -4 57 -23 97 -41 l72
-33 -37 -38 -37 -38 -3 33 c-4 40 -30 58 -147 103 -44 17 -112 48 -152 70 -83
45 -98 47 -114 18 -15 -28 -29 -8 -29 42 l0 36 69 -1 c62 0 71 -3 80 -22z
m721 2 c9 -25 -7 -86 -23 -86 -4 0 -14 15 -22 33 -12 28 -12 34 1 43 7 6 14
16 14 23 0 20 20 11 30 -13z m-70 -121 c0 -21 -24 -19 -43 3 -14 15 -15 23 -5
44 l12 26 18 -29 c10 -16 18 -36 18 -44z m-1721 -216 c256 -107 383 -167 379
-179 -1 -5 -50 -75 -108 -154 -89 -123 -109 -145 -132 -145 -41 -1 -174 51
-332 130 -150 75 -203 107 -210 126 -6 14 186 300 203 302 2 1 92 -36 200 -80z
m1538 49 c24 -49 33 -90 23 -107 -8 -14 -9 -14 -10 2 0 10 -11 39 -25 66 -22
44 -23 61 -5 61 4 0 12 -10 17 -22z m-7 -118 c6 -11 2 -27 -13 -50 -14 -21
-22 -28 -25 -19 -2 8 -13 33 -24 55 -17 35 -18 45 -8 65 l13 23 24 -30 c13
-16 28 -36 33 -44z m-81 -70 c29 -52 19 -67 -24 -33 -29 23 -32 29 -22 50 14
31 20 29 46 -17z m-499 -90 c77 -34 134 -65 132 -72 -5 -17 -42 -68 -50 -68
-7 0 -254 118 -260 125 -4 3 36 75 42 75 1 0 62 -27 136 -60z m-76 -123 c18
-13 17 -16 -8 -55 -28 -44 -32 -47 -55 -33 -12 8 -11 17 8 55 23 50 28 53 55
33z m389 -130 c-8 -18 -12 -16 -36 27 -22 39 -25 52 -15 69 10 19 12 18 35
-28 18 -35 22 -53 16 -68z m-790 29 c-35 -62 -90 -136 -100 -136 -31 0 0 74
54 132 24 25 60 29 46 4z m737 -73 c0 -7 -9 -13 -19 -13 -17 0 -18 3 -8 24 12
24 26 17 27 -11z m-207 -278 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7
-12 3 -20z"/>
<path d="M4537 4890 c-33 -38 -53 -98 -39 -112 8 -8 16 -7 27 2 20 17 65 103
65 125 0 24 -26 17 -53 -15z"/>
<path d="M5221 4716 c-9 -11 -10 -24 -2 -53 16 -58 15 -60 -41 -79 -29 -9 -59
-21 -65 -27 -18 -14 -16 -35 4 -39 15 -3 123 28 178 51 11 5 32 13 48 20 44
17 35 41 -16 41 -43 0 -44 0 -58 47 -15 51 -29 62 -48 39z"/>
<path d="M5207 4474 c-13 -14 -7 -22 25 -33 36 -13 47 -7 32 17 -12 18 -45 27
-57 16z"/>
<path d="M5908 4391 c-84 -55 -176 -136 -166 -146 9 -9 40 2 80 28 18 13 55
35 82 51 l49 28 -7 -81 c-4 -55 -3 -80 4 -76 6 4 10 -6 11 -22 1 -40 6 -64 17
-75 5 -5 12 39 16 114 3 68 9 144 12 169 7 66 -10 68 -98 10z"/>
<path d="M6082 4300 c-14 -23 -16 -50 -4 -50 14 0 42 35 42 53 0 23 -22 21
-38 -3z"/>
<path d="M6092 4217 c-20 -21 -32 -43 -32 -60 0 -43 15 -32 43 33 14 33 24 60
23 60 -1 0 -16 -15 -34 -33z"/>
<path d="M4707 4188 c-51 -69 -59 -101 -24 -96 15 2 31 19 49 53 15 28 28 56
28 63 0 23 -29 12 -53 -20z"/>
<path d="M4914 4195 c-9 -23 44 -130 72 -145 29 -16 74 -5 74 18 -1 27 -38
102 -51 102 -11 0 -10 -13 7 -70 11 -40 -17 -8 -46 52 -27 55 -46 70 -56 43z"/>
<path d="M4812 4143 c-53 -126 -61 -183 -28 -183 19 0 31 22 66 124 23 65 26
82 14 84 -8 2 -14 8 -14 13 0 24 -20 4 -38 -38z"/>
<path d="M5406 3873 c-6 -67 5 -229 17 -225 4 1 7 4 7 7 0 3 0 66 0 140 0 138
-13 181 -24 78z"/>
<path d="M5517 3913 c-3 -5 -8 -60 -11 -123 -4 -63 -9 -129 -12 -147 -5 -25
-2 -34 14 -42 36 -19 51 -12 71 35 12 25 38 67 58 92 52 64 67 92 55 104 -6 6
-34 -18 -76 -63 l-67 -73 7 86 c7 95 1 138 -21 138 -7 0 -16 -3 -18 -7z"/>
<path d="M5703 3719 c-49 -53 -68 -88 -55 -101 6 -6 17 -8 26 -5 16 6 96 120
96 136 0 23 -31 9 -67 -30z"/>
<path d="M3711 3853 c-34 -50 -36 -76 -9 -81 11 -2 29 15 52 50 42 62 45 78
12 78 -18 0 -33 -13 -55 -47z"/>
<path d="M4420 3652 c0 -22 16 -102 35 -179 19 -76 35 -139 35 -140 0 -2 5 -3
11 -3 16 0 -2 118 -45 304 -14 63 -36 73 -36 18z"/>
<path d="M4530 3635 c0 -52 41 -185 57 -185 12 0 13 7 8 28 -4 15 -11 54 -16
87 -6 36 -18 68 -30 80 -19 19 -19 19 -19 -10z"/>
<path d="M4650 3583 c0 -16 7 -38 15 -49 13 -18 14 -17 15 17 0 21 -7 42 -15
49 -13 11 -15 8 -15 -17z"/>
<path d="M4980 3376 c-14 -18 -24 -12 189 -117 157 -76 209 -92 218 -63 3 10
-362 194 -385 194 -5 0 -15 -6 -22 -14z"/>
<path d="M4904 3256 c-4 -9 -5 -18 -3 -20 22 -20 369 -176 391 -176 61 0 22
30 -146 114 -191 94 -232 108 -242 82z"/>
<path d="M1121 5030 c-7 -4 -12 -13 -10 -19 3 -8 10 -8 23 2 21 15 9 31 -13
17z"/>
<path d="M2185 4976 c-106 -42 -130 -54 -190 -99 -183 -135 -301 -342 -322
-563 -12 -127 53 -328 130 -402 47 -44 149 -92 217 -102 65 -9 154 0 237 25
62 19 154 84 209 151 51 60 125 205 153 300 99 333 49 587 -133 682 -42 21
-67 26 -146 29 -81 3 -104 0 -155 -21z m293 -80 c71 -52 112 -134 112 -225 l0
-66 -19 31 c-11 18 -24 35 -29 38 -6 3 -16 23 -22 44 -34 106 -124 192 -202
192 -43 1 -43 1 -18 16 44 26 119 13 178 -30z m-80 -89 c16 -18 32 -44 36 -60
l7 -28 -36 23 c-34 23 -45 38 -25 38 22 0 8 21 -25 37 -40 19 -46 32 -11 26
13 -3 37 -19 54 -36z m-122 -76 c9 -14 28 -35 42 -47 25 -21 25 -22 11 -60
l-15 -39 -72 4 c-68 3 -73 2 -102 -27 -52 -52 -42 -68 65 -100 94 -28 127 -62
133 -136 3 -43 -1 -65 -22 -112 -29 -65 -69 -99 -144 -124 -36 -12 -50 -24
-68 -55 -19 -34 -27 -40 -55 -40 -35 0 -38 7 -27 69 2 13 -6 22 -27 30 -66 24
-85 45 -85 93 0 24 5 53 10 63 10 18 12 18 63 -5 73 -35 158 -35 192 0 48 47
25 96 -50 110 -72 12 -120 39 -138 78 -20 41 -21 58 -6 110 12 45 64 100 116
122 64 28 68 31 71 61 5 36 13 41 56 36 24 -3 40 -12 52 -31z m93 -209 c0 -24
-1 -25 -9 -7 -12 28 -12 47 0 40 6 -3 10 -18 9 -33z"/>
<path d="M2266 4343 c7 -16 14 -36 14 -45 0 -10 5 -18 10 -18 17 0 11 44 -9
68 -26 30 -33 28 -15 -5z"/>
<path d="M1346 4755 c-15 -11 -17 -18 -9 -26 15 -15 55 -1 51 19 -4 21 -19 24
-42 7z"/>
<path d="M1325 4697 c-37 -20 -59 -67 -37 -80 14 -9 75 28 83 50 11 29 -17 47
-46 30z"/>
<path d="M4095 4660 c-10 -17 20 -44 40 -36 9 3 15 14 13 23 -4 21 -43 30 -53
13z"/>
<path d="M8794 4609 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
<path d="M3480 4566 c-94 -34 -100 -37 -94 -47 9 -14 46 -10 115 12 75 25 90
36 69 49 -19 13 -15 13 -90 -14z"/>
<path d="M7822 4501 c-8 -5 -12 -16 -10 -24 2 -11 7 -14 16 -8 8 5 12 16 10
24 -2 11 -7 14 -16 8z"/>
<path d="M7745 4439 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M4131 4395 c-45 -72 -57 -105 -40 -116 22 -14 29 -7 76 60 50 74 55
101 18 101 -19 0 -32 -11 -54 -45z"/>
<path d="M3315 4389 c-10 -16 27 -40 42 -27 7 6 13 17 13 24 0 16 -46 19 -55
3z"/>
<path d="M9022 4392 c-26 -8 -141 -118 -198 -189 -21 -27 -62 -75 -90 -108
-27 -33 -88 -109 -135 -170 -46 -60 -133 -174 -194 -252 -60 -77 -146 -192
-190 -255 -44 -62 -139 -194 -212 -293 -73 -99 -173 -234 -221 -300 -49 -66
-142 -199 -207 -295 -65 -96 -173 -256 -240 -355 -177 -261 -301 -460 -386
-620 -107 -202 -112 -217 -104 -298 4 -41 2 -73 -4 -80 -17 -22 -30 -63 -22
-76 17 -27 52 -20 95 19 33 31 51 40 78 40 50 0 103 25 127 59 12 16 21 32 21
37 0 4 14 19 30 34 20 18 28 33 24 44 -3 9 -9 16 -13 16 -4 0 -28 26 -53 58
-24 31 -62 66 -84 77 -21 11 -41 21 -43 22 -8 5 22 33 35 33 22 0 112 -93 148
-153 l34 -57 43 43 c24 24 75 82 114 129 58 72 71 83 83 72 25 -26 77 -17 129
23 151 115 264 226 381 371 118 149 269 398 322 532 29 71 69 219 72 263 2 37
-15 67 -38 67 -8 0 -14 3 -14 7 0 9 158 264 266 429 51 78 111 172 135 210 81
132 240 453 273 550 19 55 53 140 77 189 48 103 53 140 20 167 -25 19 -25 19
-59 10z m-32 -149 c-18 -32 -39 -66 -47 -75 -16 -18 -43 -14 -43 6 0 15 98
126 112 126 5 0 -5 -26 -22 -57z m-189 -224 c-11 -13 -54 -65 -95 -116 -41
-50 -81 -95 -88 -99 -7 -5 7 16 31 46 24 30 71 90 105 132 33 43 62 74 64 69
1 -5 -6 -19 -17 -32z m-136 -382 l40 -35 -30 -57 c-16 -31 -33 -59 -37 -61 -4
-3 -25 13 -46 34 -27 27 -58 45 -100 58 -34 10 -62 21 -62 24 0 3 12 20 28 37
15 17 37 43 49 58 l22 26 48 -25 c26 -13 66 -40 88 -59z m-255 -169 c11 -12
20 -30 20 -41 0 -11 14 -35 30 -54 17 -18 30 -41 30 -51 -1 -13 -4 -12 -13 6
-19 33 -73 62 -128 69 -27 3 -49 9 -49 13 0 9 72 80 81 80 5 0 17 -10 29 -22z
m-31 -149 c66 -37 91 -57 91 -75 0 -7 -17 -41 -38 -76 -21 -35 -69 -115 -107
-178 -38 -63 -83 -137 -100 -165 -18 -27 -84 -133 -147 -235 -167 -269 -229
-361 -239 -354 -5 3 -7 20 -4 37 5 29 1 37 -47 83 -51 50 -56 52 -123 58 -38
4 -71 9 -73 10 -6 7 214 336 322 481 29 39 106 142 171 230 65 88 129 172 143
188 35 37 80 36 151 -4z m-176 -644 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9
16 15 13 8 -4z m-94 -172 c-12 -21 -27 -42 -32 -48 -11 -11 9 29 31 63 24 37
25 25 1 -15z m101 -8 c0 -64 -252 -447 -379 -575 -83 -85 -82 -80 14 39 93
117 249 359 314 489 30 60 51 79 51 47z m-161 -92 c-12 -21 -29 -45 -37 -53
-7 -8 0 9 18 38 17 28 34 52 37 52 2 0 -5 -17 -18 -37z m-459 -68 c0 -8 -10
-15 -22 -17 -13 -2 -23 -7 -23 -13 0 -5 -48 -80 -106 -165 -59 -85 -154 -229
-213 -320 -120 -187 -151 -228 -162 -218 -13 14 253 438 413 658 59 81 75 98
92 93 12 -3 21 -11 21 -18z m383 -50 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9
16 15 13 8 -4z m-103 -162 c-39 -69 -125 -185 -147 -197 -9 -5 -14 -5 -11 0
53 95 109 178 141 212 23 23 43 42 45 42 2 0 -11 -26 -28 -57z m-246 -395
c-21 -24 -46 -54 -56 -68 -12 -19 -19 -22 -28 -14 -9 9 3 27 49 75 67 69 97
75 35 7z m-567 -356 c29 -35 43 -62 43 -82 0 -46 -15 -43 -28 5 -14 52 -42 67
-74 39 -13 -13 -18 -27 -15 -47 3 -27 2 -28 -32 -25 -35 3 -36 4 -35 45 0 23
9 61 19 83 16 36 22 41 49 38 22 -2 41 -16 73 -56z"/>
<path d="M8520 3649 c-13 -8 -11 -12 10 -27 14 -9 46 -36 71 -60 44 -40 47
-41 52 -20 8 28 5 31 -55 78 -55 44 -56 44 -78 29z"/>
<path d="M8331 3273 c2 -18 56 -98 65 -96 21 5 14 38 -17 69 -31 33 -50 43
-48 27z"/>
<path d="M8332 3183 c8 -10 20 -27 26 -38 10 -19 11 -19 18 -2 7 20 -21 57
-44 57 -13 0 -13 -2 0 -17z"/>
<path d="M8273 3124 c-6 -15 27 -69 42 -69 4 0 14 9 21 21 12 18 11 23 -6 42
-24 26 -48 28 -57 6z"/>
<path d="M8226 3054 c-4 -10 4 -31 20 -52 l27 -35 13 24 c10 20 10 27 -4 42
-28 31 -49 39 -56 21z"/>
<path d="M8179 2980 c-1 -5 -2 -16 -3 -22 -1 -7 3 -25 10 -39 18 -36 7 -43
-28 -18 -16 12 -32 19 -35 16 -3 -3 3 -27 13 -52 15 -40 15 -48 3 -56 -12 -7
-11 -9 3 -9 28 0 98 99 98 140 0 13 -37 50 -50 50 -5 0 -11 -4 -11 -10z"/>
<path d="M8094 2831 c5 -8 0 -14 -14 -18 -22 -6 -23 -22 -4 -68 8 -19 7 -19
-19 -2 -14 9 -29 17 -32 17 -10 0 -5 -35 11 -77 9 -24 14 -46 11 -50 -4 -3
-22 6 -41 21 -46 35 -55 18 -21 -40 15 -25 24 -48 21 -51 -9 -9 -35 17 -52 53
-19 39 -25 41 -49 14 -16 -18 -16 -20 -2 -20 10 0 17 -6 17 -13 0 -15 58 -87
70 -87 5 0 13 12 19 28 12 30 37 74 63 111 9 13 15 28 12 33 -3 4 -2 8 3 8 4
0 18 15 31 34 22 32 22 36 9 69 -8 19 -21 38 -28 42 -8 6 -10 4 -5 -4z"/>
<path d="M7837 2573 c-2 -5 23 -33 55 -64 53 -50 60 -54 69 -38 8 15 2 27 -32
64 -38 42 -81 59 -92 38z"/>
<path d="M7844 2456 c55 -67 45 -91 -16 -37 -30 26 -73 43 -80 31 -3 -6 106
-110 115 -110 4 0 18 18 33 39 19 30 23 44 16 58 -16 29 -60 63 -83 63 -18 0
-17 -4 15 -44z"/>
<path d="M7672 4348 c-20 -20 -14 -40 11 -36 14 2 22 10 22 22 0 24 -16 31
-33 14z"/>
<path d="M7595 4197 c-14 -20 -14 -22 0 -25 16 -4 45 14 45 28 0 19 -31 17
-45 -3z"/>
<path d="M1193 4194 c-7 -3 -15 -20 -19 -37 -4 -22 -18 -41 -44 -59 -54 -37
-84 -99 -76 -152 10 -58 28 -81 82 -102 l46 -18 -4 -48 c-6 -61 -12 -67 -41
-40 -27 26 -79 29 -101 6 -9 -8 -16 -18 -16 -22 2 -25 89 -92 121 -92 18 0 29
-20 29 -51 0 -21 36 -40 60 -31 20 7 30 23 30 48 0 15 8 24 25 28 36 9 92 72
109 124 39 114 0 191 -100 195 l-44 2 0 49 c0 48 0 48 28 41 15 -4 41 -7 57
-8 28 -2 30 0 27 32 -3 44 -13 55 -62 71 -26 8 -45 23 -53 39 -12 26 -31 34
-54 25z m-10 -216 c-1 -24 -2 -46 -2 -50 -1 -5 -10 -8 -21 -8 -16 0 -20 7 -20
32 0 28 23 68 39 68 3 0 5 -19 4 -42z m125 -171 c5 -23 -36 -87 -55 -87 -11 0
-14 6 -9 23 3 12 6 38 6 59 0 35 1 36 28 29 15 -4 28 -15 30 -24z"/>
<path d="M7542 4074 c-22 -15 -29 -34 -13 -34 5 0 19 8 32 18 28 23 11 37 -19
16z"/>
<path d="M7440 3945 c-7 -9 -10 -18 -7 -21 6 -7 47 18 47 28 0 14 -27 9 -40
-7z"/>
<path d="M7283 3698 c-42 -46 -113 -149 -113 -165 0 -27 39 6 74 65 44 71 77
132 73 132 -2 0 -17 -15 -34 -32z"/>
<path d="M1542 3439 c-212 -208 -277 -268 -412 -383 -237 -202 -240 -205 -240
-237 0 -42 50 -238 70 -274 9 -16 34 -40 56 -53 75 -43 73 -38 28 -80 -45 -42
-64 -71 -64 -100 0 -30 57 -136 82 -151 189 -119 1178 -627 1178 -605 0 2 17
-6 38 -19 40 -25 474 -250 684 -354 79 -39 139 -63 160 -63 50 0 105 39 172
123 34 41 115 136 181 212 66 76 175 203 243 284 68 80 133 149 145 154 34 11
49 41 36 74 -11 29 -22 107 -39 278 -24 241 -37 301 -75 330 -14 11 -94 53
-178 92 -83 40 -336 161 -562 269 -225 108 -495 237 -600 286 -196 92 -484
238 -615 314 -127 72 -108 79 -288 -97z m228 45 c25 -15 101 -56 170 -91 69
-36 168 -89 220 -118 52 -29 132 -70 177 -91 68 -31 80 -40 71 -52 -12 -14
-45 -13 -93 3 -11 3 -3 -3 18 -15 l38 -21 -32 -40 c-18 -23 -37 -43 -44 -45
-6 -3 -31 7 -56 20 -48 28 -59 31 -59 16 0 -16 78 -63 92 -55 16 8 -232 -235
-359 -352 l-92 -84 -38 24 c-21 13 -134 73 -251 132 l-214 109 -18 -24 c-10
-14 -18 -26 -17 -27 2 -1 112 -56 245 -123 139 -69 242 -126 240 -132 -3 -7
-16 -21 -31 -32 l-26 -19 -58 29 c-107 55 -588 310 -620 330 -18 10 -32 22
-30 25 2 6 63 63 233 214 108 97 258 240 375 358 48 48 94 87 101 87 7 0 33
-12 58 -26z m889 -444 c57 -27 108 -53 113 -58 8 -8 -419 -431 -599 -592 -61
-55 -97 -96 -107 -122 -20 -49 -20 -123 1 -278 15 -117 19 -208 8 -219 -3 -2
-23 1 -45 8 -37 11 -39 13 -30 37 13 34 13 67 0 59 -6 -4 -18 -24 -26 -45 -14
-33 -19 -38 -37 -31 -12 5 -22 12 -23 17 -3 31 -18 64 -29 64 -8 0 -16 4 -20
10 -12 19 -56 396 -50 433 8 58 43 103 152 202 55 50 205 196 334 326 129 130
239 237 244 237 6 1 57 -20 114 -48z m551 -265 c127 -63 230 -118 230 -122 0
-4 -10 -17 -22 -30 -29 -31 -18 -49 50 -86 44 -24 51 -31 42 -46 -14 -26 -323
-343 -424 -435 l-85 -77 -98 49 c-54 27 -199 95 -323 152 -124 57 -227 105
-229 107 -3 2 6 14 18 27 l23 24 42 -18 c22 -10 75 -33 116 -53 81 -37 280
-117 345 -137 22 -7 53 -19 68 -27 16 -8 31 -13 33 -10 5 5 -275 135 -426 196
-69 28 -131 55 -139 60 -11 7 48 72 250 274 145 146 272 266 282 266 9 1 121
-51 247 -114z m349 -166 c35 -16 86 -39 112 -51 27 -11 49 -22 49 -23 0 -2
-33 -36 -73 -77 -40 -40 -106 -111 -147 -158 -41 -46 -95 -105 -120 -130 -25
-25 -101 -107 -169 -182 -67 -76 -133 -141 -145 -144 -24 -6 -218 73 -402 163
-55 27 -164 78 -242 113 -79 35 -145 67 -149 73 -3 5 4 18 15 29 l20 20 78
-35 c44 -19 124 -54 179 -77 55 -23 180 -80 277 -127 125 -60 181 -82 192 -76
21 12 241 230 347 345 48 51 116 125 153 163 36 39 66 75 66 81 0 6 -31 28
-69 48 -52 27 -70 42 -73 61 -6 30 2 29 101 -16z m-2101 -189 c89 -43 162 -84
162 -90 0 -5 -3 -10 -6 -10 -9 0 -318 161 -328 171 -24 22 20 4 172 -71z
m2312 -109 c0 -12 -91 -113 -97 -107 -6 6 77 116 88 116 5 0 9 -4 9 -9z
m-1693 -183 c-2 -29 -3 -8 -3 47 0 55 1 79 3 53 2 -26 2 -71 0 -100z m-734 55
c12 -7 29 -13 38 -13 9 0 35 -13 57 -30 23 -16 48 -30 55 -30 20 0 53 -27 44
-36 -9 -9 -97 34 -97 47 0 5 -7 9 -15 9 -17 0 -157 69 -165 81 -3 4 -18 12
-33 18 -15 6 -27 15 -27 21 0 9 47 -13 143 -67z m2430 -74 c-19 -32 -64 -78
-48 -49 13 25 56 80 61 80 3 0 -3 -14 -13 -31z m-2154 -76 c12 -14 19 -28 17
-30 -7 -7 -54 27 -60 43 -9 24 21 16 43 -13z m108 -39 c19 -14 32 -28 30 -30
-7 -7 -97 38 -97 48 0 16 34 7 67 -18z m739 -84 c26 -11 45 -23 42 -26 -6 -5
-88 24 -102 38 -17 14 10 9 60 -12z m1132 -1 c-2 -6 -8 -10 -13 -10 -5 0 -11
4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1479 -98 c-1 -31 -2 -28 -14 24
-18 78 -18 88 0 46 8 -18 15 -50 14 -70z m776 -80 c39 -17 77 -51 58 -51 -5 0
-13 4 -18 9 -6 5 -27 14 -47 20 -39 13 -72 41 -47 41 8 0 32 -9 54 -19z m486
-8 c-5 -10 -37 -47 -71 -83 -68 -72 -47 -33 25 48 49 54 63 64 46 35z m-758
-124 c81 -41 103 -59 40 -32 -56 23 -193 93 -193 98 0 6 63 -21 153 -66z m265
-165 c131 -57 242 -115 242 -128 0 -16 -24 -6 -237 102 -98 50 -152 89 -79 58
20 -9 53 -23 74 -32z"/>
<path d="M1685 3420 c-3 -5 -45 -42 -93 -82 -101 -84 -373 -332 -450 -410 -59
-61 -59 -64 18 -94 25 -9 57 -26 72 -36 26 -18 27 -18 33 2 5 16 -1 27 -21 43
-16 12 -32 21 -36 19 -18 -5 -5 35 20 64 32 37 223 217 313 295 34 30 86 76
115 102 l52 48 43 -11 c37 -10 44 -9 61 8 19 19 19 19 -26 41 -49 22 -91 27
-101 11z"/>
<path d="M1838 3376 c-6 -27 7 -45 22 -30 5 5 3 20 -4 33 l-13 24 -5 -27z"/>
<path d="M1823 3160 c-132 -47 -189 -129 -181 -259 4 -81 31 -125 105 -167 88
-52 160 -31 283 83 63 58 106 124 96 149 -10 26 -33 14 -65 -36 -34 -52 -147
-152 -191 -168 -41 -15 -114 12 -147 54 -22 30 -27 47 -28 93 0 77 27 126 98
175 50 35 61 39 117 38 43 -1 77 -9 117 -27 69 -31 95 -20 48 22 -73 63 -157
78 -252 43z"/>
<path d="M2240 3100 c19 -11 40 -19 45 -19 6 0 -6 8 -25 19 -19 11 -39 19 -45
19 -5 0 6 -8 25 -19z"/>
<path d="M1811 3021 c-12 -16 -18 -32 -13 -35 9 -5 47 52 39 60 -3 3 -14 -8
-26 -25z"/>
<path d="M1871 3019 c-13 -17 -22 -31 -20 -33 2 -2 18 -10 34 -19 17 -8 42
-26 57 -40 25 -23 29 -24 43 -11 21 21 19 31 -10 49 -14 8 -37 22 -51 31 -19
12 -23 19 -15 27 15 15 14 27 -1 26 -7 0 -24 -14 -37 -30z"/>
<path d="M1965 3025 c32 -27 55 -32 55 -14 0 18 -32 38 -60 38 l-25 0 30 -24z"/>
<path d="M1777 2946 c-12 -30 129 -86 147 -58 8 13 -10 26 -64 44 -25 8 -52
18 -61 22 -10 5 -19 2 -22 -8z"/>
<path d="M1766 2884 c-9 -22 -1 -31 40 -44 30 -10 38 -10 45 1 13 21 12 24
-26 42 -44 21 -51 21 -59 1z"/>
<path d="M2135 2550 c-10 -16 4 -48 24 -55 22 -7 53 4 46 16 -13 20 -64 49
-70 39z"/>
<path d="M2059 2458 c8 -24 55 -47 68 -35 10 10 -38 57 -58 57 -15 0 -17 -4
-10 -22z"/>
<path d="M1996 2441 c21 -21 29 -19 21 4 -4 8 -14 15 -23 15 -16 0 -16 -2 2
-19z"/>
<path d="M1881 2351 c-16 -30 -14 -71 4 -71 17 0 38 44 33 68 -5 24 -26 25
-37 3z"/>
<path d="M1974 2322 c-17 -11 -22 -35 -12 -55 14 -27 50 -4 46 30 -3 28 -16
37 -34 25z"/>
<path d="M2932 2660 c-58 -12 -158 -67 -198 -108 -43 -45 -68 -111 -61 -159
11 -64 91 -126 212 -163 22 -7 73 -26 113 -41 102 -41 121 -35 34 10 l-74 37
43 20 c24 10 66 36 93 58 l49 39 52 -26 c33 -16 57 -22 66 -17 17 11 16 12
-49 44 -44 22 -51 29 -43 44 25 43 25 43 89 17 68 -28 82 -30 82 -14 0 6 -31
25 -69 42 -66 30 -70 33 -73 69 -2 21 -1 38 2 38 3 0 41 -16 85 -35 78 -34
122 -44 110 -26 -3 5 -49 26 -102 47 -65 25 -106 47 -121 66 -14 16 -37 35
-53 43 -36 19 -131 27 -187 15z m150 -62 c105 -40 61 -199 -76 -278 -63 -35
-96 -37 -169 -10 -72 27 -107 60 -107 100 0 59 35 104 123 157 81 49 156 59
229 31z"/>
<path d="M2871 2503 c-50 -33 -52 -37 -36 -50 11 -7 24 -13 30 -13 14 0 48 36
64 67 23 44 12 44 -58 -4z"/>
<path d="M2815 2410 c-9 -15 14 -40 36 -40 27 0 35 21 14 37 -22 16 -41 17
-50 3z"/>
<path d="M2960 2395 c0 -9 6 -12 15 -9 8 4 12 10 9 15 -8 14 -24 10 -24 -6z"/>
<path d="M3115 2255 c42 -26 55 -30 55 -17 0 10 -65 42 -83 42 -7 0 6 -12 28
-25z"/>
<path d="M2395 3550 c-8 -12 20 -24 35 -15 6 3 8 11 5 16 -8 12 -32 12 -40 -1z"/>
<path d="M3060 3486 c0 -14 29 -31 38 -21 6 6 -18 35 -29 35 -5 0 -9 -6 -9
-14z"/>
<path d="M3164 3439 c-9 -16 12 -33 31 -25 14 5 15 9 5 21 -15 18 -26 19 -36
4z"/>
<path d="M7077 3421 c-37 -45 -34 -64 4 -23 17 18 29 38 27 44 -2 6 -16 -4
-31 -21z"/>
<path d="M3290 3360 c0 -13 48 -35 57 -27 8 8 -26 36 -44 37 -7 0 -13 -5 -13
-10z"/>
<path d="M3410 3290 c12 -23 40 -27 40 -6 0 8 -7 17 -16 20 -28 11 -35 7 -24
-14z"/>
<path d="M3506 3253 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M8751 2941 c-17 -11 -8 -57 16 -83 20 -22 69 -24 77 -2 23 60 -40
118 -93 85z"/>
<path d="M4085 2550 c-9 -14 75 -34 89 -20 8 8 7 13 -4 20 -20 13 -77 13 -85
0z"/>
<path d="M6411 2465 c-46 -49 -56 -72 -15 -34 27 25 69 79 61 79 -2 0 -23 -20
-46 -45z"/>
<path d="M6260 2285 c-36 -36 -60 -65 -54 -65 13 0 129 118 123 124 -3 3 -34
-24 -69 -59z"/>
<path d="M5715 2160 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5840 2121 c0 -21 13 -41 26 -41 19 0 18 36 -2 44 -21 8 -24 8 -24
-3z"/>
<path d="M5915 2070 c-3 -5 7 -12 22 -16 15 -3 35 -8 45 -10 28 -8 31 15 3 26
-33 13 -62 13 -70 0z"/>
<path d="M3955 1654 c-23 -23 -26 -32 -20 -63 9 -46 21 -61 52 -61 31 0 79 25
87 45 7 19 -3 75 -17 93 -17 22 -74 14 -102 -14z"/>
<path d="M6440 1521 c0 -12 2 -21 4 -21 2 0 12 -3 21 -6 13 -5 16 -2 13 17 -4
31 -38 39 -38 10z"/>
<path d="M6290 1447 c0 -19 10 -27 26 -20 21 8 17 33 -6 33 -11 0 -20 -6 -20
-13z"/>
<path d="M6035 1385 c-154 -43 -178 -52 -169 -66 10 -17 92 -5 232 35 134 38
145 43 137 57 -11 17 -79 8 -200 -26z"/>
<path d="M4754 1375 c-29 -30 -28 -41 9 -69 l27 -20 28 21 c34 25 39 49 16 74
-23 26 -50 24 -80 -6z"/>
<path d="M6137 1263 c-11 -11 -8 -21 9 -27 19 -7 47 12 38 25 -6 11 -38 12
-47 2z"/>
</g>
</svg>
  )
}
